import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ProprietorshipNavbar = ({ height }) => {

  // Proprietorship service grid items
  const items = [
    { title: 'Single Ownership', description: 'A proprietorship firm is owned and managed by one person.' },
    { title: 'Easy Setup', description: 'Setting up a proprietorship firm is quick and hassle-free.' },
    { title: 'Minimal Compliance', description: 'Fewer regulatory compliances compared to other business structures.' },
    { title: 'Complete Control', description: 'The owner has full control over the business operations and profits.' },
    { title: 'Tax Benefits', description: 'Profits are taxed as personal income, potentially reducing tax liabilities.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Full Control - The proprietor has complete control over decision-making and business operations.",
    "Easy Setup - Minimal legal formalities and registration requirements make it easy to start.",
    "Low Operational Costs - Fewer regulatory compliances help reduce operational costs.",
    "Tax Benefits - Proprietors can take advantage of personal tax benefits.",
  ];

  const disadvantages = [
    "Unlimited Liability - The proprietor is personally liable for all business debts.",
    "Limited Growth Potential - Raising capital or expanding the business can be challenging.",
    "No Legal Distinction - The proprietor and the business are legally considered the same entity."
  ];

  const documents = [
    {
      title: "PAN Card",
      description: "A copy of the PAN card of the proprietor is required."
    },
    {
      title: "Identity Proof",
      description: "Includes passport, voter ID, or Aadhaar card."
    },
    {
      title: "Address Proof",
      description: "Recent documents like utility bills, rental agreements, or bank statements."
    },
    {
      title: "Business Address Proof",
      description: "Proof of the business’s operational address, such as utility bills."
    },
    {
      title: "Bank Account Details",
      description: "A copy of the bank account statement for the business account."
    }
  ];

  const steps = [
    {
      title: "Choose a Business Name",
      description: "Ensure the chosen business name is unique and represents the services offered."
    },
    {
      title: "Obtain PAN for the Business",
      description: "Use the PAN of the proprietor for tax registration."
    },
    {
      title: "Open a Current Bank Account",
      description: "Open a bank account in the business name for transactions."
    },
    {
      title: "Register for GST",
      description: "If applicable, register for Goods and Services Tax (GST)."
    },
    {
      title: "Obtain Business Licenses",
      description: "Depending on the nature of your business, you may need specific licenses."
    },
    {
      title: "Commence Business Operations",
      description: "Once all necessary documents and licenses are in place, begin your operations."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{"top":`${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Proprietorship Firm
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A proprietorship firm is one of the simplest forms of business structure owned and operated by an individual.
            </p>

            <p>Thousands of proprietorship firms have successfully registered with us.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Proprietorship Registration Made Easy</h6>
          <p className='text-center mt-4'>Setting up a proprietorship firm with us is a straightforward process. From selecting a business name to starting operations, we provide comprehensive support to get your firm up and running quickly.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Proprietorship Firm?</h6>

            <p className='mb-4'>A proprietorship firm is a simple business structure where a single individual owns and controls the business. It is ideal for small and medium enterprises due to its ease of setup and minimal regulatory requirements.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register a proprietorship firm in India, you need to meet the following criteria:

              <ul>
                <li><strong>Single Owner -</strong> The business is solely owned and managed by one person.</li>

                <li><strong>Business Name -</strong> The name should not infringe on any trademarks and should reflect your business.</li>

                <li><strong>PAN Card -</strong> You must have a valid PAN card for registration.</li>

                <li><strong>Address Proof -</strong> Provide proof of your business and personal address.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Proprietorship Firm</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of a Proprietorship Firm</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of a Proprietorship Firm</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Proprietorship Firm Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Register a Proprietorship Firm</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default ProprietorshipNavbar;