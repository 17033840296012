import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard'; // Assuming Card component is used for feature display
import './LLPcardgrid.css'; // Custom styling for card grid
import './LLP.css'; // Custom styling for the page
import ScrollToTopButton from './ScrollTop'; // Custom component for scroll to top button

const FSSAILicenseNavbar = ({ height }) => {

  // Features, advantages, and process items
  const items = [
    { title: 'Legal Requirement', description: 'FSSAI license is mandatory for food businesses in India.' },
    { title: 'Consumer Trust', description: 'Enhances trust and credibility with consumers.' },
    { title: 'Compliance', description: 'Ensures compliance with food safety and standards regulations.' },
    { title: 'Market Expansion', description: 'Enables expansion into new markets and regions.' },
    { title: 'Health & Safety', description: 'Ensures adherence to health and safety regulations.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Requirement - FSSAI license is mandatory for food businesses in India.",
    "Consumer Trust - Enhances trust and credibility with consumers.",
    "Compliance - Ensures compliance with food safety and standards regulations.",
    "Market Expansion - Enables expansion into new markets and regions.",
    "Health & Safety - Ensures adherence to health and safety regulations."
  ];

  const disadvantages = [
    "Regulatory Complexity - The application process can be complex and time-consuming.",
    "Ongoing Compliance - Requires continuous compliance with food safety standards.",
    "Cost - There are costs associated with obtaining and maintaining the license.",
    "Documentation - Requires thorough documentation and record-keeping."
  ];

  const documents = [
    {
      title: "Proof of Identity",
      description: "Identity proof of the applicant (e.g., Aadhaar card, passport)."
    },
    {
      title: "Proof of Address",
      description: "Proof of address of the business premises (e.g., utility bill, lease agreement)."
    },
    {
      title: "Business Registration Certificate",
      description: "Certificate of registration of the business (e.g., Company registration, Partnership deed)."
    },
    {
      title: "Food Safety Management System Plan",
      description: "Plan detailing the food safety management system in place."
    },
    {
      title: "List of Food Products",
      description: "Detailed list of food products to be handled or sold."
    },
    {
      title: "Health Certificate",
      description: "Health certificate of the food business operator and key staff."
    }
  ];

  const steps = [
    {
      title: "Determine the Type of License",
      description: "Identify the type of FSSAI license required based on the size and nature of your business."
    },
    {
      title: "Prepare Documents",
      description: "Gather all necessary documents required for the application."
    },
    {
      title: "Fill the Application Form",
      description: "Complete the FSSAI license application form available on the FSSAI website."
    },
    {
      title: "Submit the Application",
      description: "Submit the completed application form along with the required documents to the FSSAI."
    },
    {
      title: "Inspection",
      description: "An FSSAI official may visit your premises for inspection."
    },
    {
      title: "Receive License",
      description: "Upon successful verification, receive the FSSAI license."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#benefits' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Benefits</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why-choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              FSSAI License (Food License)
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Obtaining an FSSAI license is crucial for any food business in India. It ensures compliance with food safety regulations and enhances consumer trust. T2F offers expert guidance to help you navigate the process efficiently.
            </p>

            <p>10K+ Successful Licenses</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>FSSAI License (Food License)</h6>
          <p className='text-center mt-4'>An FSSAI license is a mandatory requirement for any food business operating in India. It ensures that your business adheres to food safety standards and regulations, thereby enhancing consumer trust and market credibility.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is FSSAI License?</h6>

            <p className='mb-4'>FSSAI (Food Safety and Standards Authority of India) license is a legal certification that is required for all food businesses in India. It ensures that food products meet safety standards and are safe for consumption.</p>
            <div>
              <ul><strong>Legal Requirement:</strong> FSSAI license is mandatory for food businesses in India.</ul>

              <ul><strong>Consumer Trust:</strong> Enhances trust and credibility with consumers.</ul>

              <ul><strong>Compliance:</strong> Ensures compliance with food safety and standards regulations.</ul>

              <ul><strong>Market Expansion:</strong> Enables expansion into new markets and regions.</ul>

              <ul><strong>Health & Safety:</strong> Ensures adherence to health and safety regulations.</ul>
            </div>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of FSSAI License</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0C5E1' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of FSSAI License</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔️</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of FSSAI License</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='documents' className='secondLLP pr-96 pl-16 mt-16'>
          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for FSSAI License</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='process' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Obtain FSSAI License</h2>
              <p>Follow these steps to get your FSSAI license:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for Your FSSAI License?</h6>
            <p>T2F is your trusted partner for obtaining your FSSAI license:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you through the licensing process.</li>

              <li><strong>Documentation Assistance - </strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>Application Submission -</strong> Assistance with submitting your application to the FSSAI.</li>

              <li><strong>Compliance Support -</strong> Ensure adherence to food safety regulations.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the licensing process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default FSSAILicenseNavbar;
