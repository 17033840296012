import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const NBFNavbar = ({ height }) => {

  // NBFC service grid items
  const items = [
    { title: 'Regulated by RBI', description: 'NBFCs are strictly regulated by the Reserve Bank of India.' },
    { title: 'Wide Financial Services', description: 'Offers a variety of financial services including loans, investments, and asset financing.' },
    { title: 'Flexibility in Operations', description: 'NBFCs have more operational flexibility compared to traditional banks.' },
    { title: 'No Requirement for Full Banking License', description: 'NBFCs don’t need a full banking license, reducing regulatory burden.' },
    { title: 'Investment Opportunities', description: 'Provides opportunities for financial growth through investment services.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Diverse Financial Services - NBFCs offer a range of services including loans, asset financing, and investments.",
    "Less Stringent Regulations - Compared to banks, NBFCs face fewer restrictions in operations.",
    "Growth Opportunities - NBFCs cater to sectors and demographics underserved by traditional banks.",
    "Flexible Credit Policies - Easier credit policies make it accessible for various customer segments."
  ];

  const disadvantages = [
    "No Demand Deposit - NBFCs are not allowed to accept demand deposits, limiting liquidity options.",
    "Dependence on Borrowing - NBFCs rely heavily on external borrowing for funds.",
    "Regulatory Challenges - Despite flexibility, NBFCs must still comply with several RBI guidelines."
  ];

  const documents = [
    {
      title: "Certificate of Incorporation",
      description: "Issued by the Registrar of Companies (RoC)."
    },
    {
      title: "Detailed Business Plan",
      description: "A comprehensive business plan outlining financial services, target market, and growth projections."
    },
    {
      title: "Director and Shareholder Details",
      description: "Includes PAN, Aadhaar, and other identification proofs."
    },
    {
      title: "Audited Financial Statements",
      description: "For at least three years if applicable, along with the balance sheet."
    },
    {
      title: "Net Owned Funds (NOF) Proof",
      description: "A minimum of Rs. 2 crores of net owned funds is required."
    },
    {
      title: "Registered Office Proof",
      description: "Address proof of the registered office of the NBFC."
    }
  ];

  const steps = [
    {
      title: "Company Incorporation",
      description: "Ensure your NBFC is registered as a company under the Companies Act."
    },
    {
      title: "Secure Net Owned Funds",
      description: "Ensure a minimum of Rs. 2 crore of Net Owned Funds (NOF)."
    },
    {
      title: "Submit Online Application",
      description: "Apply through the RBI’s official portal and attach all required documents."
    },
    {
      title: "Conduct Due Diligence",
      description: "Ensure all directors and promoters meet the 'fit and proper' criteria set by the RBI."
    },
    {
      title: "Compliance with Regulatory Framework",
      description: "Ensure adherence to RBI guidelines on capital adequacy and financial reporting."
    },
    {
      title: "Receive NBFC License",
      description: "Upon verification, the RBI issues the NBFC registration certificate."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              NBFC Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A Non-Banking Financial Company (NBFC) offers financial services such as loans, investments, and asset financing without holding a full banking license.
            </p>

            <p>Join 10K+ Registered NBFCs with Our Assistance</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>NBFC Registration Simplified with T2F</h6>
          <p className='text-center mt-4'>At T2F, we help you navigate the NBFC registration process, ensuring compliance with RBI regulations and a smooth setup. Contact us today to begin your NBFC journey and start providing financial services in India.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is an NBFC?</h6>

            <p className='mb-4'>A Non-Banking Financial Company (NBFC) is a financial institution that offers services similar to banks, including loans and asset financing, but doesn’t have a full banking license. NBFCs are regulated by the RBI to ensure compliance and financial health.</p>

            <p>With flexible financial services and regulatory advantages, NBFCs serve as a critical part of India’s financial landscape.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register as an NBFC in India, the following criteria must be met:

              <ul>
                <li><strong>Minimum Net Owned Funds -</strong> At least Rs. 2 crore of NOF is mandatory.</li>
                <li><strong>Minimum Directors -</strong> A minimum of two directors, with at least one being a resident of India.</li>
                <li ><strong>Company Incorporation -</strong> The company must be registered under the Companies Act.</li>
                <li ><strong>Business Plan -</strong> A detailed plan outlining the NBFC’s services and financial operations is required.</li>
              </ul>
            </p>
          </div>

          <div className='LLP-content h-[50%] mb-10 ml-28 mt-24 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Features of NBFC Registration</h6>
            <div className='llpgrid-container'>
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>
          {/* Pros and Cons, Documents, Steps */}
        </div>
      </div>
    </>
  );
};

export default NBFNavbar;