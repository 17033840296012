import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ESIRegistration = ({ height }) => {

  // ESI Registration grid items
  const items = [
    { title: 'Medical Benefits', description: 'ESI provides medical benefits to employees and their dependents.' },
    { title: 'Sickness Benefits', description: 'Employees receive financial assistance during periods of sickness.' },
    { title: 'Maternity Benefits', description: 'Female employees are entitled to maternity leave and financial assistance.' },
    { title: 'Accident Compensation', description: 'ESI covers compensation for work-related injuries and accidents.' },
    { title: 'Disability Benefits', description: 'Permanent or temporary disability benefits are available under ESI.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Comprehensive Medical Coverage - Provides medical treatment to insured employees and their dependents.",
    "Financial Assistance During Sickness - Employees get financial aid during illness, ensuring income stability.",
    "Maternity Benefits - Female employees are entitled to financial assistance and leave during pregnancy.",
    "Workplace Injury Compensation - ESI provides compensation in case of work-related injuries.",
    "Long-Term Disability Benefits - Employees are protected against long-term disabilities caused by accidents at work.",
  ];

  const disadvantages = [
    "Eligibility Constraints - Only employees earning up to a certain salary are eligible for ESI benefits.",
    "Employer Contribution - Employers need to contribute a portion of the employee’s wages towards ESI.",
    "Complex Compliance - Employers must comply with regular filings and contributions under ESI.",
    "Limited Coverage - Certain employees and employers may not be eligible under the ESI scheme.",
    "Healthcare Facility Dependence - Treatment under ESI is limited to designated ESI hospitals and dispensaries.",
  ];

  const documents = [
    {
      title: "Registration Certificate",
      description: "Registration certificate of the business or establishment is mandatory."
    },
    {
      title: "Employee Information",
      description: "List of all employees with their personal and salary details."
    },
    {
      title: "Bank Statements",
      description: "Bank statements showing payment of salaries and wages to employees."
    },
    {
      title: "Attendance Register",
      description: "Employee attendance record, showing the number of employees regularly employed."
    },
    {
      title: "Factory or Shop License",
      description: "Factory or shop license issued by the local municipal authority."
    }
  ];

  const steps = [
    {
      title: "Obtain Employee Details",
      description: "Collect and verify details of all employees for registration under ESI."
    },
    {
      title: "Apply for Registration",
      description: "Submit the ESI registration application with the required documents."
    },
    {
      title: "Generate ESIC Number",
      description: "After approval, generate unique ESI numbers for all employees."
    },
    {
      title: "File Regular Contributions",
      description: "Start filing monthly ESI contributions for both employer and employee."
    },
    {
      title: "Issue ESI Cards",
      description: "Issue ESI cards to employees for availing medical benefits."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top":`${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Employee State Insurance (ESI) Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Ensure your employees receive comprehensive medical coverage, sickness, and maternity benefits with ESI registration.
            </p>

            <p>Register your organization under the ESI scheme to provide extensive medical and social security benefits to your employees.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Employee State Insurance (ESI)</h6>
          <p className='text-center mt-4'>ESI provides medical and financial benefits to employees in case of sickness, maternity, or workplace injuries.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is ESI?</h6>

            <p className='mb-4'>The Employee State Insurance (ESI) scheme is a social security initiative aimed at providing health benefits, medical care, and compensation to employees during times of sickness, injury, or maternity.</p>
          </div>

          <div id="application" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for ESI Registration</h6>
            <p className='mb-4'>To register your organization under the ESI scheme, follow these steps:

              <ul>
                <li><strong>Obtain Employee Details -</strong> Collect employee information and ensure they are eligible for ESI benefits.</li>

                <li><strong>Apply for Registration -</strong> Submit the ESI registration application form online.</li>

                <li><strong>Generate ESIC Number -</strong> After approval, generate ESI identification numbers for all employees.</li>

                <li><strong>File Monthly Contributions -</strong> Start filing regular ESI contributions on behalf of employees.</li>

                <li><strong>Issue ESI Cards -</strong> Distribute ESI cards to employees, enabling them to access medical facilities.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of ESI Scheme</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className='AdvantagesLLP'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Advantages of ESI</h6>
            <ul>
              {advantages.map((advantage, index) => (
                <li key={index}>{advantage}</li>
              ))}
            </ul>
          </div>

          <div className='DisadvantagesLLP'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Disadvantages of ESI</h6>
            <ul>
              {disadvantages.map((disadvantage, index) => (
                <li key={index}>{disadvantage}</li>
              ))}
            </ul>
          </div>

          <div id="documents" className='docs-req'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Documents Required for ESI Registration</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {documents.map((doc, index) => (
                <Card
                  key={index}
                  title={doc.title}
                  description={doc.description}
                />
              ))}
            </div>
          </div>

          <div id="process" className='steps w-full flex flex-wrap' >
            <div className='stepsToRegister'>
              <h6 className='text-blue-500 text-lg font-semibold text-center'>Steps to Complete ESI Registration</h6>
              <ul>
                {steps.map((step, index) => (
                  <li key={index}>
                    <strong>{step.title} -</strong> {step.description}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for ESI Registration?</h6>
            <p>We offer expert assistance with ESI registration to ensure your organization meets compliance requirements and your employees benefit from comprehensive social security coverage.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default ESIRegistration;