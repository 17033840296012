import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
const MSMENavbar = ({ height }) => {

  // MSME service grid items
  const items = [
    { title: 'Micro Enterprises', description: 'Businesses with investment up to ₹1 crore and turnover up to ₹5 crore.' },
    { title: 'Small Enterprises', description: 'Businesses with investment up to ₹10 crore and turnover up to ₹50 crore.' },
    { title: 'Medium Enterprises', description: 'Businesses with investment up to ₹50 crore and turnover up to ₹250 crore.' },
    { title: 'Easy Online Registration', description: 'Simple and efficient online registration process through the Udyam portal.' },
    { title: 'Government Benefits', description: 'Access to various government schemes and financial benefits.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Government Support - MSMEs are eligible for various government schemes and financial assistance.",
    "Priority Lending - Access to loans with lower interest rates.",
    "Tax Benefits - Avail tax exemptions and benefits under various government schemes.",
    "Protection Against Delayed Payments - Legal protection for delayed payments from buyers.",
  ];

  const disadvantages = [
    "Limited Resources - Smaller scale businesses may face challenges in accessing resources.",
    "Compliance Requirements - Adhering to regulatory requirements can be challenging.",
    "Growth Constraints - Scaling up can be difficult due to limited resources and market constraints.",
  ];

  const documents = [
    {
      title: "Aadhaar Card",
      description: "Aadhaar card of the business owner is required for registration."
    },
    {
      title: "PAN Card",
      description: "Valid PAN card of the business or its owner is needed."
    },
    {
      title: "Business Address Proof",
      description: "Documents proving the address of the business such as utility bills or rental agreements."
    },
    {
      title: "Bank Account Details",
      description: "Details of the business’s bank account for verification."
    },
    {
      title: "Investment and Turnover Details",
      description: "Financial documents showing the business’s investment and turnover figures."
    }
  ];

  const steps = [
    {
      title: "Provide Aadhaar Details",
      description: "The business owner must provide their Aadhaar number for registration."
    },
    {
      title: "Enter Business Details",
      description: "Provide details about the business such as PAN, location, and type of enterprise."
    },
    {
      title: "Submit Financial Information",
      description: "Enter information regarding investment and turnover for the business."
    },
    {
      title: "Complete Online Application",
      description: "Fill out and submit the application form online through the Udyam portal."
    },
    {
      title: "Receive Udyam Registration Certificate",
      description: "After successful submission, you will receive a Udyam Registration Number and certificate."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              MSME (Udyam) Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              MSME (Udyam) Registration provides businesses with numerous benefits and access to government schemes.
            </p>

            <p>Thousands of MSMEs have successfully registered with us.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>MSME Registration Made Easy</h6>
          <p className='text-center mt-4'>Registering your business as an MSME under the Udyam scheme is simple and offers numerous benefits. We guide you through each step to ensure a smooth registration process.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is MSME?</h6>

            <p className='mb-4'>Micro, Small, and Medium Enterprises (MSMEs) play a critical role in the economic development of India. The Udyam Registration provides a streamlined process to avail of various benefits and support from the government.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for MSME Registration</h6>
            <p className='mb-4'>To be eligible for MSME registration, the business must meet the following criteria:

              <ul>
                <li><strong>Business Type -</strong> The business must be categorized as Micro, Small, or Medium based on investment and turnover.</li>

                <li><strong>Aadhaar and PAN -</strong> Aadhaar and PAN details of the business owner or entity are required.</li>

                <li><strong>Investment and Turnover -</strong> Accurate figures for business investment and turnover must be provided.</li>

                <li><strong>Business Location -</strong> Provide proof of the business location and operational address.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of MSME Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of MSME Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of MSME Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for MSME Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Register for MSME (Udyam)</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default MSMENavbar;