import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const FCRARegistration = ({ height }) => {

  // FCRA Registration grid items
  const items = [
    { title: 'Understanding FCRA Registration', description: 'Learn about FCRA (Foreign Contribution Regulation Act) Registration, its importance, and who needs it.' },
    { title: 'Document Preparation', description: 'Steps for preparing necessary documents and information required for FCRA Registration.' },
    { title: 'Application Process', description: 'Detailed process for applying for FCRA Registration, including submission methods and timelines.' },
    { title: 'Compliance Requirements', description: 'Guidance on compliance with FCRA regulations and maintaining the registration status.' },
    { title: 'Renewal and Updates', description: 'Information on renewing FCRA Registration and updating details as required by the authorities.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Regulatory Compliance - Ensure adherence to FCRA regulations for receiving foreign contributions.",
    "Expert Assistance - Professional guidance throughout the FCRA Registration process.",
    "Efficient Processing - Streamlined procedures for faster and accurate registration.",
    "Ongoing Support - Assistance with renewal and compliance maintenance.",
    "Peace of Mind - Confidence in meeting all legal requirements for foreign contributions.",
  ];

  const disadvantages = [
    "Complex Process - The registration process can be complex and require careful attention to detail.",
    "Potential Costs - Possible costs related to professional services and document preparation.",
    "Regulatory Changes - Need to stay updated with changing regulations and requirements.",
  ];

  const documents = [
    {
      title: "Application Form",
      description: "The official application form for FCRA Registration, completed with accurate details."
    },
    {
      title: "Identity Proof",
      description: "Proof of identity for the organization and its key members, such as passports or Aadhar cards."
    },
    {
      title: "Address Proof",
      description: "Proof of address of the organization and its key members, such as utility bills or lease agreements."
    },
    {
      title: "Bank Account Details",
      description: "Details of the bank account where foreign contributions will be received."
    },
    {
      title: "Memorandum and Articles of Association",
      description: "Documents outlining the organizational structure and objectives."
    }
  ];

  const steps = [
    {
      title: "Gather Required Documents",
      description: "Collect all necessary documents and information for the FCRA Registration application."
    },
    {
      title: "Complete Application Form",
      description: "Fill out the FCRA Registration application form with accurate details."
    },
    {
      title: "Submit Application",
      description: "Submit the completed form and supporting documents to the relevant regulatory authority."
    },
    {
      title: "Receive Acknowledgement",
      description: "Obtain confirmation of receipt and processing of the FCRA Registration application."
    },
    {
      title: "Update Records",
      description: "Ensure that the FCRA Registration details are updated in organizational records and databases."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              FCRA Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              FCRA (Foreign Contribution Regulation Act) Registration is mandatory for organizations intending to receive foreign contributions. Our services assist in ensuring that your registration is handled efficiently and in compliance with all legal requirements.
            </p>

            <p>Ensure your FCRA Registration is completed accurately and on time with our expert assistance.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>FCRA Registration</h6>
          <p className='text-center mt-4'>FCRA Registration is a regulatory requirement for organizations to receive foreign contributions. Our expert services ensure a smooth and compliant registration process.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is FCRA Registration?</h6>

            <p className='mb-4'>FCRA Registration is a process mandated by the Foreign Contribution Regulation Act, designed to regulate and monitor foreign contributions to organizations. This registration ensures that funds are received and utilized for lawful purposes only.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for FCRA Registration</h6>
            <p className='mb-4'>To apply for FCRA Registration, the organization must meet the following eligibility criteria and provide the required documents:

              <ul>
                <li><strong>Application Form -</strong> The official application form for FCRA Registration, completed with accurate details.</li>

                <li><strong>Identity Proof -</strong> Proof of identity for the organization and its key members, such as passports or Aadhar cards.</li>

                <li><strong>Address Proof -</strong> Proof of address of the organization and its key members, such as utility bills or lease agreements.</li>

                <li><strong>Bank Account Details -</strong> Details of the bank account where foreign contributions will be received.</li>

                <li><strong>Memorandum and Articles of Association -</strong> Documents outlining the organizational structure and objectives.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our FCRA Registration Services</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  style={{ background: 'rgba(197, 203, 237, 0.1)' }}
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Advantages of Our FCRA Registration Services</h6>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Disadvantages of Our FCRA Registration Services</h6>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Required for FCRA Registration</h6>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Steps to Complete FCRA Registration</h6>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default FCRARegistration;