import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const PrepInMinutes = ({ height }) => {

  // Preparation in Minutes grid items
  const items = [
    { title: 'Quick Setup', description: 'Get your documents and preparations done swiftly without delays.' },
    { title: 'Easy Instructions', description: 'Follow straightforward steps for efficient preparation and submission.' },
    { title: 'Instant Access', description: 'Access all required resources and templates instantly for quick preparation.' },
    { title: 'Expert Assistance', description: 'Receive expert guidance to ensure everything is in order and ready for submission.' },
    { title: 'Efficient Process', description: 'Streamline the preparation process to save time and avoid unnecessary complications.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Speed - Complete your preparation tasks in a fraction of the time with our efficient process.",
    "Clarity - Clear instructions and expert guidance make the preparation process simple and straightforward.",
    "Convenience - Access all necessary resources and templates quickly and easily.",
    "Expert Help - Professional assistance ensures that your preparation is thorough and accurate.",
    "Time-Saving - Streamlined processes minimize the time required for document preparation and submission."
  ];

  const disadvantages = [
    "Dependence on Tools - Requires access to specific tools or templates which might not be available to everyone.",
    "Learning Curve - There might be a learning curve for those unfamiliar with the preparation process.",
    "Potential Errors - Quick processes may sometimes lead to overlooked details or mistakes.",
    "Limited Customization - Predefined templates might limit customization options for specific needs."
  ];

  const documents = [
    {
      title: "Preparation Checklist",
      description: "A comprehensive checklist to ensure you have all necessary documents and information ready."
    },
    {
      title: "Templates",
      description: "Access to various templates that can be used to streamline your preparation process."
    },
    {
      title: "Instruction Manual",
      description: "Detailed instructions on how to complete the preparation efficiently and correctly."
    },
    {
      title: "Expert Contact",
      description: "Contact details for professional assistance if needed during the preparation phase."
    }
  ];

  const steps = [
    {
      title: "Review Requirements",
      description: "Understand the requirements and gather all necessary documents for preparation."
    },
    {
      title: "Follow Instructions",
      description: "Use the provided instructions and templates to complete your preparation tasks."
    },
    {
      title: "Verify Information",
      description: "Double-check all information and documents to ensure accuracy and completeness."
    },
    {
      title: "Submit Documents",
      description: "Submit your prepared documents through the appropriate channels or platforms."
    },
    {
      title: "Seek Assistance",
      description: "Contact experts if you encounter any issues or need further guidance during the process."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Preparation in Minutes Made Simple
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Experience a streamlined preparation process that saves you time and effort. Our approach ensures that you complete your preparations quickly and accurately.
            </p>

            <p>Utilize our tools and expert assistance to get everything done in no time and avoid unnecessary delays.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Streamlined Preparation in Minutes</h6>
          <p className='text-center mt-4'>Our preparation process is designed to be quick and efficient, ensuring you can complete your tasks in minutes with minimal effort.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Preparation in Minutes?</h6>

            <p className='mb-4'>Preparation in Minutes refers to an efficient and rapid approach to completing necessary documentation and tasks. Our process enables you to prepare everything swiftly while ensuring accuracy and completeness.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Quick Preparation</h6>
            <p className='mb-4'>To benefit from our quick preparation services, ensure you have:

              <ul>
                <li><strong>Access to Required Documents -</strong> All necessary documents and information ready for submission.</li>

                <li><strong>Follow Guidelines -</strong> Adhere to provided instructions and utilize available templates effectively.</li>

                <li><strong>Timely Submission -</strong> Ensure that documents are submitted within the specified time frame.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Our Preparation Services</h6>

            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Quick Preparation</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Quick Preparation</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Quick Preparation</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps for Quick Preparation</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Quick Preparation?</h6>
            <p>We offer a streamlined process to help you complete your preparations in minutes. Our expert team ensures that all steps are handled efficiently, allowing you to save time and avoid unnecessary complications.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default PrepInMinutes;