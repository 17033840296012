import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const InsuranceBrokerLicense = ({ height }) => {

  // Insurance Broker License grid items
  const items = [
    { title: 'Regulatory Compliance', description: 'Ensure compliance with IRDAI regulations and guidelines.' },
    { title: 'Access to Markets', description: 'Gain access to insurance markets and sell policies from multiple insurers.' },
    { title: 'Earn Commissions', description: 'Earn commissions from selling insurance products to clients.' },
    { title: 'Market Expertise', description: 'Enhance your expertise in insurance markets and products.' },
    { title: 'Customer Trust', description: 'Build customer trust by providing unbiased advice and multiple options.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Market Access - Brokers can offer policies from multiple insurers, giving customers a variety of options.",
    "Commission Earnings - Brokers earn commissions on policies sold, providing a lucrative income opportunity.",
    "Unbiased Advice - Insurance brokers provide impartial advice, acting in the best interest of the client.",
    "Expert Knowledge - Brokers have extensive knowledge of the insurance industry and available products.",
    "Trust Building - With unbiased advice, brokers can build long-lasting relationships with clients.",
  ];

  const disadvantages = [
    "Compliance Burden - Requires adherence to strict IRDAI regulations and guidelines.",
    "Initial Capital Requirement - Obtaining an Insurance Broker License requires substantial capital.",
    "Licensing Process - The licensing process can be time-consuming and involves thorough scrutiny.",
    "Ongoing Compliance - Regular reporting and compliance checks are necessary to maintain the license.",
    "Market Competition - The insurance brokerage market is highly competitive, requiring constant effort to stand out.",
  ];

  const documents = [
    {
      title: "Business Plan",
      description: "Detailed business plan showcasing your brokerage model and target market."
    },
    {
      title: "Financial Statements",
      description: "Audited financial statements for the previous years to demonstrate financial stability."
    },
    {
      title: "Net Worth Certificate",
      description: "A certificate from a Chartered Accountant showcasing the net worth of the applicant."
    },
    {
      title: "Directors’ Details",
      description: "Details of directors, including identification proof, financial background, and experience."
    },
    {
      title: "Office Lease Agreement",
      description: "Proof of office space in accordance with the IRDAI guidelines."
    }
  ];

  const steps = [
    {
      title: "Prepare Documents",
      description: "Gather the necessary documents, such as a business plan, financial records, and identification proof of directors."
    },
    {
      title: "Online Application",
      description: "Submit the IRDAI Insurance Broker License application online along with the required documents."
    },
    {
      title: "Application Review",
      description: "IRDAI reviews the application, scrutinizes documents, and may request additional information."
    },
    {
      title: "Capital Deposit",
      description: "Deposit the required capital in a designated account as per IRDAI regulations."
    },
    {
      title: "Receive License",
      description: "Once approved, receive the Insurance Broker License and commence operations."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Insurance Broker License
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Obtain an IRDAI-approved Insurance Broker License and start your insurance brokerage business.
            </p>

            <p>The license allows you to provide unbiased advice to customers and sell insurance policies from multiple insurers, offering the best solutions to your clients.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Insurance Broker License</h6>
          <p className='text-center mt-4'>An Insurance Broker License enables you to act as an intermediary between insurance companies and clients, offering unbiased advice and multiple product options.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is an Insurance Broker License?</h6>

            <p className='mb-4'>An Insurance Broker License, issued by the IRDAI, allows individuals or firms to act as intermediaries between insurance companies and customers. Brokers can offer policies from multiple insurers, providing clients with various options to meet their insurance needs.</p>
          </div>

          <div id="application" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Insurance Broker License</h6>
            <p className='mb-4'>To obtain an Insurance Broker License, follow these steps:

              <ul>
                <li><strong>Prepare Documents -</strong> Gather necessary documents like a business plan, financial records, and director details.</li>

                <li><strong>Submit Online Application -</strong> Submit the IRDAI application along with all required documents.</li>

                <li><strong>Application Review -</strong> IRDAI reviews the application and may request additional information.</li>

                <li><strong>Capital Deposit -</strong> Deposit the required capital in a designated account.</li>

                <li><strong>Receive License -</strong> Once approved, receive the Insurance Broker License and start operating.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of an Insurance Broker License</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className='AdvantagesLLP'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Advantages of Insurance Broker License</h6>
            <ul>
              {advantages.map((advantage, index) => (
                <li key={index}>{advantage}</li>
              ))}
            </ul>
          </div>

          <div className='DisadvantagesLLP'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Disadvantages of Insurance Broker License</h6>
            <ul>
              {disadvantages.map((disadvantage, index) => (
                <li key={index}>{disadvantage}</li>
              ))}
            </ul>
          </div>

          <div id="documents" className='docs-req'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Documents Required for Insurance Broker License</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {documents.map((doc, index) => (
                <Card
                  key={index}
                  title={doc.title}
                  description={doc.description}
                />
              ))}
            </div>
          </div>

          <div id="process" className='steps w-full flex flex-wrap' >
            <div className='stepsToRegister'>
              <h6 className='text-blue-500 text-lg font-semibold text-center'>Steps to Complete Insurance Broker License</h6>
              <ul>
                {steps.map((step, index) => (
                  <li key={index}>
                    <strong>{step.title} -</strong> {step.description}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Insurance Broker License?</h6>
            <p>We provide expert assistance throughout the Insurance Broker License application process, ensuring compliance with IRDAI regulations and helping you navigate the complexities of the industry.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default InsuranceBrokerLicense;