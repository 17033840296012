import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const GenerateForm16 = ({ height }) => {

  // Form 16 grid items
  const items = [
    { title: 'Understand Form 16', description: 'Form 16 is a certificate issued by an employer, summarizing the tax deducted and paid to the government on behalf of an employee. It is essential for filing income tax returns.' },
    { title: 'Gather Required Details', description: 'Collect all necessary documents and details, including salary slips, tax deductions, and employer information to generate Form 16 accurately.' },
    { title: 'Generate Form 16', description: 'Use tax software or consult with your payroll department to generate Form 16 based on the gathered details and ensure all fields are correctly filled.' },
    { title: 'Verify Information', description: 'Check the generated Form 16 for accuracy, ensuring that all the information, including tax deductions and personal details, is correct.' },
    { title: 'Distribute Form 16', description: 'Provide the completed Form 16 to employees for their records and to facilitate the filing of their income tax returns.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Accurate Documentation - Provides a precise record of tax deductions and payments made, essential for accurate tax filing.",
    "Employer Compliance - Ensures that employers meet their tax obligations and provide necessary documentation to employees.",
    "Ease of Tax Filing - Simplifies the process of filing income tax returns for employees by providing a consolidated tax statement.",
    "Financial Transparency - Promotes transparency in salary and tax deductions, helping employees understand their earnings and tax liabilities.",
    "Legal Requirement - Meets legal requirements for tax documentation and helps avoid potential penalties for non-compliance.",
  ];

  const disadvantages = [
    "Complexity - Generating Form 16 can be complex, requiring accurate input of various tax-related details.",
    "Errors - Mistakes in Form 16 can lead to discrepancies and issues during tax filing.",
    "Dependence on Employer - Employees depend on their employers to generate and provide Form 16, which may cause delays if not done promptly.",
    "Cost - There may be additional costs associated with tax software or professional services for generating Form 16.",
    "Time-Consuming - The process of collecting details and generating Form 16 can be time-consuming, especially for large organizations.",
  ];

  const documents = [
    {
      title: "Salary Slips",
      description: "Monthly salary slips that provide details of salary payments, deductions, and other relevant information for generating Form 16."
    },
    {
      title: "Tax Deduction Records",
      description: "Records of tax deductions made by the employer during the financial year, including any additional deductions or exemptions."
    },
    {
      title: "Employer Details",
      description: "Information about the employer, including name, address, and PAN, required for accurate completion of Form 16."
    },
    {
      title: "PAN Details",
      description: "Personal Identification Number (PAN) details of the employee, necessary for generating and verifying Form 16."
    },
    {
      title: "Income Details",
      description: "Complete details of the employee's income, including salary, bonuses, and any other taxable income for accurate Form 16 preparation."
    }
  ];

  const steps = [
    {
      title: "Collect Documentation",
      description: "Gather all necessary documents and details required for generating Form 16, including salary slips and tax deduction records."
    },
    {
      title: "Prepare Details",
      description: "Compile the collected information and prepare it for entry into the tax software or documentation system."
    },
    {
      title: "Generate Form 16",
      description: "Use the prepared details to generate Form 16 using tax software or with the help of your payroll department."
    },
    {
      title: "Verify Accuracy",
      description: "Review the generated Form 16 for any errors or discrepancies and ensure all details are correct before distribution."
    },
    {
      title: "Distribute Form 16",
      description: "Provide the completed Form 16 to employees for their records and use in income tax return filing."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Generate Form 16
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Form 16 is an essential document for employees, summarizing the tax deducted and paid on their behalf by the employer. It plays a crucial role in filing income tax returns and ensures accurate tax documentation.
            </p>

            <p>Efficiently generate Form 16 with our services to streamline tax documentation and compliance.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Generate Form 16</h6>
          <p className='text-center mt-4'>Our Form 16 generation services ensure accurate and timely preparation of this crucial document for employees. We assist in streamlining the process and ensuring compliance with tax regulations.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Form 16?</h6>

            <p className='mb-4'>Form 16 is a certificate issued by an employer to an employee, summarizing the total salary paid and the tax deducted during the financial year. It serves as proof of tax payments and is necessary for filing income tax returns.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Form 16</h6>
            <p className='mb-4'>Employees who have received salary from an employer and had tax deducted at source are eligible for Form 16. It is issued annually and should be provided to all employees whose tax has been deducted by the employer.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Form 16</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Generating Form 16</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Form 16 Generation</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Generating Form 16</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default GenerateForm16;