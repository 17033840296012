import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const SellYourCompany = ({ height }) => {

  // Sell Your Company grid items
  const items = [
    { title: 'Business Valuation', description: 'We offer fair and accurate valuations based on your company’s market potential, financial health, and assets.' },
    { title: 'Streamlined Process', description: 'Our team ensures a hassle-free process, handling legal paperwork and negotiations on your behalf.' },
    { title: 'Confidentiality', description: 'We value your privacy and guarantee that your company’s details will remain confidential throughout the sale process.' },
    { title: 'Flexible Payment Options', description: 'We provide multiple payment options to suit your financial needs and requirements.' },
    { title: 'Fast Turnaround', description: 'Our goal is to finalize the sale quickly while ensuring a smooth transition for your company.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Quick Sale - You can sell your company without the usual delays of finding buyers in the market.",
    "Fair Valuation - We provide competitive valuations to ensure you receive the right value for your business.",
    "Hassle-Free - We handle all the paperwork, negotiations, and legal work for you, saving time and effort.",
    "Confidentiality - We ensure complete confidentiality throughout the process, keeping sensitive information secure.",
    "Flexible Terms - We offer flexible payment terms to accommodate your financial needs."
  ];

  const disadvantages = [
    "Reduced Flexibility - You may not have control over certain terms of the sale compared to selling on your own.",
    "Potential Lower Offers - Selling directly to a buyer may fetch higher offers compared to selling to a third party.",
    "Limited Buyer Interaction - Since we handle negotiations, you may have less direct communication with the final buyer.",
    "Time Constraints - The need to complete the sale quickly might limit your negotiation leverage.",
    "Legal Restrictions - There might be restrictions or clauses that you have to agree to when selling to us."
  ];

  const documents = [
    {
      title: "Business Financial Statements",
      description: "Submit your company's balance sheets, income statements, and financial reports for accurate valuation."
    },
    {
      title: "Ownership Proof",
      description: "Provide documentation that confirms the ownership structure and legal authority to sell the company."
    },
    {
      title: "Tax Filings",
      description: "Make available recent tax filings and records to ensure compliance with tax obligations."
    },
    {
      title: "Asset Documentation",
      description: "Provide details of your company’s tangible and intangible assets for evaluation."
    },
    {
      title: "Business Contracts",
      description: "Submit all active contracts and agreements that affect the business."
    }
  ];

  const steps = [
    {
      title: "Initial Consultation",
      description: "Reach out to us for a confidential consultation and provide us with the necessary details about your company."
    },
    {
      title: "Business Valuation",
      description: "Our team will conduct a detailed valuation of your company based on financial health, market trends, and other factors."
    },
    {
      title: "Offer Presentation",
      description: "We will present a fair and competitive offer based on the valuation and negotiate terms to meet your needs."
    },
    {
      title: "Legal Documentation",
      description: "We handle all legal paperwork and ensure a secure transfer of ownership with no hassle."
    },
    {
      title: "Finalize the Sale",
      description: "Once all terms are agreed upon, we finalize the sale, ensuring a smooth transition for both parties."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Sell Your Company Quickly and Confidentially
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Looking to sell your company? We offer a simple and secure process for business owners looking for a quick sale, all while ensuring your privacy and providing fair valuations.
            </p>

            <p>Our experts handle every step, from valuation to closing the deal, providing a hassle-free experience for you.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Quick, Hassle-Free Company Sale</h6>
          <p className='text-center mt-4'>We offer a smooth and secure process for selling your company, handling all aspects of the transaction to give you peace of mind. From accurate valuations to fast turnarounds, we ensure a seamless experience.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Sell Your Company to Us?</h6>

            <p className='mb-4'>This service offers business owners the opportunity to sell their company directly to us, allowing for a quick, straightforward sale without the complexities of traditional methods. We handle all legal, financial, and negotiation aspects for a seamless transaction.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Selling Your Company</h6>
            <p className='mb-4'>To sell your company to us, ensure the following:

              <ul>
                <li><strong>Ownership Proof -</strong> Provide documentation that verifies your legal ownership of the company.</li>

                <li><strong>Financial Records -</strong> Up-to-date financial statements are required to assess the value of the business.</li>

                <li><strong>Tax Compliance -</strong> Make sure all tax filings and obligations are current.</li>

                <li><strong>Asset Inventory -</strong> Prepare a detailed list of your company's assets, including tangible and intangible items.</li>

                <li><strong>No Legal Disputes -</strong> Ensure your company is free from ongoing legal disputes or claims.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Selling Your Company to Us</h6>

            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Selling Your Company to Us</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Selling Your Company to Us</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Selling Your Company to Us</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps for Selling Your Company to Us</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Selling Your Company?</h6>
            <p>We ensure that your company sale is conducted quickly and efficiently, with our legal and financial experts handling all the heavy lifting. Our goal is to provide a smooth, secure, and satisfactory process for all parties involved.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default SellYourCompany;