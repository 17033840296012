import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TaxPlanningServicesNavbar = ({ height }) => {

  // Tax Planning Services grid items
  const items = [
    { title: 'Personalized Tax Strategies', description: 'Receive tailored tax planning strategies to optimize your tax savings and reduce liabilities.' },
    { title: 'Tax Deduction Maximization', description: 'Identify and maximize available tax deductions to minimize your taxable income.' },
    { title: 'Investment Planning', description: 'Strategically plan your investments to leverage tax benefits and grow your wealth.' },
    { title: 'Retirement Planning', description: 'Plan for your retirement with tax-efficient strategies to ensure a secure financial future.' },
    { title: 'Tax Compliance Guidance', description: 'Stay compliant with tax regulations and avoid penalties through expert advice and support.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Customized Strategies - Tailor-made tax strategies to fit your unique financial situation and goals.",
    "Maximized Savings - Identify and utilize all available deductions to reduce your taxable income.",
    "Investment Benefits - Optimize your investments for tax benefits and growth potential.",
    "Retirement Security - Plan for a tax-efficient retirement to ensure long-term financial stability.",
    "Regulatory Compliance - Expert guidance to ensure compliance with tax laws and avoid penalties.",
  ];

  const disadvantages = [
    "Complexity - Tax planning can be complex and may require a thorough understanding of tax laws and regulations.",
    "Cost - Professional tax planning services may involve additional costs.",
    "Dependence on Advice - Relying on expert advice means any errors could affect your tax situation.",
  ];

  const documents = [
    {
      title: "Income Statements",
      description: "Provide detailed income statements from all sources including salary, investments, and other income."
    },
    {
      title: "Expense Records",
      description: "Document all personal and business-related expenses that may qualify for deductions."
    },
    {
      title: "Investment Records",
      description: "Records of all investments including stocks, bonds, and retirement accounts."
    },
    {
      title: "Tax Returns",
      description: "Previous years' tax returns to review past filings and plan for future tax years."
    },
    {
      title: "Retirement Accounts",
      description: "Information on retirement accounts and plans to incorporate tax-efficient strategies."
    }
  ];

  const steps = [
    {
      title: "Gather Financial Information",
      description: "Collect all relevant financial documents including income, expenses, and investments."
    },
    {
      title: "Analyze Tax Situation",
      description: "Review your current tax situation and identify opportunities for optimization."
    },
    {
      title: "Develop Tax Strategies",
      description: "Create personalized tax strategies to maximize savings and achieve financial goals."
    },
    {
      title: "Implement Strategies",
      description: "Put the developed strategies into action and make necessary adjustments to your financial plan."
    },
    {
      title: "Review and Monitor",
      description: "Regularly review and monitor your tax plan to ensure continued effectiveness and compliance."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Expert Tax Planning Services for Individuals
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Our tax planning services offer personalized strategies to optimize your tax savings and ensure compliance with tax regulations. Whether you need help with investment planning, retirement strategies, or maximizing deductions, we provide comprehensive support for your financial success.
            </p>

            <p>Enhance your financial strategy and reduce tax liabilities with our expert tax planning services.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Optimize Your Tax Strategy</h6>
          <p className='text-center mt-4'>Effective tax planning can significantly impact your financial well-being. Our services provide personalized strategies to help you minimize tax liabilities and maximize savings.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Tax Planning?</h6>

            <p className='mb-4'>Tax planning involves the development of strategies to optimize your tax liabilities, maximize deductions, and ensure compliance with tax regulations. Our services help individuals create effective tax plans tailored to their financial situation and goals.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Tax Planning Services</h6>
            <p className='mb-4'>To benefit from our tax planning services, you need to provide the following:

              <ul>
                <li><strong>Income Statements -</strong> Detailed statements of all sources of income, including salary, investments, and other earnings.</li>

                <li><strong>Expense Records -</strong> Documentation of personal and business-related expenses that may qualify for deductions.</li>

                <li><strong>Investment Records -</strong> Records of all investments including stocks, bonds, and retirement accounts.</li>

                <li><strong>Tax Returns -</strong> Previous years' tax returns for review and planning purposes.</li>

                <li><strong>Retirement Accounts -</strong> Information on retirement accounts and plans for tax-efficient strategies.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Our Tax Planning Services</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Our Tax Planning Services</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Our Tax Planning Services</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Tax Planning Services</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Set Up Tax Planning Services</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default TaxPlanningServicesNavbar;