import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const Invoicing = ({ height }) => {

  // Invoicing grid items
  const items = [
    { title: 'Automated Generation', description: 'Generate invoices automatically from your sales data, reducing manual effort and errors.' },
    { title: 'Customizable Templates', description: 'Use customizable templates to ensure invoices match your branding and business needs.' },
    { title: 'Compliance Features', description: 'Ensure your invoices comply with tax regulations and standards, avoiding legal issues.' },
    { title: 'Integrated Payment Options', description: 'Integrate with payment gateways for seamless invoice payments and tracking.' },
    { title: 'Real-Time Tracking', description: 'Track invoice statuses in real-time, from creation to payment, for better financial management.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Automated Generation - Automatically generate invoices from your sales records, saving time and reducing errors.",
    "Customizable Templates - Personalize invoice templates to reflect your brand and meet specific business requirements.",
    "Compliance Features - Includes features to ensure that invoices comply with tax laws and regulatory standards.",
    "Integrated Payment Options - Seamlessly integrate with payment gateways to facilitate easy invoice payments and tracking.",
    "Real-Time Tracking - Monitor the status of invoices in real-time, helping you manage finances more effectively.",
  ];

  const disadvantages = [
    "System Dependency - Requires reliable software and internet access to manage and generate invoices effectively.",
    "Learning Curve - May involve a learning curve for users unfamiliar with invoicing software or features.",
    "Integration Challenges - Integrating with existing systems or payment gateways can sometimes be complex.",
    "Cost of Software - There may be costs associated with invoicing software or services, depending on the features you choose.",
  ];

  const documents = [
    {
      title: "Company Details",
      description: "Include your company's legal name, address, and contact information on the invoice."
    },
    {
      title: "Client Information",
      description: "Add the client's name, address, and contact details to ensure proper invoicing."
    },
    {
      title: "Invoice Details",
      description: "Specify invoice number, date, and payment terms for clarity and record-keeping."
    },
    {
      title: "Product/Service Details",
      description: "Provide a detailed description of the products or services provided, including quantities and pricing."
    },
    {
      title: "Tax Information",
      description: "Include applicable taxes and tax rates, if any, to ensure compliance with tax regulations."
    }
  ];

  const steps = [
    {
      title: "Set Up Invoice Templates",
      description: "Customize your invoice templates to include all necessary business and client details."
    },
    {
      title: "Input Sales Data",
      description: "Enter the details of the sales transactions that need to be invoiced, including products or services provided."
    },
    {
      title: "Generate Invoice",
      description: "Create the invoice using your chosen template and sales data. Review for accuracy."
    },
    {
      title: "Send Invoice",
      description: "Send the invoice to the client via email or any other preferred method."
    },
    {
      title: "Track Payment",
      description: "Monitor the payment status of the invoice and follow up if necessary."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Streamlined Invoicing Solutions
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Efficient invoicing is crucial for managing business transactions. Our solutions offer automated, customizable invoicing to simplify your financial processes and ensure accuracy.
            </p>

            <p>Enhance your business operations with our advanced invoicing system designed to streamline and manage your billing efficiently.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Invoicing Made Easy</h6>
          <p className='text-center mt-4'>Our invoicing solutions are designed to simplify the billing process, ensuring accurate and efficient management of your financial transactions. From automated generation to real-time tracking, we've got you covered.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Invoicing?</h6>

            <p className='mb-4'>Invoicing is the process of generating and issuing bills for goods or services provided to clients. It is a critical aspect of financial management that ensures you receive payment for your business transactions in a timely manner.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Invoicing</h6>
            <p className='mb-4'>To create and manage invoices, you need to:

              <ul>
                <li><strong>Company Details -</strong> Your business's legal name, address, and contact information.</li>

                <li><strong>Client Information -</strong> The client's name, address, and contact details for accurate billing.</li>

                <li><strong>Invoice Details -</strong> Invoice number, date, and payment terms for proper record-keeping.</li>

                <li><strong>Product/Service Details -</strong> Detailed descriptions of the goods or services provided.</li>

                <li><strong>Tax Information -</strong> Applicable taxes and tax rates, if any, for compliance with tax regulations.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Our Invoicing Solutions</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Efficient Invoicing</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Invoicing Systems</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Invoicing</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Generate Invoices</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Our Invoicing Solutions?</h6>
            <p>Our invoicing solutions are designed to make billing easier, more accurate, and compliant with all necessary regulations. Partner with us to streamline your invoicing processes and enhance financial management.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default Invoicing;