import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TradeLicenseNavbar = ({ height }) => {

  // Trade License grid items
  const items = [
    { title: 'Regulated by Authorities', description: 'Trade licenses are issued and regulated by local municipal authorities.' },
    { title: 'Business Legitimacy', description: 'Provides legal authorization to conduct business activities.' },
    { title: 'Renewable License', description: 'Requires periodic renewal to ensure compliance with regulations.' },
    { title: 'Wide Scope', description: 'Applicable to a variety of business activities and sectors.' },
    { title: 'Compliance Requirement', description: 'Ensures compliance with local laws and regulations.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Authorization - Provides formal approval to operate a business legally.",
    "Enhanced Credibility - Boosts business reputation and trustworthiness.",
    "Compliance - Helps ensure that your business meets local regulations.",
    "Access to Public Contracts - Required for bidding on public contracts or tenders."
  ];

  const disadvantages = [
    "Renewal Process - Requires periodic renewal and may involve additional fees.",
    "Regulatory Compliance - Must adhere to local regulations and guidelines.",
    "Documentation - Involves submission of various documents which can be time-consuming."
  ];

  const documents = [
    {
      title: "Proof of Business Address",
      description: "Includes lease agreement or ownership documents."
    },
    {
      title: "Identity Proof",
      description: "Recent government-issued ID of the applicant."
    },
    {
      title: "Business Registration Document",
      description: "Proof of business registration or incorporation certificate."
    },
    {
      title: "Passport-size Photos",
      description: "Recent photographs of the applicant."
    },
    {
      title: "PAN Card",
      description: "For tax purposes."
    },
    {
      title: "No Objection Certificate (NOC)",
      description: "From the local municipal authority or landlord if applicable."
    }
  ];

  const steps = [
    {
      title: "Gather Required Documents",
      description: "Collect all necessary documents as per the guidelines."
    },
    {
      title: "Fill Out Application Form",
      description: "Complete the trade license application form with accurate details."
    },
    {
      title: "Submit Application",
      description: "Submit the completed application form and documents to the local authority."
    },
    {
      title: "Pay Fees",
      description: "Pay the applicable processing fees for the trade license."
    },
    {
      title: "Inspection",
      description: "An inspection may be conducted by the local authority if required."
    },
    {
      title: "Receive Trade License",
      description: "Once approved, receive the trade license from the authority."
    },
    {
      title: "Renewal",
      description: "Ensure to renew the trade license before its expiration date."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Trade License
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A Trade License is a legal document required to operate a business legally within a specific jurisdiction.
            </p>

            <p>10K+ Businesses Successfully Licensed</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Trade License Made Simple with T2F</h6>
          <p className='text-center mt-4'>Ready to establish a legitimate business with a trade license? Look no further than T2F. Our expertise ensures a smooth application process for your trade license, guiding you every step of the way. Contact us today to get started.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Trade License?</h6>

            <p className='mb-4'>A Trade License is a formal document that authorizes businesses to operate legally within a specific area. Here’s why trade licenses are essential:</p>
            <div>
              <ul><strong>Regulated by Authorities:</strong> Issued and regulated by local municipal authorities.</ul>

              <ul><strong>Business Legitimacy:</strong> Provides legal authorization to conduct business activities.</ul>

              <ul><strong>Renewable License:</strong> Requires periodic renewal to stay compliant.</ul>
            </div>

            <p>Trade licenses are crucial for ensuring that your business operations are recognized and compliant with local regulations.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To obtain a trade license, you need to fulfill the following criteria:

              <ul>
                <li><strong>Business Address -</strong> Proof of a valid business address within the jurisdiction.</li>

                <li><strong>Business Registration -</strong> Valid registration or incorporation certificate of the business.</li>

                <li ><strong>Applicant’s ID -</strong> Government-issued identity proof of the applicant.</li>
                <li ><strong>Application Form -</strong> Complete the trade license application form accurately.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of a Trade License</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of a Trade License</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of a Trade License</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='why' className='secondLLP pr-96 pl-16 mt-16'>
          <div className='Choosing'>
            <h6 className='text-blue-500 text-lg font-semibold'>Choosing Your Business Name</h6>
            <ul className='space-x-4'>
              <li><strong>Unique and Available - </strong> Ensure your business name is unique and not already in use.</li>

              <li><strong>Descriptive - </strong> Reflects the nature of your business clearly.</li>

              <li ><strong>Compliance -</strong> Ensure it meets local naming regulations.</li>
            </ul>
          </div>

          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Trade License Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='how' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth registration process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for Your Trade License Registration</h6>
            <p>T2F is your trusted partner for obtaining a trade license:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Guidance - </strong> Professional help with the entire trade license application process.</li>

              <li><strong>Document Assistance - </strong> Assistance with gathering and preparing required documents.</li>

              <li ><strong>Regulatory Compliance -</strong> Ensure adherence to local regulations and requirements.</li>

              <li ><strong>Efficient Processing -</strong> Fast and efficient processing of your trade license application.</li>

              <li ><strong>Ongoing Support -</strong> Continuous support throughout the registration and renewal process.</li>

              <li ><strong>Fee Management -</strong> Assistance with fee payment and submission procedures.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeLicenseNavbar;
