import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ITReturn6Navbar = ({ height }) => {

  // IT-Return 6 grid items
  const items = [
    { title: 'Income from Business or Profession', description: 'Guidance on reporting income from business or professional activities, including deductions for business expenses.' },
    { title: 'Income from Capital Gains', description: 'Detailed information on taxation of capital gains from the sale of assets such as property, shares, and mutual funds.' },
    { title: 'Income from Other Sources', description: 'How to handle and report income from sources other than business or capital gains, such as interest, dividends, and royalties.' },
    { title: 'Deductions and Exemptions', description: 'Overview of available deductions and exemptions to reduce taxable income and optimize tax liabilities.' },
    { title: 'Tax Filing Requirements', description: 'Information on the tax filing requirements and deadlines for IT-Return 6, ensuring compliance with tax regulations.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Comprehensive Reporting - Covers a wide range of income sources including business, capital gains, and other incomes.",
    "Detailed Deductions - Provides guidance on various deductions and exemptions to reduce taxable income.",
    "Clear Filing Requirements - Outlines tax filing requirements and deadlines to ensure timely compliance.",
    "Optimized Tax Planning - Helps in strategic tax planning to minimize liabilities.",
    "Efficient Documentation - Streamlines the documentation process for different income sources.",
  ];

  const disadvantages = [
    "Complexity in Reporting - Handling multiple income sources and deductions can be complex.",
    "Detailed Documentation Needed - Requires comprehensive documentation to support various claims.",
    "Potential for Errors - Increased risk of errors if information is not accurately reported.",
  ];

  const documents = [
    {
      title: "Business Income Records",
      description: "Detailed records of business income and expenses, including profit and loss statements."
    },
    {
      title: "Capital Gains Statements",
      description: "Statements showing capital gains from the sale of assets like property and investments."
    },
    {
      title: "Income from Other Sources",
      description: "Proof of income from other sources, such as interest, dividends, and royalties."
    },
    {
      title: "Deduction Documents",
      description: "Documents supporting claims for deductions and exemptions, including receipts and certificates."
    },
    {
      title: "Filing Compliance Records",
      description: "Records related to tax filing requirements and deadlines, including acknowledgments of return submission."
    }
  ];

  const steps = [
    {
      title: "Gather All Income Documents",
      description: "Collect records for business income, capital gains, and other sources of income."
    },
    {
      title: "Review Deductions and Exemptions",
      description: "Identify and gather documents for all eligible deductions and exemptions."
    },
    {
      title: "Prepare IT-Return 6",
      description: "Fill out the IT-Return 6 form with accurate information on income, deductions, and exemptions."
    },
    {
      title: "File the Return",
      description: "Submit the completed IT-Return 6 form electronically or manually according to tax regulations."
    },
    {
      title: "Confirm Compliance",
      description: "Ensure compliance with tax regulations and deadlines, and retain records of submission."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              IT-Return 6: Comprehensive Tax Filing Solutions
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              IT-Return 6 provides an all-encompassing solution for reporting various income sources, including business, capital gains, and other income types. Utilize our services for optimized tax planning and accurate filing.
            </p>

            <p>Efficiently manage your tax filing with IT-Return 6, covering all necessary income sources and maximizing available deductions and exemptions.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>IT-Return 6 Filing Services</h6>
          <p className='text-center mt-4'>Our IT-Return 6 services offer a complete solution for reporting income from business, capital gains, and other sources. Benefit from expert assistance in optimizing your tax filings and ensuring compliance with tax regulations.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is IT-Return 6?</h6>

            <p className='mb-4'>IT-Return 6 is designed for individuals with multiple income sources, including business income, capital gains, and other earnings. It simplifies the tax filing process and provides detailed guidance on reporting and deductions.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for IT-Return 6</h6>
            <p className='mb-4'>To file IT-Return 6, you should meet the following criteria:
              <ul>
                <li><strong>Multiple Income Sources -</strong> Applicable if you have income from business, capital gains, and other sources.</li>

                <li><strong>Documentation -</strong> Ensure you have all required documentation for income reporting and deduction claims.</li>

                <li><strong>Filing Requirements -</strong> Be aware of tax filing requirements and deadlines to ensure compliance.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of IT-Return 6</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className="w-full px-16 mt-10 pt-2">
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of IT-Return 6</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for IT-Return 6</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>IT-Return 6 Filing Process</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ITReturn6Navbar;