import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const PANTANNavbar = ({ height }) => {

  // PAN & TAN Registration service grid items
  const items = [
    { title: 'Essential for Compliance', description: 'PAN and TAN are essential for compliance with tax regulations and conducting business operations smoothly.' },
    { title: 'Streamlined Process', description: 'Simplifies the process of obtaining PAN and TAN for exporters, reducing paperwork and processing time.' },
    { title: 'Expert Guidance', description: 'Provides expert assistance throughout the registration process to ensure accuracy and efficiency.' },
    { title: 'Legal Requirement', description: 'Mandatory for financial transactions, tax payments, and business operations.' },
    { title: 'Efficient Handling', description: 'Efficient handling of PAN and TAN applications to avoid delays and complications.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Essential for Compliance - PAN and TAN are crucial for compliance with tax regulations and smooth business operations.",
    "Streamlined Process - Our process simplifies the registration of PAN and TAN, making it quicker and less cumbersome.",
    "Expert Guidance - Receive professional assistance to ensure accurate and efficient registration of PAN and TAN.",
    "Legal Requirement - PAN and TAN are legally required for various financial transactions and tax-related activities.",
    "Efficient Handling - We handle the registration process efficiently to avoid any delays or complications.",
  ];

  const disadvantages = [
    "Documentation Requirements - Requires specific documents which might be a hassle to gather for some applicants.",
    "Processing Time - The registration process might take time depending on the authorities' workload.",
    "Complexity for Some - Understanding the requirements and process can be complex for individuals unfamiliar with PAN and TAN registration.",
  ];

  const documents = [
    {
      title: "Proof of Identity",
      description: "A valid government-issued identity proof such as Aadhar card, passport, or driving license."
    },
    {
      title: "Proof of Address",
      description: "Proof of residence, such as a utility bill or bank statement, needs to be provided."
    },
    {
      title: "Business Registration Certificate",
      description: "For TAN, a certificate of business registration or incorporation is required."
    },
    {
      title: "PAN Card of Authorized Person",
      description: "PAN card of the authorized signatory of the business or individual applying for TAN."
    },
    {
      title: "Photographs",
      description: "Recent passport-sized photographs may be required for the application process."
    }
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents including proof of identity, proof of address, and business registration certificate."
    },
    {
      title: "Prepare Application",
      description: "Fill out the PAN and TAN application forms with accurate information."
    },
    {
      title: "Submit Application",
      description: "Submit the completed application forms along with the required documents to the relevant authorities."
    },
    {
      title: "Verification Process",
      description: "Complete the verification process as required by the authorities for PAN and TAN registration."
    },
    {
      title: "Receive PAN & TAN",
      description: "After successful verification, receive your PAN and TAN certificates for business use."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              PAN & TAN Registration for Exporters
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              PAN (Permanent Account Number) and TAN (Tax Deduction and Collection Account Number) are crucial for exporters to manage their tax obligations and conduct business transactions. Our efficient registration process ensures that you get both numbers quickly and without hassle.
            </p>

            <p>Many exporters have streamlined their PAN and TAN registration with our expert help.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Simplified PAN & TAN Registration</h6>
          <p className='text-center mt-4'>Obtaining PAN and TAN is crucial for exporters to comply with tax regulations and carry out business operations efficiently. We make the registration process seamless and straightforward, ensuring you get both numbers without delays.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is PAN & TAN Registration?</h6>

            <p className='mb-4'>PAN (Permanent Account Number) is a unique identifier for taxpayers, while TAN (Tax Deduction and Collection Account Number) is used for deducting or collecting tax at source. Both are essential for managing tax obligations and ensuring compliance with regulations.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for PAN & TAN Registration</h6>
            <p className='mb-4'>To register for PAN and TAN, you need to:

              <ul>
                <li><strong>Proof of Identity -</strong> Provide a valid government-issued identity proof such as an Aadhar card, passport, or driving license.</li>

                <li><strong>Proof of Address -</strong> Submit proof of residence, such as a utility bill or bank statement.</li>

                <li><strong>Business Registration Certificate -</strong> For TAN, provide a certificate of business registration or incorporation.</li>

                <li><strong>PAN Card of Authorized Person -</strong> PAN card of the authorized signatory of the business or individual applying for TAN.</li>

                <li><strong>Photographs -</strong> Recent passport-sized photographs may be required for the application process.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of PAN & TAN Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of PAN & TAN Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of PAN & TAN Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for PAN & TAN Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for PAN & TAN Registration</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default PANTANNavbar;