import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const CompanySalePurchase = ({ height }) => {

  // Company Sale/Purchase grid items
  const items = [
    { title: 'Legal Documentation', description: 'Ensure all legal documents are in place for a smooth transaction between the buyer and seller.' },
    { title: 'Asset Evaluation', description: 'Conduct thorough evaluations of assets to determine the correct market value before finalizing the deal.' },
    { title: 'Tax Compliance', description: 'Make sure both parties adhere to tax obligations and regulations related to the sale or purchase of a company.' },
    { title: 'Due Diligence', description: 'Perform detailed due diligence to investigate the financial health and legal standing of the company.' },
    { title: 'Transaction Security', description: 'Utilize secure transaction methods to protect both parties from potential fraud or legal issues.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Security - Ensures that all necessary legal documents are handled, reducing the risk of future disputes.",
    "Accurate Asset Valuation - A fair and thorough valuation helps both parties reach a fair agreement.",
    "Tax Benefits - Depending on the structure of the sale or purchase, there could be potential tax advantages for both parties.",
    "Streamlined Process - Engaging professional assistance can streamline the sale/purchase process, saving time and effort.",
    "Comprehensive Due Diligence - Helps avoid potential legal or financial pitfalls by thoroughly investigating the company’s records.",
  ];

  const disadvantages = [
    "Complex Documentation - The process requires a large amount of paperwork and legal documentation.",
    "Risk of Discrepancies - If due diligence is not properly conducted, there is a risk of financial or legal discrepancies.",
    "Time-Consuming - The sale or purchase of a company can be a lengthy process, often requiring months to complete.",
    "Costs - Legal, financial, and advisory fees can increase the overall cost of the transaction.",
    "Potential for Hidden Liabilities - Despite thorough investigations, there could still be undiscovered liabilities."
  ];

  const documents = [
    {
      title: "Company Financial Statements",
      description: "Provide up-to-date financial records including balance sheets, income statements, and cash flow reports."
    },
    {
      title: "Legal Documents",
      description: "Ensure the availability of legal documents such as contracts, leases, and intellectual property agreements."
    },
    {
      title: "Tax Records",
      description: "Make sure tax returns and related records are accurate and available for review."
    },
    {
      title: "Asset Evaluation Reports",
      description: "Provide asset valuation reports to determine the value of tangible and intangible assets."
    },
    {
      title: "Ownership Documents",
      description: "Submit documents verifying the ownership structure of the company being sold or purchased."
    }
  ];

  const steps = [
    {
      title: "Prepare Documentation",
      description: "Gather all necessary legal, financial, and tax documents related to the company."
    },
    {
      title: "Asset Valuation",
      description: "Conduct an accurate asset evaluation to determine the value of the company’s assets."
    },
    {
      title: "Due Diligence",
      description: "Perform due diligence to investigate the company’s financial health, liabilities, and legal standing."
    },
    {
      title: "Negotiate Terms",
      description: "Negotiate the terms of the sale or purchase, ensuring both parties agree on key conditions."
    },
    {
      title: "Finalize the Transaction",
      description: "Complete the transaction by signing legal documents and transferring ownership."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Secure Company Sale and Purchase Solutions
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Whether you are buying or selling a company, it is crucial to ensure all legal, financial, and tax aspects are handled with care. Our services streamline the process, ensuring a secure and compliant transaction.
            </p>

            <p>We assist in navigating the complexities of company sales and purchases, from documentation to final transaction completion.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Smooth Company Sale/Purchase Process</h6>
          <p className='text-center mt-4'>Our services are designed to simplify the sale or purchase of a company, ensuring a streamlined and compliant process. From legal documentation to due diligence, we provide comprehensive support at every step.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Company Sale/Purchase?</h6>

            <p className='mb-4'>Company sale or purchase involves the legal transfer of ownership between parties. The process requires meticulous attention to legal, financial, and regulatory details to ensure a smooth and successful transaction.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Company Sale/Purchase</h6>
            <p className='mb-4'>To sell or purchase a company, ensure that:

              <ul>
                <li><strong>Legal Documents -</strong> Ensure the availability of all legal contracts and agreements for a clear transaction.</li>

                <li><strong>Financial Records -</strong> Have accurate and up-to-date financial statements for evaluation.</li>

                <li><strong>Tax Compliance -</strong> Ensure that all tax returns and liabilities are in order.</li>

                <li><strong>Ownership Structure -</strong> Provide clear documentation of the ownership structure for clarity in the transaction.</li>

                <li><strong>Asset Evaluation -</strong> Complete an accurate evaluation of assets for fair pricing.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Company Sale/Purchase</h6>

            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Company Sale/Purchase</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Company Sale/Purchase</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Company Sale/Purchase</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps for Company Sale/Purchase</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Company Sale/Purchase?</h6>
            <p>We ensure that your company sale or purchase is handled with professionalism, transparency, and efficiency. With our legal, financial, and tax expertise, we make sure the process is smooth and secure for both parties involved.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default CompanySalePurchase;