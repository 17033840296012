import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';

const PrivateLimitedNavbar = ({ height }) => {

  // Private Limited service grid items
  const items = [
    { title: 'Separate Legal Entity', description: 'A Private Limited Company is a separate legal entity from its owners.' },
    { title: 'Limited Liability', description: 'Shareholders have limited liability for company debts.' },
    { title: 'Perpetual Succession', description: 'The company continues to exist regardless of changes in ownership.' },
    { title: 'Ability to Raise Capital', description: 'Easier to raise capital through private investment.' },
    { title: 'Compliance Requirements', description: 'Subject to specific regulatory and compliance requirements.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Limited Liability - Shareholders are liable only up to their investment in the company.",
    "Separate Legal Entity - The company exists independently of its owners.",
    "Perpetual Succession - The company continues regardless of changes in ownership or management.",
    "Easier Fund Raising - Ability to raise capital through private investors and other means.",
    "Credibility - Often perceived as more credible than sole proprietorships or partnerships."
  ];

  const disadvantages = [
    "Regulatory Compliance - Must adhere to strict regulatory and reporting requirements.",
    "Higher Setup Costs - Generally more expensive to set up than a sole proprietorship or partnership.",
    "Restrictions on Share Transfer - Shares cannot be freely transferred; requires agreement from existing shareholders.",
    "Ongoing Costs - Regular compliance costs and maintenance of statutory records."
  ];

  const documents = [
    {
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills."
    },
    {
      title: "Address Proof of Directors",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      title: "Identity Proof of Directors",
      description: "Passport, Aadhar card, or driving license."
    },
    {
      title: "Passport-size Photos",
      description: "With a white background."
    },
    {
      title: "PAN Card",
      description: "For all directors."
    },
    {
      title: "Digital Signature Certificate (DSC)",
      description: "Required for at least one director."
    }
  ];

  const steps = [
    {
      title: "Obtain a Digital Signature Certificate (DSC)",
      description: "Required for all directors."
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      description: "For all directors."
    },
    {
      title: "Choose a Company Name",
      description: "Ensure it meets legal requirements and is not already taken."
    },
    {
      title: "Prepare and File Incorporation Documents",
      description: "Submit necessary documents and forms to the Registrar of Companies (RoC)."
    },
    {
      title: "Draft the Memorandum and Articles of Association",
      description: "Outline the company's objectives and internal rules."
    },
    {
      title: "Receive Certificate of Incorporation",
      description: "Issued by the Registrar of Companies (RoC)."
    },
    {
      title: "Apply for PAN and TAN",
      description: "Essential for tax purposes."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#registration" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#why-choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Private Limited Company
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A Private Limited Company is a popular business structure that offers limited liability and separate legal entity status.
            </p>

            <p>10K+ Trusted Companies Successfully Registered</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Private Limited Company Registration Simplified with T2F</h6>
          <p className='text-center mt-4'>Looking to establish a Private Limited Company with a robust structure and limited liability? T2F makes the registration process seamless, guiding you through every step from documentation to compliance. With extensive experience and expertise, we ensure your company is set up efficiently and in accordance with legal requirements. Contact us today to get started.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Private Limited Company?</h6>

            <p className='mb-4'>A Private Limited Company (PLC) is a business structure that provides limited liability to its shareholders, a separate legal entity from its owners, and the ability to raise capital through private means. Key characteristics include:</p>
            <div>
              <ul><strong>Separate Legal Entity:</strong> PLCs are distinct from their owners and continue to exist independently of changes in ownership.</ul>

              <ul><strong>Limited Liability:</strong> Shareholders are not personally liable for company debts beyond their investment.</ul>

              <ul><strong>Perpetual Succession:</strong> The company’s existence is not affected by changes in ownership or management.</ul>
            </div>

            <p>Private Limited Companies are governed by the Companies Act and are ideal for those seeking a structured business model with limited liability and growth potential.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register a Private Limited Company in India, you need to meet the following criteria:

              <ul>
                <li><strong>Minimum 2 Directors -</strong> A PLC must have at least two directors, with at least one being a resident of India.</li>

                <li><strong>Minimum 2 Shareholders -</strong> At least two shareholders are required, who can also be directors.</li>

                <li><strong>Unique Company Name -</strong> The name should be unique and include “Private Limited” at the end.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of a Private Limited Company</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of a Private Limited Company</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔️</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of a Private Limited Company</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='documents' className='secondLLP pr-96 pl-16 mt-16'>
          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Private Limited Company Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='registration' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth registration process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for your Private Limited Company Registration</h6>
            <p>T2F is your trusted partner for Private Limited Company registration:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you set up your Private Limited Company.</li>

              <li><strong>Name Reservation - </strong> We’ll assist in reserving your desired company name.</li>

              <li ><strong>Comprehensive Documentation -</strong> Help with preparing and filing all necessary documents.</li>

              <li ><strong>DSC and DIN Assistance -</strong> Facilitation of obtaining digital signatures and identification numbers.</li>

              <li ><strong>PAN and TAN Application -</strong> Assistance with applying for tax IDs.</li>

              <li ><strong>Ongoing Support -</strong> Continued support throughout the registration process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivateLimitedNavbar;
