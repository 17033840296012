import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const SSI = ({ height }) => {

  // SSI grid items
  const items = [
    { title: 'What is SSI?', description: 'Small Scale Industry (SSI) refers to a small-sized business that operates at a limited scale in terms of production and resources, playing a crucial role in the economic development of a country.' },
    { title: 'Types of SSI', description: 'SSI can be classified into manufacturing industries, service industries, and ancillary industries. These industries contribute to employment generation and the promotion of regional development.' },
    { title: 'Registration Process', description: 'SSI registration is an important step for small businesses to avail benefits from the government, such as subsidies and incentives. The process includes applying online, submitting necessary documents, and obtaining an SSI certificate.' },
    { title: 'SSI Benefits', description: 'SSI units enjoy a range of benefits, including easier access to loans, tax exemptions, subsidies on power, and financial assistance for marketing and export activities.' },
    { title: 'Government Schemes', description: 'Several government schemes are available to support SSIs, such as the Credit Linked Capital Subsidy Scheme (CLCSS), Micro and Small Enterprises Cluster Development Programme (MSE-CDP), and more.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Employment Generation - SSIs create numerous job opportunities, particularly in rural and semi-urban areas.",
    "Decentralized Growth - SSI encourages the dispersal of industries, which leads to regional development and equitable distribution of income.",
    "Contribution to Export - Many SSI units contribute significantly to the export of goods, earning foreign exchange for the country.",
    "Encourages Entrepreneurship - SSIs foster entrepreneurship by providing opportunities to new entrepreneurs with limited capital.",
    "Government Support - SSIs receive substantial support from the government in terms of subsidies, financial assistance, and infrastructure development.",
  ];

  const disadvantages = [
    "Limited Capital - SSIs often face challenges in accessing sufficient capital, which can hinder their growth and expansion.",
    "Outdated Technology - Due to budget constraints, many SSIs rely on outdated technology, affecting productivity and efficiency.",
    "Limited Market Access - SSIs usually have limited access to larger markets and face challenges in competing with larger businesses.",
    "Skilled Labor Shortage - Lack of access to skilled labor can impede the growth of SSIs, especially in highly technical industries.",
    "High Competition - SSIs face stiff competition from large industries and multinational companies with vast resources.",
  ];

  const documents = [
    {
      title: "Proof of Identity",
      description: "Valid identity proof such as Aadhaar card, PAN card, or voter ID of the business owner."
    },
    {
      title: "Business Address Proof",
      description: "Documents that confirm the business address, such as utility bills or rent agreements."
    },
    {
      title: "Bank Account Details",
      description: "The bank account details of the business, including the account number and IFSC code."
    },
    {
      title: "Ownership or Lease Documents",
      description: "Proof of ownership or lease agreements for the property where the business operates."
    },
    {
      title: "Partnership Deed (if applicable)",
      description: "A partnership deed in case the business is run as a partnership firm."
    }
  ];

  const steps = [
    {
      title: "Submit Online Application",
      description: "Apply for SSI registration through the government’s online portal by filling out the required forms."
    },
    {
      title: "Attach Necessary Documents",
      description: "Submit the necessary documents, including proof of identity, business address, and ownership details."
    },
    {
      title: "Verification by Authorities",
      description: "The authorities will verify the details and documents submitted for the registration process."
    },
    {
      title: "Issuance of SSI Certificate",
      description: "Once the application is verified, an SSI certificate is issued to the business, allowing it to avail various benefits."
    },
    {
      title: "Avail Government Benefits",
      description: "The registered SSI can then avail various benefits, including financial support, subsidies, and tax exemptions."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Small Scale Industry (SSI)
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Small Scale Industries (SSI) are critical to the economic development of countries, promoting regional development and providing employment opportunities in rural areas.
            </p>

            <p>Register your SSI and take advantage of government schemes designed to promote and support small businesses.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Small Scale Industry</h6>
          <p className='text-center mt-4'>Small Scale Industries play a significant role in developing countries by providing employment opportunities and supporting regional economic development. SSI registration helps businesses avail numerous benefits provided by the government.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is SSI?</h6>

            <p className='mb-4'>Small Scale Industry (SSI) refers to businesses that operate with limited capital, manpower, and production capabilities. These industries are vital to the economy, contributing to employment, exports, and regional development.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for SSI</h6>
            <p className='mb-4'>SSI eligibility depends on factors such as the investment in plant and machinery, turnover, and number of employees. Businesses that meet these criteria can register as SSI units and avail government benefits.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of SSI</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of SSI</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for SSI</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for SSI Registration</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default SSI;