import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ReturnFiling = ({ height }) => {

  // Return Filing grid items
  const items = [
    { title: 'What is Return Filing?', description: 'Return Filing is a mandatory process for individuals and businesses to report their income, expenses, and taxes paid to the government.' },
    { title: 'Types of Returns', description: 'There are various types of tax returns based on the nature of the taxpayer, such as income tax returns, GST returns, and TDS returns.' },
    { title: 'Due Dates for Filing', description: 'Return filing has specific due dates depending on the type of taxpayer, generally falling within the financial year end.' },
    { title: 'Penalties for Late Filing', description: 'Failure to file returns on time can lead to penalties, interest charges, and legal action by tax authorities.' },
    { title: 'Filing Process', description: 'Return filing is now largely done online through government portals, requiring proper documentation and careful data entry.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Compliance - Filing returns ensures compliance with legal tax obligations.",
    "Claim Refunds - Taxpayers can claim refunds for excess taxes paid by filing returns.",
    "Loan Eligibility - Return filing is often required for availing loans and financial products.",
    "Record Maintenance - Keeps a formal record of income and taxes paid over the years."
  ];

  const disadvantages = [
    "Time-Consuming - The process can be time-consuming, especially for complex filings.",
    "Fines for Non-Compliance - Late or inaccurate filing can lead to heavy fines and penalties.",
    "Documentation - Gathering necessary documentation can be tedious and require careful management.",
    "Increased Scrutiny - Filing returns may lead to increased scrutiny and potential audits by tax authorities."
  ];

  const documents = [
    {
      title: "Income Proof",
      description: "Salary slips, bank statements, or other proof of income for the financial year are needed."
    },
    {
      title: "Tax Deducted at Source (TDS)",
      description: "TDS certificates showing the amount of tax deducted from your income."
    },
    {
      title: "Investment Proofs",
      description: "Documents showing any investments made for tax-saving purposes like PPF, ELSS, etc."
    },
    {
      title: "Form 16",
      description: "Form 16 is issued by employers to employees for income tax purposes."
    },
    {
      title: "Bank Account Details",
      description: "The bank account number, IFSC code, and branch details where the refund, if any, is to be credited."
    }
  ];

  const steps = [
    {
      title: "Collect Income Details",
      description: "Compile all sources of income including salary, rental income, capital gains, and business profits."
    },
    {
      title: "Calculate Taxable Income",
      description: "Subtract deductions and exemptions from your total income to determine the taxable income."
    },
    {
      title: "Fill the Return Form",
      description: "Log in to the official tax portal and fill the relevant tax return form."
    },
    {
      title: "Submit Return",
      description: "Verify the details and submit the form online. Ensure proper e-verification for the process to complete."
    },
    {
      title: "Keep Acknowledgement",
      description: "Once filed, keep a copy of the acknowledgement for future reference."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Return Filing
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Filing returns is essential for ensuring compliance with tax laws, whether it’s income tax, GST, or other forms of returns.
            </p>

            <p>Ensure timely and accurate filing of returns to avoid penalties and ensure smooth operations.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Return Filing</h6>
          <p className='text-center mt-4'>Filing tax returns is a crucial process to comply with various tax laws applicable to individuals and businesses.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Return Filing?</h6>

            <p className='mb-4'>Return filing is the submission of tax details, including income and tax liabilities, to the tax authorities.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Return Filing</h6>
            <p className='mb-4'>Any individual or business earning above the prescribed threshold is required to file returns.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Return Filing</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Return Filing</h6>
            <div className="flex flex-wrap justify-between mt-10">
              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Return Filing</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Return Filing</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ReturnFiling;