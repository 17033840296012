import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const WindingUpOfCompanyNavbar = ({ height }) => {

  // Winding Up of a Company grid items
  const items = [
    { title: 'Understanding Winding Up', description: 'Learn about the process and legal requirements involved in winding up a company, including voluntary and compulsory winding up.' },
    { title: 'Preparation and Documentation', description: 'Guidance on preparing the necessary documentation and complying with regulatory requirements for winding up a company.' },
    { title: 'Settlement of Liabilities', description: 'Assistance with settling outstanding liabilities and debts before the company can be formally dissolved.' },
    { title: 'Filing Final Returns', description: 'Support in filing final returns and completing all statutory obligations required for the winding up process.' },
    { title: 'Completion and Closure', description: 'Ensure the company’s closure is legally compliant and all administrative procedures are completed.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Comprehensive Process Management - Expert assistance throughout the entire winding up process.",
    "Regulatory Compliance - Ensures all legal and regulatory requirements are met.",
    "Efficient Documentation - Streamlined handling of all necessary paperwork and filings.",
    "Debt Settlement Support - Guidance on settling outstanding liabilities and obligations.",
    "Smooth Closure - Facilitates a smooth and compliant closure of the company.",
  ];

  const disadvantages = [
    "Complex Procedures - The winding up process can be complex and time-consuming.",
    "Potential Costs - Professional services may involve additional costs.",
    "Legal Challenges - Navigating legal requirements and potential disputes can be challenging.",
  ];

  const documents = [
    {
      title: "Board Resolution",
      description: "Resolution passed by the board of directors authorizing the winding up process."
    },
    {
      title: "Creditors’ List",
      description: "List of all creditors and details of outstanding liabilities to be settled."
    },
    {
      title: "Final Accounts",
      description: "Final financial statements including balance sheet and profit & loss account."
    },
    {
      title: "Tax Returns",
      description: "Final tax returns and documents proving tax compliance."
    },
    {
      title: "Dissolution Form",
      description: "Form required for applying for the dissolution of the company."
    }
  ];

  const steps = [
    {
      title: "Board Resolution",
      description: "Pass a board resolution to initiate the winding up process and inform all stakeholders."
    },
    {
      title: "Prepare Financial Statements",
      description: "Prepare final accounts and financial statements for the company."
    },
    {
      title: "Settle Liabilities",
      description: "Clear all outstanding debts and liabilities before proceeding with dissolution."
    },
    {
      title: "File Final Returns",
      description: "Submit final returns and other required documents to the relevant authorities."
    },
    {
      title: "Apply for Dissolution",
      description: "Apply for the company’s dissolution with the appropriate regulatory body."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Winding Up of a Company
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              The winding up of a company is a critical process that involves closing down the company's operations, settling its liabilities, and fulfilling all legal requirements. Our services provide comprehensive support throughout this process to ensure a smooth and compliant closure.
            </p>

            <p>Facilitate the effective winding up of your company with our expert assistance and comprehensive services.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Winding Up of a Company</h6>
          <p className='text-center mt-4'>Our services for winding up a company provide comprehensive support to ensure all legal and regulatory requirements are met. From preparing documentation to filing final returns, we assist in every step of the winding up process.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Winding Up of a Company?</h6>

            <p className='mb-4'>Winding up of a company refers to the process of dissolving a company’s operations, settling its liabilities, and fulfilling all legal requirements to close the company. This can be initiated voluntarily by the company’s shareholders or by order of a court. Our services guide you through this complex process to ensure compliance and proper closure.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Winding Up of a Company</h6>
            <p className='mb-4'>To wind up a company, the following requirements and documents are necessary:

              <ul>
                <li><strong>Board Resolution -</strong> Resolution passed by the board of directors authorizing the winding up process.</li>

                <li><strong>Creditors’ List -</strong> List of all creditors and details of outstanding liabilities to be settled.</li>

                <li><strong>Final Accounts -</strong> Final financial statements including balance sheet and profit & loss account.</li>

                <li><strong>Tax Returns -</strong> Final tax returns and documents proving tax compliance.</li>

                <li><strong>Dissolution Form -</strong> Form required for applying for the dissolution of the company.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our Winding Up Services</h6>
            <div className='llp-card-grid-container mt-4'>
              {items.map((item, index) => (
                <Card
                  style={{ backgroundColor: '#ffffff', borderRadius: '5px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Our Winding Up Services</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Our Winding Up Services</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Winding Up of a Company</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Handle Winding Up of a Company</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default WindingUpOfCompanyNavbar;