import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const PatentProvisional = ({ height }) => {

  // Patent Provisional grid items
  const items = [
    { title: 'What is a Provisional Patent?', description: 'A provisional patent is a temporary application filed with the patent office to secure an early filing date for an invention while giving the applicant time to refine the idea or product.' },
    { title: 'Why File a Provisional Patent?', description: 'Filing a provisional patent provides a "patent pending" status for your invention, offering protection against competing claims during the development process.' },
    { title: 'How Long is it Valid?', description: 'A provisional patent is valid for 12 months from the date of filing. Within this period, a full patent application must be filed to maintain protection.' },
    { title: 'Conversion to Full Patent', description: 'After filing a provisional patent, you must submit a full patent application, known as a non-provisional patent, to gain complete legal protection.' },
    { title: 'Cost-Effective Option', description: 'A provisional patent is a more affordable option to secure initial protection for your invention, making it accessible for startups and individual inventors.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Secures Early Filing Date - A provisional patent establishes a filing date which is crucial in protecting your invention.",
    "Patent Pending Status - Offers legal protection by allowing you to use 'Patent Pending' on your invention during development.",
    "Lower Initial Costs - Filing a provisional patent is less expensive than filing a full patent, providing cost-effective protection.",
    "Time to Refine Invention - Gives inventors time to improve and finalize the product before filing a full patent application.",
    "Market Testing - Allows inventors to test the commercial viability of their invention while maintaining protection."
  ];

  const disadvantages = [
    "Limited Duration - The protection offered by a provisional patent lasts only for 12 months, requiring further action.",
    "No Formal Protection - A provisional patent does not grant full patent rights; it only secures the filing date.",
    "Incomplete Specification - Since the invention may still be in development, the provisional patent may lack a complete specification.",
    "Must Convert to Full Patent - Inventors must file a non-provisional patent application within 12 months to obtain full protection.",
    "Non-Enforceable Rights - A provisional patent cannot be enforced legally in court against infringement."
  ];

  const documents = [
    {
      title: "Description of Invention",
      description: "A detailed description of the invention, outlining its purpose, functionality, and how it differs from existing products or ideas."
    },
    {
      title: "Drawings or Sketches",
      description: "Visual representations or diagrams of the invention to clearly illustrate its components and working mechanisms."
    },
    {
      title: "Inventor Information",
      description: "Details of the inventor, including name, address, and contact information."
    },
    {
      title: "Provisional Application Form",
      description: "A completed provisional patent application form, which includes relevant technical details about the invention."
    },
    {
      title: "Filing Fee",
      description: "A filing fee is required when submitting a provisional patent application, varying based on the country and patent office."
    }
  ];

  const steps = [
    {
      title: "Draft Invention Description",
      description: "Prepare a detailed description of your invention, including its purpose, features, and unique advantages."
    },
    {
      title: "Create Illustrations",
      description: "Develop sketches or technical drawings to visually represent the invention's structure and functionality."
    },
    {
      title: "Complete Application",
      description: "Fill out the provisional patent application form, ensuring all necessary details are provided."
    },
    {
      title: "Submit to Patent Office",
      description: "File the provisional application with the relevant patent office and pay the necessary fees."
    },
    {
      title: "Patent Pending Status",
      description: "Upon submission, your invention will receive 'patent pending' status, offering protection for 12 months."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Patent Provisional
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A provisional patent allows you to secure an early filing date for your invention while providing time to develop or refine it.
            </p>

            <p>Protect your invention from being copied during the initial stages by filing a provisional patent.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Patent Provisional</h6>
          <p className='text-center mt-4'>Filing a provisional patent protects your invention during its development phase by securing an early filing date.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Provisional Patent?</h6>

            <p className='mb-4'>A provisional patent is a cost-effective way to secure a filing date for your invention while offering protection against competing claims.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Provisional Patent</h6>
            <p className='mb-4'>Any new and useful invention, whether it is a product or process, can be protected by filing a provisional patent.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Provisional Patent</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Provisional Patent</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Provisional Patent</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Provisional Patent</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default PatentProvisional;