import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const UdyamNavbar = ({ height }) => {

  // Udyam Registration service grid items
  const items = [
    { title: 'Simplified Process', description: 'Easy online registration process for Udyam Certification.' },
    { title: 'Government Recognition', description: 'Recognized by the Government of India for MSME benefits.' },
    { title: 'Access to Subsidies', description: 'Eligibility for various government subsidies and incentives.' },
    { title: 'No Renewal Required', description: 'Permanent registration with no need for periodic renewals.' },
    { title: 'Support for MSMEs', description: 'Supports small and medium enterprises with growth and compliance.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Government Recognition - Official recognition by the Government of India, which can enhance credibility.",
    "Subsidies and Incentives - Access to various government subsidies and incentives for MSMEs.",
    "Simplified Process - Streamlined online registration process for ease of application.",
    "Permanent Registration - No need for periodic renewals, ensuring ongoing validity.",
  ];

  const disadvantages = [
    "Limited to MSMEs - Only applicable to micro, small, and medium enterprises.",
    "Eligibility Criteria - Must meet specific criteria to qualify as an MSME.",
    "Document Requirements - Requires submission of various documents, which can be cumbersome for some.",
  ];

  const documents = [
    {
      title: "Business PAN Card",
      description: "A valid PAN card of the business is required for registration."
    },
    {
      title: "Proof of Business Address",
      description: "Submit proof of the business address such as a utility bill or lease agreement."
    },
    {
      title: "Business Bank Account Details",
      description: "Bank account details of the business need to be provided."
    },
    {
      title: "Business Registration Proof",
      description: "Documents related to the business registration, such as the Certificate of Incorporation."
    },
    {
      title: "Aadhar Card of Proprietor/Partner",
      description: "A valid Aadhar card of the business proprietor or partner is needed for verification."
    }
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents required for Udyam Registration."
    },
    {
      title: "Apply Online",
      description: "Submit the Udyam Registration application online through the Udyam Registration portal."
    },
    {
      title: "Verification",
      description: "Complete any verification processes required by the authorities."
    },
    {
      title: "Receive Certificate",
      description: "After successful verification, receive your Udyam Registration Certificate."
    },
    {
      title: "Utilize Benefits",
      description: "Access various government subsidies and benefits available to registered MSMEs."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Udyam Registration (Shop & Establishment License)
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Udyam Registration is a government initiative aimed at supporting and recognizing micro, small, and medium enterprises (MSMEs) in India. This registration helps businesses access various benefits and subsidies, making the process of establishing and growing a business more manageable.
            </p>

            <p>Thousands of businesses have benefited from Udyam Registration with our assistance.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Udyam Registration Made Simple</h6>
          <p className='text-center mt-4'>Udyam Registration is a key step for MSMEs to gain recognition and access various government benefits. We provide a streamlined process to help you complete your registration efficiently.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Udyam Registration?</h6>

            <p className='mb-4'>Udyam Registration is a government registration program for micro, small, and medium enterprises (MSMEs) in India. It provides official recognition to businesses, enabling them to access various government schemes, subsidies, and incentives aimed at supporting their growth and development.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Udyam Registration</h6>
            <p className='mb-4'>To apply for Udyam Registration, you need to meet the following requirements:

              <ul>
                <li><strong>Business PAN Card -</strong> A valid PAN card of the business is required.</li>

                <li><strong>Proof of Business Address -</strong> Submit proof of the business address, such as a utility bill or lease agreement.</li>

                <li><strong>Business Bank Account Details -</strong> Provide details of the business bank account.</li>

                <li><strong>Business Registration Proof -</strong> Include documents related to business registration, such as the Certificate of Incorporation.</li>

                <li><strong>Aadhar Card of Proprietor/Partner -</strong> A valid Aadhar card of the business proprietor or partner is needed.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Udyam Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Udyam Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Udyam Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Udyam Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for Udyam Registration</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default UdyamNavbar;