import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';

const EPFRegistrationNavbar = ({ height }) => {

  // EPF grid items
  const items = [
    { title: 'Mandatory for Employers', description: 'EPF registration is mandatory for employers with 20 or more employees.' },
    { title: 'Employee Benefits', description: 'Provides financial security and benefits for employees post-retirement.' },
    { title: 'Tax Benefits', description: 'Contributions to EPF are eligible for tax benefits under Indian tax laws.' },
    { title: 'Interest Accumulation', description: 'EPF contributions earn interest, which is compounded annually.' },
    { title: 'Employee Coverage', description: 'Covers employees across various sectors and industries.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Financial Security - Ensures a secure financial future for employees after retirement.",
    "Tax Benefits - Contributions and interest earned are tax-exempt under certain conditions.",
    "Employee Welfare - Contributes to the overall welfare and financial health of employees.",
    "Easy Compliance - Simple procedures for both registration and ongoing compliance."
  ];

  const disadvantages = [
    "Mandatory Requirement - Employers with fewer than 20 employees are not required to register.",
    "Contribution Costs - Employers must contribute a percentage of salary towards EPF.",
    "Administrative Burden - Requires regular maintenance of records and compliance with regulations."
  ];

  const documents = [
    {
      title: "Proof of Establishment",
      description: "Includes registration documents or proof of business operations."
    },
    {
      title: "PAN Card",
      description: "For the business and for the authorized signatory."
    },
    {
      title: "Address Proof",
      description: "Recent utility bills or lease agreements."
    },
    {
      title: "Employee Details",
      description: "List of employees along with their KYC details."
    },
    {
      title: "Bank Account Details",
      description: "Business bank account details for EPF transactions."
    },
    {
      title: "Digital Signature",
      description: "For the authorized person handling the EPF registration."
    }
  ];

  const steps = [
    {
      title: "Collect Required Documents",
      description: "Gather all necessary documents for EPF registration."
    },
    {
      title: "Register Online",
      description: "Complete the EPF registration form on the EPFO website."
    },
    {
      title: "Submit Documents",
      description: "Upload the required documents and submit them online."
    },
    {
      title: "Verification Process",
      description: "The EPFO will verify the submitted information and documents."
    },
    {
      title: "Receive EPF Registration Number",
      description: "Once approved, you will receive a unique EPF registration number."
    },
    {
      title: "Start EPF Contributions",
      description: "Begin making EPF contributions as per the regulations."
    },
    {
      title: "Maintain Records",
      description: "Keep accurate records of contributions and employee details."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Employee Provident Fund (EPF)
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              The Employee Provident Fund (EPF) is a retirement benefits scheme for employees, managed by the Employees' Provident Fund Organisation (EPFO).
            </p>

            <p>10K+ Companies Successfully Registered for EPF</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>EPF Registration Made Simple with T2F</h6>
          <p className='text-center mt-4'>Looking to register for Employee Provident Fund (EPF)? T2F simplifies the EPF registration process, ensuring compliance with all regulations and smooth handling of all formalities. Contact us today to get started on securing your employees' future.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is EPF?</h6>

            <p className='mb-4'>The Employee Provident Fund (EPF) is a savings scheme managed by the EPFO, where both employers and employees contribute towards a fund that provides retirement benefits. Here’s why EPF is essential:</p>
            <div>
              <ul><strong>Mandatory for Employers:</strong> Required for employers with 20 or more employees.</ul>

              <ul><strong>Employee Benefits:</strong> Provides a financial cushion for employees post-retirement.</ul>

              <ul><strong>Tax Benefits:</strong> Contributions and interest earned are tax-exempt under specific conditions.</ul>
            </div>

            <p>EPF helps in ensuring financial security and stability for employees once they retire from active service.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register for EPF, you need to meet the following criteria:

              <ul>
                <li><strong>Employee Count -</strong> Must have 20 or more employees to be eligible for EPF registration.</li>

                <li><strong>Proof of Establishment -</strong> Valid business registration or establishment proof.</li>

                <li ><strong>Bank Account -</strong> Business bank account for EPF transactions.</li>
                <li ><strong>Employee Details -</strong> Details of all employees for EPF account creation.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of EPF</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of EPF</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of EPF</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='why' className='secondLLP pr-96 pl-16 mt-16'>
          <div className='Choosing'>
            <h6 className='text-blue-500 text-lg font-semibold'>Choosing Your Business Name</h6>
            <ul className='space-x-4'>
              <li><strong>Unique and Available - </strong> Ensure your business name is unique and not already in use.</li>

              <li><strong>Descriptive - </strong> Reflects the nature of your business clearly.</li>

              <li ><strong>Compliance -</strong> Ensure it meets local naming regulations.</li>
            </ul>
          </div>

          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for EPF Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='how' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth EPF registration process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for Your EPF Registration</h6>
            <p>T2F is your reliable partner for EPF registration:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Guidance - </strong> Professional support with the EPF registration process.</li>

              <li><strong>Document Assistance - </strong> Help with gathering and preparing the necessary documents.</li>

              <li ><strong>Compliance Assurance -</strong> Ensure adherence to EPF regulations and guidelines.</li>

              <li ><strong>Efficient Processing -</strong> Fast and smooth handling of your EPF application.</li>

              <li ><strong>Ongoing Support -</strong> Continuous assistance throughout the registration and compliance process.</li>

              <li ><strong>Contribution Management -</strong> Support with managing EPF contributions and records.</li>
            </ul>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default EPFRegistrationNavbar;
