import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ITReturn1 = ({ height }) => {

  // IT-Return 1 grid items
  const items = [
    { title: 'Income Declaration', description: 'Report your income from all sources including salary, business, and investments.' },
    { title: 'Tax Deductions', description: 'Claim deductions under various sections like 80C, 80D to reduce tax liability.' },
    { title: 'Advance Tax Payments', description: 'Ensure timely payment of advance taxes to avoid penalties.' },
    { title: 'Income Proof Documents', description: 'Submit all necessary proof for declared income and deductions.' },
    { title: 'Electronic Filing', description: 'File your IT-Return 1 online easily and securely.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Easy Income Declaration - IT-Return 1 allows easy declaration of income from various sources.",
    "Claim Deductions - Maximize tax savings by claiming deductions under different sections.",
    "Avoid Penalties - Pay advance taxes on time to avoid interest and penalties.",
    "Convenient Online Filing - File your return online from the comfort of your home.",
    "Accuracy - Filing returns ensures accuracy in income reporting and compliance with tax laws."
  ];

  const disadvantages = [
    "Complexity - If income sources are diversified, filing may get complicated.",
    "Penalties - Failure to file on time or accurately can result in penalties.",
    "Documentation - Gathering and organizing necessary documents can be time-consuming.",
    "Online Filing Challenges - Online systems may sometimes face issues, leading to delays.",
    "Tax Laws Updates - Frequent changes in tax laws may require constant updates."
  ];

  const documents = [
    {
      title: "Form 16",
      description: "A certificate from your employer showing your salary details and tax deductions."
    },
    {
      title: "Bank Statements",
      description: "Statements of your bank accounts to verify income from interest and other sources."
    },
    {
      title: "Investment Proof",
      description: "Proof of investments made under various sections for claiming tax deductions."
    },
    {
      title: "TDS Certificates",
      description: "Certificates of tax deducted at source on income from various sources."
    },
    {
      title: "PAN Card",
      description: "Your Permanent Account Number (PAN) card for tax identification."
    }
  ];

  const steps = [
    {
      title: "Collect Documents",
      description: "Gather all necessary documents like Form 16, bank statements, and investment proof."
    },
    {
      title: "Calculate Total Income",
      description: "Calculate your total income from salary, business, investments, and other sources."
    },
    {
      title: "Claim Deductions",
      description: "Maximize your tax savings by claiming deductions under eligible sections."
    },
    {
      title: "Pay Taxes",
      description: "Ensure you have paid any due advance taxes to avoid penalties."
    },
    {
      title: "File IT-Return 1",
      description: "Submit your income tax return online before the deadline."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              IT-Return 1
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Filing your IT-Return 1 is simple and hassle-free with our expert assistance. Ensure timely income declaration, claim tax deductions, and file electronically for accuracy and convenience.
            </p>

            <p>IT-Return 1 allows individuals to declare income from various sources, claim tax deductions, and pay taxes accurately. Our services streamline the filing process, ensuring compliance and tax optimization.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainITReturn w-full flex flex-wrap'>
        <div id="overview" className="ITReturnRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Efficient Filing of IT-Return 1</h6>
          <p className='text-center mt-4'>We offer comprehensive services to assist with filing IT-Return 1, ensuring accurate income declaration, proper deduction claims, and timely filing to avoid penalties and optimize tax savings.</p>
        </div>

        <div className='ITReturn-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is IT-Return 1?</h6>

            <p className='mb-4'>IT-Return 1 is a simple form used by individuals to declare their income from salary, business, investments, and other sources, claim deductions, and pay taxes. Filing it ensures compliance with tax laws and maximizes savings.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To file IT-Return 1, the following documents and eligibility criteria are required:

              <ul>
                <li><strong>Form 16 -</strong> Salary certificate from your employer.</li>

                <li><strong>Bank Statements -</strong> For income from interest and other sources.</li>

                <li><strong>Investment Proof -</strong> Documents for claiming deductions.</li>

                <li><strong>TDS Certificates -</strong> Certificates for tax deducted at source.</li>

                <li><strong>PAN Card -</strong> Required for tax identification.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of IT-Return 1</h6>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="pros-cons" className='pl-16 pr-96 mt-16'>
            <div className='AdvantagesLLP'>
              <h2>Advantages of Filing IT-Return 1</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Filing IT-Return 1</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for IT-Return 1</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to File IT-Return 1</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Filing IT-Return 1?</h6>
            <p>Our team provides expert assistance to simplify IT-Return 1 filing, ensuring compliance, tax savings, and timely submission for a stress-free tax experience.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default ITReturn1;