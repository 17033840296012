import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const OnePersonCompanyNavbar = ({ height }) => {

  // OPC service grid items
  const items = [
    { title: 'Single Shareholder', description: 'Only one person is required to form an OPC, making it ideal for sole entrepreneurs.' },
    { title: 'Limited Liability', description: 'Shareholder has limited liability, protecting personal assets from business debts.' },
    { title: 'Separate Legal Entity', description: 'The company is a separate legal entity from its owner, with its own legal rights and obligations.' },
    { title: 'Perpetual Succession', description: 'The company continues to exist regardless of changes in ownership or management.' },
    { title: 'Simple Compliance', description: 'Less compliance burden compared to a Private Limited Company.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Single Shareholder - Only one person is required to form an OPC.",
    "Limited Liability - Protects personal assets from business liabilities.",
    "Separate Legal Entity - The company is distinct from its owner.",
    "Perpetual Succession - Continues to exist despite changes in ownership or management.",
    "Simplified Compliance - Fewer compliance requirements compared to other company structures."
  ];

  const disadvantages = [
    "Limited Scope - Cannot be converted into a Public Company or carry out non-compliant business activities.",
    "Single Ownership - Can be risky if the sole owner is unavailable or incapacitated.",
    "Restricted Share Transfer - Shares cannot be freely transferred, which can limit flexibility."
  ];

  const documents = [
    {
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills."
    },
    {
      title: "Address Proof of Director",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      title: "Identity Proof of Director",
      description: "Passport, Aadhar card, or driving license."
    },
    {
      title: "Passport-size Photos",
      description: "With a white background."
    },
    {
      title: "PAN Card",
      description: "For the sole director."
    },
    {
      title: "Digital Signature Certificate (DSC)",
      description: "Required for the director."
    }
  ];

  const steps = [
    {
      title: "Obtain a Digital Signature Certificate (DSC)",
      description: "Required for the sole director."
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      description: "For the sole director."
    },
    {
      title: "Choose a Company Name",
      description: "Ensure it meets legal requirements and is not already taken."
    },
    {
      title: "Prepare and File Incorporation Documents",
      description: "Submit necessary documents and forms to the Registrar of Companies (RoC)."
    },
    {
      title: "Draft the Memorandum and Articles of Association",
      description: "Outline the company's objectives and internal rules."
    },
    {
      title: "Receive Certificate of Incorporation",
      description: "Issued by the Registrar of Companies (RoC)."
    },
    {
      title: "Apply for PAN and TAN",
      description: "Essential for tax purposes."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#registration" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#why-choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              One Person Company (OPC)
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A One Person Company (OPC) is a business structure that allows a single individual to establish a company, providing limited liability and separate legal entity status.
            </p>

            <p>10K+ Trusted Companies Successfully Registered</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>One Person Company Registration Made Easy with T2F</h6>
          <p className='text-center mt-4'>Looking to start a business with complete control? A One Person Company (OPC) allows you to be the sole owner while enjoying the benefits of a separate legal entity and limited liability. T2F simplifies the registration process, ensuring a smooth experience from documentation to compliance. Get in touch with us to get started.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a One Person Company (OPC)?</h6>

            <p className='mb-4'>A One Person Company (OPC) is a business structure that allows a single individual to operate a company with limited liability. Key characteristics include:</p>
            <div>
              <ul><strong>Single Shareholder:</strong> An OPC requires only one shareholder, making it ideal for sole proprietors.</ul>

              <ul><strong>Limited Liability:</strong> The shareholder’s liability is limited to their investment in the company.</ul>

              <ul><strong>Separate Legal Entity:</strong> The company is a separate legal entity from its owner, with its own legal rights and obligations.</ul>

              <ul><strong>Perpetual Succession:</strong> The company continues to exist independently of changes in ownership or management.</ul>

              <ul><strong>Simplified Compliance:</strong> OPCs face fewer compliance requirements compared to other company structures.</ul>
            </div>

            <p>OPCs are governed by the Companies Act and are suitable for individuals who want to enjoy the benefits of a company structure while maintaining full control.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register a One Person Company (OPC) in India, you need to fulfill the following criteria:</p>
            <ul>
              <li><strong>Indian Citizen:</strong> The sole shareholder and director must be an Indian citizen and resident in India.</li>

              <li><strong>Single Shareholder:</strong> Only one shareholder is required to form an OPC.</li>

              <li><strong>Minimum Capital Requirement:</strong> There is no minimum capital requirement for OPCs, but authorized capital must be mentioned.</li>

              <li><strong>Director:</strong> The sole director can also be the sole shareholder.</li>

              <li><strong>Unique Company Name:</strong> The name should be unique and include “One Person Company” or “OPC” at the end.</li>
            </ul>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of a One Person Company</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of a One Person Company</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔️</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of a One Person Company</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='documents' className='secondLLP pr-96 pl-16 mt-16'>
          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for One Person Company Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='registration' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth OPC registration process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for your One Person Company Registration</h6>
            <p>T2F is your trusted partner for One Person Company registration:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you set up your OPC.</li>

              <li><strong>Name Reservation - </strong> We’ll assist in reserving your desired company name.</li>

              <li><strong>Comprehensive Documentation -</strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>DSC and DIN Assistance -</strong> Facilitation of obtaining digital signatures and identification numbers.</li>

              <li><strong>PAN and TAN Application -</strong> Assistance with applying for tax IDs.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the registration process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnePersonCompanyNavbar;
