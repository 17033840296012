import React, { useState } from "react";
import { Accordion , Card , Button , Container } from "react-bootstrap";
import './FAQz.css'

function FAQ (){
    const [activeKey , setactiveKey] = useState("0");

    const faqs = [
        {
            id:"0",
            question:"Are there any hidden fees?",
            answer:"ABC company has no hidden things there services are very transparent"
        },
        {
            id:"1",
            question:"Are there any hidden fees?",
            answer:"ABC company has no hidden things there services are very transparent"
        },
        {
            id:"2",
            question:"What services does T2f provide?",
            answer:"ABC company has no hidden things there services are very transparent"
        },
        {
            id:"3",
            question:"How long does it take to complete the company registration ? ",
            answer:"ABC company has no hidden things there services are very transparent"
        },
        {
            id:"4",
            question:"Is the consultation really free?",
            answer:"ABC company has no hidden things there services are very transparent"
        },
    ];

    const handleToggle = (key) => {
        setactiveKey (activeKey=== key ? null :key);
    };

    return (
        <Container className="Faq-container" id="faqq">
            <h4 className="Faq-heading"> Freqently Asked Questions </h4>
            <Accordion activeKey={activeKey} className="mt-5 px-14">
                {faqs.map(faq =>(
                    <Card key = {faq.id}  style={{marginTop:"8px" ,backgroundColor:"#E3EEFB"}}>
                        <Card.Header className="w-auto">
                            <Accordion.Button 
                            as={Button}
                            variant = "link"
                            eventKey ={faq.id}
                            onClick = {()=> handleToggle(faq.id)}
                            >
                                {faq.question}<span className="arrow"></span>
                            </Accordion.Button>
                            </Card.Header>
                            <Accordion.Collapse eventKey={faq.id}>
                                <Card.Body>{faq.answer}</Card.Body>
                            </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </Container>
    );
}
export default FAQ;