import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const NRITaxationServicesNavbar = ({ height }) => {

  // NRI Taxation Services grid items
  const items = [
    { title: 'Income Tax Filing for NRIs', description: 'Expert assistance in filing income tax returns for Non-Resident Indians, ensuring compliance with domestic and international regulations.' },
    { title: 'Tax Planning for NRIs', description: 'Strategic tax planning to optimize tax liabilities and benefit from applicable deductions and exemptions for NRIs.' },
    { title: 'Investment Taxation Guidance', description: 'Guidance on tax implications of investments made in India and abroad, including real estate, stocks, and mutual funds.' },
    { title: 'Double Taxation Avoidance Agreement (DTAA) Consultation', description: 'Consultation on leveraging DTAA provisions to avoid double taxation on income earned in multiple countries.' },
    { title: 'Repatriation of Funds Assistance', description: 'Assistance with the repatriation of funds from India to the NRI’s country of residence, ensuring compliance with regulatory requirements.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Expertise in NRI Regulations - In-depth knowledge of tax regulations affecting NRIs and their financial implications.",
    "Optimized Tax Liabilities - Tailored tax planning to minimize tax liabilities and maximize available benefits.",
    "Comprehensive Investment Guidance - Detailed advice on managing and taxing investments across different jurisdictions.",
    "DTAA Utilization - Effective use of DTAA provisions to avoid double taxation and streamline tax processes.",
    "Efficient Fund Repatriation - Assistance with the smooth repatriation of funds while ensuring regulatory compliance.",
  ];

  const disadvantages = [
    "Complex Regulations - Navigating NRI tax regulations can be complex due to varying rules across jurisdictions.",
    "Potential for Misinterpretation - Incorrect understanding or application of tax laws may lead to compliance issues.",
    "Professional Fees - Engaging specialized tax services may involve additional costs.",
  ];

  const documents = [
    {
      title: "Passport and Visa Details",
      description: "Copy of the passport and visa to confirm non-resident status and residency details."
    },
    {
      title: "Income Proof",
      description: "Documents proving income earned both in India and abroad, including salary slips, bank statements, and investment statements."
    },
    {
      title: "Previous Tax Returns",
      description: "Copies of previous years' tax returns for reference and continuity in tax filing."
    },
    {
      title: "Investment Documents",
      description: "Details of investments held in India and abroad, including property documents, mutual fund statements, and stock portfolios."
    },
    {
      title: "Repatriation Records",
      description: "Documentation related to repatriation of funds, including bank transfer receipts and related correspondence."
    }
  ];

  const steps = [
    {
      title: "Gather Required Documents",
      description: "Collect all necessary documents including income proof, investment details, and previous tax returns."
    },
    {
      title: "Review Tax Obligations",
      description: "Assess your tax obligations in India and your country of residence to identify applicable regulations and benefits."
    },
    {
      title: "Develop Tax Strategy",
      description: "Create a tax strategy to optimize liabilities and ensure compliance with NRI tax laws and DTAA provisions."
    },
    {
      title: "File Tax Returns",
      description: "Prepare and file income tax returns in accordance with Indian tax laws and international regulations."
    },
    {
      title: "Repatriate Funds",
      description: "Assist with the repatriation of funds ensuring compliance with all regulatory requirements."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Comprehensive NRI Taxation Services
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Our NRI taxation services offer expert guidance on income tax filing, tax planning, and investment taxation for Non-Resident Indians. Benefit from our comprehensive solutions to ensure compliance and optimize your tax position.
            </p>

            <p>Navigate the complexities of NRI taxation with ease and efficiency through our specialized services.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Expert NRI Taxation Services</h6>
          <p className='text-center mt-4'>Our NRI taxation services provide specialized assistance to help Non-Resident Indians manage their tax obligations efficiently. From filing income tax returns to strategic tax planning, we ensure compliance and optimize your tax position.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What are NRI Taxation Services?</h6>

            <p className='mb-4'>NRI taxation services encompass a range of solutions designed to address the unique tax needs of Non-Resident Indians. This includes assistance with income tax filing, tax planning, investment taxation, and repatriation of funds. Our services are tailored to ensure compliance with both Indian and international tax regulations.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for NRI Taxation Services</h6>
            <p className='mb-4'>To benefit from our NRI taxation services, you need to provide the following:

              <ul>
                <li><strong>Passport and Visa Details -</strong> Copy of the passport and visa to confirm non-resident status and residency details.</li>

                <li><strong>Income Proof -</strong> Documents proving income earned both in India and abroad, including salary slips, bank statements, and investment statements.</li>

                <li><strong>Previous Tax Returns -</strong> Copies of previous years' tax returns for reference and continuity in tax filing.</li>

                <li><strong>Investment Documents -</strong> Details of investments held in India and abroad, including property documents, mutual fund statements, and stock portfolios.</li>

                <li><strong>Repatriation Records -</strong> Documentation related to repatriation of funds, including bank transfer receipts and related correspondence.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our NRI Taxation Services</h6>
            <div className='llp-card-grid-container mt-4'>
              {items.map((item, index) => (
                <Card
                  style={{ backgroundColor: '#ffffff', borderRadius: '5px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Our NRI Taxation Services</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Our NRI Taxation Services</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for NRI Taxation Services</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Set Up NRI Taxation Services</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default NRITaxationServicesNavbar;