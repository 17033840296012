import React from "react";
import MyCard from "./MyCard";
import Setup from './iconsweb p/Frame 1.webp'
import Gst from './iconsweb p/Frame 2.webp'
import ITR from './iconsweb p/Component 24.webp'
const Sample = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "auto",
        padding: "5px",
        width: "100%",
        margin: "auto",
        flexWrap:'wrap',
        justifyContent:'center',
    

      }}
    >
    <MyCard heading={"Set up a business"} para={"All-in-One Tech Solutions for  Startup Compliance"} src={Setup} alt={"Set up a business"} />
    <MyCard heading={"GST"} para={"GST laws confusing? We simplify returns"} src={Gst} alt={"GST"}/>
    <MyCard heading={"ITR Filing"} para={"Fix missed deadlines: revise and handle notices"} src={ITR} alt={"ITR Filing"}/>
      </div>
  );
};

export default Sample;
