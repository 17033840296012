import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const LUTNavbar = ({ height }) => {

  // LUT Filing service grid items
  const items = [
    { title: 'Tax Exemption', description: 'Allows exporters to export goods and services without paying GST, ensuring a tax-free transaction.' },
    { title: 'Ease of Compliance', description: 'Simplifies compliance with GST regulations for exporters by filing a Letter of Undertaking (LUT).' },
    { title: 'Quick Processing', description: 'Expedited processing and approval of LUT applications, facilitating smooth export operations.' },
    { title: 'Cash Flow Management', description: 'Helps in better cash flow management by avoiding the upfront payment of GST on exports.' },
    { title: 'Government Support', description: 'Provides access to various government schemes and benefits available for exporters.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Tax Exemption - Exporters can export goods and services without the burden of paying GST, making transactions tax-free.",
    "Ease of Compliance - Filing LUT simplifies compliance with GST regulations for exporters, reducing administrative overhead.",
    "Quick Processing - Faster processing and approval of LUT applications help exporters manage their operations smoothly.",
    "Cash Flow Management - Avoids upfront GST payments, improving cash flow management for exporters.",
    "Government Support - Access to various government schemes and incentives available for exporters under the LUT framework.",
  ];

  const disadvantages = [
    "Documentation - Requires meticulous documentation and adherence to GST regulations, which can be complex for some exporters.",
    "Compliance Risk - Incorrect filing or non-compliance can lead to penalties or rejection of LUT applications.",
    "Initial Processing Time - The initial approval process for LUT might take some time, which could impact the export schedule.",
    "Professional Fees - Costs may be incurred if professional assistance is needed for LUT filing and compliance.",
  ];

  const documents = [
    {
      title: "GST Registration Certificate",
      description: "A valid GST registration certificate of the business is required for LUT filing."
    },
    {
      title: "Export Invoices",
      description: "Copies of export invoices for the goods or services to be exported under the LUT."
    },
    {
      title: "Bank Account Details",
      description: "Bank account details of the business for receiving payments and handling export transactions."
    },
    {
      title: "Proof of Export",
      description: "Documents proving the actual export of goods or services, such as shipping bills or export receipts."
    },
    {
      title: "Company PAN Card",
      description: "A valid PAN card of the company or business entity for identification and verification purposes."
    }
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents including GST registration, export invoices, and proof of export."
    },
    {
      title: "Fill LUT Application",
      description: "Complete the LUT application form with accurate details about your export transactions and business."
    },
    {
      title: "Submit Application",
      description: "Submit the LUT application online through the GST portal or to the relevant tax authorities."
    },
    {
      title: "Receive Approval",
      description: "Await approval of the LUT from the tax authorities, which allows for tax-free export transactions."
    },
    {
      title: "Maintain Records",
      description: "Maintain proper records of LUT filings and export transactions for future reference and compliance checks."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              LUT Filing for Exporters
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Filing a Letter of Undertaking (LUT) is essential for exporters to ensure GST exemption on exports. Our streamlined process helps exporters navigate through LUT filing efficiently and ensures compliance with GST regulations.
            </p>

            <p>Thousands of exporters have streamlined their GST compliance with our expert assistance.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>LUT Filing Made Easy</h6>
          <p className='text-center mt-4'>Filing a Letter of Undertaking (LUT) ensures GST exemption on your export transactions. Our process simplifies the filing and compliance, allowing you to focus on your export business.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is LUT Filing?</h6>

            <p className='mb-4'>LUT Filing refers to submitting a Letter of Undertaking to the tax authorities to claim GST exemption on exports. It allows exporters to avoid paying GST upfront on their export transactions, thereby facilitating smoother export operations.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for LUT Filing</h6>
            <p className='mb-4'>To file an LUT, you need to:

              <ul>
                <li><strong>GST Registration Certificate -</strong> A valid GST registration certificate for the business.</li>

                <li><strong>Export Invoices -</strong> Copies of export invoices for the goods or services to be exported under the LUT.</li>

                <li><strong>Bank Account Details -</strong> Bank account details of the business for handling export transactions.</li>

                <li><strong>Proof of Export -</strong> Documentation proving the actual export of goods or services.</li>

                <li><strong>Company PAN Card -</strong> A valid PAN card of the company or business entity.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of LUT Filing</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of LUT Filing</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of LUT Filing</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for LUT Filing</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for LUT Filing</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default LUTNavbar;