import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const CloudAccounting = ({ height }) => {

  // Cloud Accounting grid items
  const items = [
    { title: 'Real-Time Access', description: 'Access your financial data anytime, anywhere with cloud-based solutions.' },
    { title: 'Automated Updates', description: 'Get automatic updates and upgrades to the latest accounting features and security.' },
    { title: 'Cost Efficiency', description: 'Reduce costs associated with hardware and IT maintenance by using cloud-based services.' },
    { title: 'Scalability', description: 'Easily scale your accounting solution to match your business growth and needs.' },
    { title: 'Enhanced Security', description: 'Benefit from advanced security features and backups provided by cloud accounting providers.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Real-Time Access - Provides instant access to your financial data from any location, improving accessibility and flexibility.",
    "Automated Updates - Ensures you always have the latest features and security updates without manual intervention.",
    "Cost Efficiency - Reduces the need for expensive hardware and IT infrastructure, lowering overall costs.",
    "Scalability - Easily adapt the solution to accommodate business growth and changing requirements.",
    "Enhanced Security - Offers robust security features and regular backups to protect your financial information.",
  ];

  const disadvantages = [
    "Internet Dependency - Requires a stable internet connection to access and use cloud accounting services.",
    "Data Security Concerns - Potential risks related to data breaches and unauthorized access if security measures are not adequate.",
    "Subscription Costs - Ongoing subscription fees can add up over time compared to traditional software purchases.",
    "Integration Challenges - May face difficulties integrating with existing systems or software used by the business.",
    "Compliance Issues - Ensuring compliance with local regulations and standards can be complex when using cloud-based solutions.",
  ];

  const documents = [
    {
      title: "Business Registration Details",
      description: "Valid business registration documents to verify the legitimacy of your organization."
    },
    {
      title: "Financial Statements",
      description: "Recent financial statements and records to integrate into the cloud accounting system."
    },
    {
      title: "Tax Identification Number",
      description: "Your business's tax identification number for accurate financial reporting and compliance."
    },
    {
      title: "Bank Account Information",
      description: "Details of your business bank accounts for reconciliation and transaction tracking."
    },
    {
      title: "User Identification",
      description: "Identification details of users who will access the cloud accounting system for user management and security."
    }
  ];

  const steps = [
    {
      title: "Choose a Cloud Accounting Provider",
      description: "Select a cloud accounting service provider that meets your business needs and budget."
    },
    {
      title: "Set Up Your Account",
      description: "Create and configure your cloud accounting account, including user access and initial settings."
    },
    {
      title: "Import Financial Data",
      description: "Upload and integrate your existing financial data into the cloud accounting system."
    },
    {
      title: "Configure Features",
      description: "Customize the cloud accounting system with features and settings that align with your business processes."
    },
    {
      title: "Train Users",
      description: "Provide training to your team on how to use the cloud accounting system effectively."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Cloud Accounting Solutions
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Cloud accounting offers modern businesses the ability to manage their finances efficiently and securely from anywhere. Discover how our cloud accounting solutions can transform your financial management with real-time access and advanced features.
            </p>

            <p>Empower your business with our cutting-edge cloud accounting services designed to streamline your financial operations.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Cloud Accounting Overview</h6>
          <p className='text-center mt-4'>Cloud accounting offers a modern approach to managing your business finances, providing real-time access, automated updates, and cost efficiency. Embrace the future of accounting with our cloud-based solutions designed to enhance your financial management.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Cloud Accounting?</h6>

            <p className='mb-4'>Cloud accounting refers to the use of cloud-based software to manage your financial data and accounting processes. It offers advantages such as real-time data access, automated updates, and scalability, making it a popular choice for modern businesses.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Cloud Accounting Solutions</h6>
            <p className='mb-4'>To benefit from cloud accounting, you need:

              <ul>
                <li><strong>Business Registration Details -</strong> Proof of business registration to validate your organization.</li>

                <li><strong>Financial Statements -</strong> Recent financial records to integrate into the cloud accounting system.</li>

                <li><strong>Tax Identification Number -</strong> Your business tax ID for accurate financial reporting and compliance.</li>

                <li><strong>Bank Account Information -</strong> Details of your business bank accounts for reconciliation and transaction tracking.</li>

                <li><strong>User Identification -</strong> Identification details of users accessing the cloud accounting system.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Cloud Accounting</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Cloud Accounting</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Cloud Accounting</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Cloud Accounting</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Implement Cloud Accounting</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Cloud Accounting?</h6>
            <p>Our cloud accounting solutions offer unparalleled flexibility, cost savings, and enhanced security. Let us help you transition to cloud-based accounting with ease and efficiency.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default CloudAccounting;