import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const AppealAgainstIncomeTaxOrdersNavbar = ({ height }) => {

  // Appeal against Income Tax Orders grid items
  const items = [
    { title: 'Understanding Income Tax Appeals', description: 'Learn about the process of filing an appeal against income tax orders, including reasons for appeal and the procedure involved.' },
    { title: 'Preparing Your Appeal', description: 'Guidance on gathering necessary documentation and preparing a strong appeal case against income tax orders.' },
    { title: 'Filing the Appeal', description: 'Steps involved in filing the appeal with the appropriate authorities and ensuring all requirements are met.' },
    { title: 'Handling Correspondence', description: 'Support in managing communication with tax authorities during the appeal process.' },
    { title: 'Final Resolution', description: 'Assistance in receiving and understanding the final decision on your appeal and taking further actions if needed.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Expert Guidance - Professional help to navigate the complexities of tax appeals.",
    "Comprehensive Support - Assistance with documentation, filing, and communication with authorities.",
    "Increased Chances of Success - Enhanced likelihood of a favorable outcome with a well-prepared appeal.",
    "Legal Compliance - Ensuring all procedural and legal requirements are met during the appeal process.",
    "Peace of Mind - Reducing stress and uncertainty associated with handling tax disputes.",
  ];

  const disadvantages = [
    "Time-Consuming - The appeal process can be lengthy and require significant time investment.",
    "Potential Costs - Professional fees and other costs associated with filing an appeal.",
    "Complexity - Handling appeals can be complex, requiring thorough knowledge of tax laws and procedures.",
  ];

  const documents = [
    {
      title: "Income Tax Order Copy",
      description: "Copy of the income tax order that is being appealed against."
    },
    {
      title: "Appeal Form",
      description: "The prescribed form for filing an appeal with the tax authorities."
    },
    {
      title: "Supporting Documentation",
      description: "Additional documents and evidence supporting the appeal, such as financial statements, correspondence, etc."
    },
    {
      title: "Proof of Payment",
      description: "Proof of any tax payments or adjustments related to the appeal."
    },
    {
      title: "Written Submission",
      description: "Detailed written submission outlining the grounds for appeal and arguments."
    }
  ];

  const steps = [
    {
      title: "Review Income Tax Order",
      description: "Thoroughly review the income tax order to understand the issues and grounds for appeal."
    },
    {
      title: "Prepare Appeal",
      description: "Gather necessary documentation and prepare a detailed appeal, including legal arguments and supporting evidence."
    },
    {
      title: "File Appeal",
      description: "Submit the appeal along with all required documents to the relevant tax authority."
    },
    {
      title: "Respond to Queries",
      description: "Handle any correspondence or queries from tax authorities related to the appeal."
    },
    {
      title: "Receive Decision",
      description: "Review the final decision on the appeal and take necessary actions based on the outcome."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Filing Appeal Against Income Tax Orders
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Filing an appeal against an income tax order is a formal process to challenge decisions made by tax authorities. Our services offer comprehensive support to ensure your appeal is well-prepared, filed correctly, and managed efficiently throughout the process.
            </p>

            <p>Get expert assistance in filing your appeal and navigating the complexities of income tax disputes.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Filing Appeal Against Income Tax Orders</h6>
          <p className='text-center mt-4'>Our services for filing appeals against income tax orders ensure that you receive expert support throughout the process, from understanding the order to preparing and filing the appeal.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Filing an Appeal Against Income Tax Orders?</h6>

            <p className='mb-4'>Filing an appeal against an income tax order involves challenging the decision made by tax authorities regarding your tax assessment. This process is essential when you believe there has been an error or injustice in the tax order. The appeal allows you to present your case and seek a reconsideration of the decision.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Filing an Appeal</h6>
            <p className='mb-4'>To file an appeal against an income tax order, the following requirements and documents are necessary:

              <ul>
                <li><strong>Income Tax Order Copy -</strong> Copy of the income tax order you are challenging.</li>

                <li><strong>Appeal Form -</strong> The prescribed form for filing an appeal with the tax authorities.</li>

                <li><strong>Supporting Documentation -</strong> Additional documents and evidence supporting your appeal, such as financial statements, correspondence, etc.</li>

                <li><strong>Proof of Payment -</strong> Proof of any tax payments or adjustments related to the appeal.</li>

                <li><strong>Written Submission -</strong> Detailed written submission outlining the grounds for appeal and arguments.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our Filing Appeal Services</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  style={{ backgroundColor: '#ffffff', borderRadius: '5px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Our Filing Appeal Services</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Our Filing Appeal Services</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Filing Appeal Against Income Tax Orders</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to File an Appeal Against Income Tax Orders</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default AppealAgainstIncomeTaxOrdersNavbar;