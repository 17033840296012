import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const MicroFinanceCompanyNavbar = ({ height }) => {

  // Micro Finance Company service grid items
  const items = [
    { title: 'Financial Inclusion', description: 'Helps in providing financial services to underserved populations.' },
    { title: 'Regulated Sector', description: 'Operates under strict regulatory guidelines ensuring transparency and fairness.' },
    { title: 'Microloans', description: 'Provides small loans to individuals or businesses with limited access to traditional banking services.' },
    { title: 'Community Development', description: 'Focuses on empowering communities through financial education and support.' },
    { title: 'Social Impact', description: 'Aims at improving the socio-economic conditions of low-income groups.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Financial Inclusion - Expands access to financial services for underserved populations.",
    "Regulated Sector - Adheres to strict regulations ensuring transparency and fairness.",
    "Microloans - Provides access to small loans for individuals and businesses with limited banking services.",
    "Community Development - Focuses on empowering communities through financial support and education.",
    "Social Impact - Aims to improve socio-economic conditions of low-income groups."
  ];

  const disadvantages = [
    "High Risk - Microfinance operations can involve higher risks due to the nature of the clientele.",
    "Regulatory Compliance - Requires strict adherence to regulatory guidelines which can be complex.",
    "Operational Costs - May involve high operational costs, especially in rural areas.",
    "Limited Scalability - May face challenges in scaling operations due to high transaction costs."
  ];

  const documents = [
    {
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills."
    },
    {
      title: "Address Proof of Directors",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      title: "Identity Proof of Directors",
      description: "Passport, Aadhar card, or driving license."
    },
    {
      title: "Passport-size Photos",
      description: "With a white background."
    },
    {
      title: "PAN Card",
      description: "For all directors and the company."
    },
    {
      title: "Digital Signature Certificate (DSC)",
      description: "Required for the directors."
    },
    {
      title: "Business Plan",
      description: "Detailed plan outlining the business model and financial projections."
    }
  ];

  const steps = [
    {
      title: "Obtain a Digital Signature Certificate (DSC)",
      description: "Required for all directors of the company."
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      description: "For each director of the company."
    },
    {
      title: "Prepare a Detailed Business Plan",
      description: "Including financial projections and target market analysis."
    },
    {
      title: "Apply for a Micro Finance Institution (MFI) License",
      description: "Submit an application to the relevant regulatory authority."
    },
    {
      title: "Choose a Company Name",
      description: "Ensure it complies with regulatory requirements and is not already taken."
    },
    {
      title: "Prepare and File Incorporation Documents",
      description: "Submit necessary documents and forms to the Registrar of Companies (RoC)."
    },
    {
      title: "Receive Certificate of Incorporation",
      description: "Issued by the Registrar of Companies (RoC)."
    },
    {
      title: "Apply for PAN and TAN",
      description: "Essential for tax purposes."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#registration" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#why-choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Micro Finance Company (MFC)
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A Micro Finance Company (MFC) provides financial services to underserved populations, focusing on small loans and community development. MFCs play a crucial role in promoting financial inclusion and social impact.
            </p>

            <p>10K+ Trusted Companies Successfully Registered</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Micro Finance Company Registration Simplified with T2F</h6>
          <p className='text-center mt-4'>Starting a Micro Finance Company (MFC) enables you to provide crucial financial services to underserved communities. T2F offers expert guidance through the registration process, from documentation to compliance, ensuring a smooth setup for your MFC. Connect with us to get started.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Micro Finance Company (MFC)?</h6>

            <p className='mb-4'>A Micro Finance Company (MFC) is a financial institution that provides small loans and financial services to individuals and businesses with limited access to traditional banking. Key characteristics include:</p>
            <div>
              <ul><strong>Financial Inclusion:</strong> MFCs focus on serving underserved populations, promoting financial inclusion.</ul>

              <ul><strong>Regulated Sector:</strong> Operates under strict regulatory guidelines ensuring transparency and adherence to financial norms.</ul>

              <ul><strong>Microloans:</strong> Provides small-scale loans to individuals or small businesses that lack access to traditional credit facilities.</ul>

              <ul><strong>Community Development:</strong> Focuses on empowering communities through financial support and education.</ul>

              <ul><strong>Social Impact:</strong> Aims to improve the socio-economic conditions of low-income groups.</ul>
            </div>

            <p>MFCs are regulated by specific financial authorities to ensure they meet the standards of operation and provide beneficial services to their target populations.</p>
          </div>

          <div id='eligibility' className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility Criteria for Micro Finance Company Registration</h6>
            <p>To register a Micro Finance Company (MFC), you need to meet the following criteria:</p>
            <ul>
              <li><strong>Indian Citizen/Resident:</strong> The company must be owned and operated by Indian citizens and residents.</li>

              <li><strong>Minimum Capital Requirement:</strong> Specific minimum capital requirements set by the regulatory authority.</li>

              <li><strong>Business Plan:</strong> A comprehensive business plan outlining the financial model, target market, and operational strategy.</li>

              <li><strong>Office Space:</strong> Proof of a registered office address is required.</li>

              <li><strong>Regulatory Compliance:</strong> Compliance with regulations set forth by the financial regulatory authority.</li>
            </ul>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of a Micro Finance Company</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of a Micro Finance Company</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔️</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of a Micro Finance Company</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='documents' className='secondLLP pr-96 pl-16 mt-16'>
          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Micro Finance Company Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='registration' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth MFC registration process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for your Micro Finance Company Registration</h6>
            <p>T2F is your trusted partner for Micro Finance Company registration:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you set up your MFC.</li>

              <li><strong>License Application - </strong> We’ll assist in applying for the necessary MFI license.</li>

              <li><strong>Comprehensive Documentation -</strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>DSC and DIN Assistance -</strong> Facilitation of obtaining digital signatures and identification numbers.</li>

              <li><strong>PAN and TAN Application -</strong> Assistance with applying for tax IDs.</li>

              <li><strong>PAN and TAN Application -</strong> Assistance with applying for tax IDs.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the registration process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MicroFinanceCompanyNavbar;
