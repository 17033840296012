import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TrademarkRenewal = ({ height }) => {

  // Trademark Renewal grid items
  const items = [
    { title: 'Review Trademark Status', description: 'Check the status of your trademark to ensure it is due for renewal and has not been expired or canceled.' },
    { title: 'Prepare Renewal Documents', description: 'Gather the necessary documents required for the trademark renewal process, including proof of use and previous registration details.' },
    { title: 'File Renewal Application', description: 'Submit your trademark renewal application to the relevant trademark office before the expiry date.' },
    { title: 'Pay Renewal Fees', description: 'Ensure that all required renewal fees are paid to avoid any lapses or complications in the renewal process.' },
    { title: 'Monitor Renewal Status', description: 'Track the status of your renewal application to confirm successful renewal and avoid any issues with trademark protection.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Continued Protection - Renewing your trademark ensures that your brand continues to be legally protected.",
    "Legal Rights - Maintains your exclusive rights to use and enforce the trademark, preventing others from using similar marks.",
    "Brand Integrity - Keeps your brand’s reputation intact by ensuring uninterrupted trademark protection.",
    "Avoid Re-registration - Renewal saves you from the need to reapply for a new trademark, saving time and effort.",
    "Business Continuity - Ensures that your business continues to benefit from trademark protection without any gaps.",
  ];

  const disadvantages = [
    "Cost - Trademark renewal involves payment of fees, which can add up, especially if multiple trademarks are involved.",
    "Administrative Burden - Managing the renewal process and ensuring timely submissions can be administratively burdensome.",
    "Potential for Errors - Mistakes in the renewal application or missed deadlines can lead to complications or loss of trademark rights.",
    "Requires Monitoring - Ongoing monitoring is needed to track renewal deadlines and maintain trademark protection.",
  ];

  const documents = [
    {
      title: "Renewal Application Form",
      description: "The form required for filing a trademark renewal application with the relevant authorities."
    },
    {
      title: "Proof of Use",
      description: "Evidence demonstrating that the trademark has been used in commerce during the renewal period."
    },
    {
      title: "Previous Registration Details",
      description: "Details of the existing trademark registration to facilitate the renewal process."
    },
    {
      title: "Payment Receipts",
      description: "Receipts or proof of payment for the renewal fees associated with the trademark."
    },
    {
      title: "Identification Documents",
      description: "Proof of identity for the applicant or business entity as required for trademark renewal."
    }
  ];

  const steps = [
    {
      title: "Review Trademark Status",
      description: "Check the status of your trademark to determine if it is due for renewal and ensure it is in good standing."
    },
    {
      title: "Prepare Necessary Documents",
      description: "Gather all required documents and information, including proof of use and previous registration details."
    },
    {
      title: "Submit Renewal Application",
      description: "File the trademark renewal application with the appropriate trademark office before the expiration date."
    },
    {
      title: "Pay Required Fees",
      description: "Ensure payment of all renewal fees to complete the renewal process and avoid lapses in protection."
    },
    {
      title: "Confirm Renewal",
      description: "Monitor the status of your renewal application to confirm successful renewal and maintain trademark protection."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Trademark Renewal
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Trademark Renewal is the process of extending the protection of your trademark beyond its initial registration period. Regular renewal ensures that your trademark remains valid and enforceable, safeguarding your brand and intellectual property rights.
            </p>

            <p>Stay ahead of potential issues and maintain your trademark rights by renewing on time with our expert assistance.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Trademark Renewal Services</h6>
          <p className='text-center mt-4'>Our Trademark Renewal services are designed to help you extend the protection of your trademark and ensure that your brand remains legally protected. We provide comprehensive support throughout the renewal process to maintain your trademark rights.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Trademark Renewal?</h6>

            <p className='mb-4'>Trademark Renewal is the process of extending the registration of your trademark beyond its initial period. This is necessary to keep your trademark valid and enforceable, ensuring continued protection for your brand and intellectual property rights.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Trademark Renewal</h6>
            <p className='mb-4'>To be eligible for trademark renewal, your trademark must be in good standing and have been used in commerce during the registration period. Ensure that you meet all requirements and deadlines for renewal to maintain your trademark protection.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of Trademark Renewal</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className="w-full px-16 mt-10 pt-2">
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Trademark Renewal</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Trademark Renewal</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Trademark Renewal Process</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default TrademarkRenewal;