import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const PublicToPrivate = ({ height }) => {

  // Public Limited Company grid items
  const items = [
    { title: 'Raising Capital', description: 'Ability to raise capital by issuing shares to the public through stock exchanges.' },
    { title: 'Limited Liability', description: 'Provides limited liability protection to shareholders, safeguarding personal assets.' },
    { title: 'Market Presence', description: 'Enhanced market presence and credibility due to public listing and regulatory compliance.' },
    { title: 'Share Liquidity', description: 'Shares are more liquid and can be easily traded on stock exchanges.' },
    { title: 'Regulatory Oversight', description: 'Subject to rigorous regulatory oversight, ensuring transparency and accountability.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Raising Capital - A Public Limited Company can raise significant capital by offering shares to the public through stock exchanges.",
    "Limited Liability - Shareholders enjoy limited liability, protecting their personal assets from company debts and liabilities.",
    "Market Presence - Enhanced visibility and credibility due to public listing and adherence to strict regulatory requirements.",
    "Share Liquidity - Shares of a public company are more liquid and can be traded easily on stock exchanges.",
    "Regulatory Oversight - Subject to rigorous regulatory oversight, promoting transparency and good corporate governance.",
  ];

  const disadvantages = [
    "Regulatory Compliance - Requires adherence to extensive regulatory and reporting requirements, which can be costly and time-consuming.",
    "Public Scrutiny - Subject to increased public and media scrutiny, which can impact company reputation and operations.",
    "Cost - Higher costs associated with compliance, reporting, and maintaining public company status.",
    "Management Complexity - Greater complexity in management due to larger scale and regulatory demands.",
  ];

  const documents = [
    {
      title: "Identity Proof",
      description: "Valid government-issued identity proofs of all directors and shareholders, such as Aadhar card or passport."
    },
    {
      title: "Address Proof",
      description: "Proof of the registered office address of the company, such as a utility bill or lease agreement."
    },
    {
      title: "Incorporation Documents",
      description: "Documents required for incorporation, including the Memorandum of Association (MOA) and Articles of Association (AOA)."
    },
    {
      title: "PAN and TAN",
      description: "Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the company."
    },
    {
      title: "Director Identification Number (DIN)",
      description: "DIN for all proposed directors of the company."
    },
    {
      title: "Share Capital Details",
      description: "Details regarding the share capital structure and number of shares to be issued."
    }
  ];

  const steps = [
    {
      title: "Choose a Company Name",
      description: "Select a unique name for your company and ensure it complies with naming regulations."
    },
    {
      title: "Prepare Documents",
      description: "Gather all required documents including identity proofs, address proofs, and incorporation documents."
    },
    {
      title: "Register with ROC",
      description: "Submit the registration application along with required documents to the Registrar of Companies (ROC)."
    },
    {
      title: "Obtain PAN and TAN",
      description: "Apply for the Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the company."
    },
    {
      title: "Share Capital and Listing",
      description: "Finalize share capital details and apply for listing on a stock exchange if required."
    },
    {
      title: "Receive Certificate of Incorporation",
      description: "Upon successful verification, receive the Certificate of Incorporation from the ROC."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Public To Private Limited Company Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Registering a Public Limited Company provides significant advantages such as the ability to raise capital through public offerings and limited liability protection. This structure is suitable for businesses seeking substantial growth and market presence.
            </p>

            <p>We guide you through the registration process to ensure your company complies with all regulatory requirements.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Establish Your Public Limited Company with Confidence</h6>
          <p className='text-center mt-4'>Setting up a Public Limited Company offers numerous benefits including access to public capital markets and enhanced business credibility. We ensure a smooth and compliant registration process for your public company.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Public Limited Company?</h6>

            <p className='mb-4'>A Public Limited Company is a type of business entity that offers its shares to the public through stock exchanges. It provides the benefit of raising substantial capital while limiting shareholder liability. This structure is ideal for larger businesses seeking to expand and attract public investment.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Public Limited Company Registration</h6>
            <p className='mb-4'>To register a Public Limited Company, you must meet the following requirements:

              <ul>
                <li><strong>Identity Proof -</strong> Provide valid government-issued identity proofs of all directors and shareholders, such as Aadhar card or passport.</li>

                <li><strong>Address Proof -</strong> Submit proof of the registered office address, such as a utility bill or lease agreement.</li>

                <li><strong>Incorporation Documents -</strong> Prepare necessary documents including the Memorandum of Association (MOA) and Articles of Association (AOA).</li>

                <li><strong>PAN and TAN -</strong> Obtain the Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the company.</li>

                <li><strong>Director Identification Number (DIN) -</strong> Ensure all proposed directors have a valid DIN.</li>

                <li><strong>Share Capital -</strong> Provide details regarding the share capital structure and the number of shares to be issued.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Public Limited Company</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Public Limited Company</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Public Limited Company</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Public Limited Company Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Register a Public Limited Company</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default PublicToPrivate;