import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const PayrollServicesNavbar = ({ height }) => {

  // Payroll Services grid items
  const items = [
    { title: 'Automated Payroll Processing', description: 'Efficient and accurate payroll processing with automated systems to minimize errors and ensure timely payments.' },
    { title: 'Tax Compliance', description: 'Ensure compliance with all tax regulations and timely submission of tax-related documents.' },
    { title: 'Employee Self-Service Portal', description: 'Provide employees with a user-friendly portal to access pay slips, tax forms, and update personal information.' },
    { title: 'Customizable Payroll Reports', description: 'Generate detailed and customizable payroll reports for better financial planning and analysis.' },
    { title: 'Dedicated Support', description: 'Access to dedicated payroll support to handle queries and resolve issues promptly.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Accuracy and Efficiency - Automated payroll systems reduce errors and ensure timely salary payments.",
    "Compliance Assurance - Adhere to tax regulations and avoid penalties with accurate tax calculations and submissions.",
    "Employee Convenience - Self-service portals allow employees to access their pay slips and tax forms easily.",
    "Detailed Reporting - Customizable reports provide insights for better financial management and decision-making.",
    "Expert Support - Dedicated payroll support helps resolve issues and answer queries quickly.",
  ];

  const disadvantages = [
    "Initial Setup Costs - There may be initial costs associated with setting up automated payroll systems.",
    "System Dependence - Reliance on automated systems may require troubleshooting and regular updates.",
    "Data Security - Ensuring the security of payroll data is critical and requires robust measures to prevent breaches.",
  ];

  const documents = [
    {
      title: "Employee Details",
      description: "Accurate details of all employees including personal information, bank details, and tax information."
    },
    {
      title: "Payroll Policies",
      description: "Documentation of company payroll policies including pay rates, benefits, and deductions."
    },
    {
      title: "Tax Registration Documents",
      description: "Documents related to tax registration and compliance for both the company and employees."
    },
    {
      title: "Bank Account Information",
      description: "Company’s bank account details for processing payroll payments."
    },
    {
      title: "Contractual Agreements",
      description: "Employee contracts and agreements related to compensation and benefits."
    }
  ];

  const steps = [
    {
      title: "Collect Employee Information",
      description: "Gather all necessary employee details and documentation for payroll processing."
    },
    {
      title: "Set Up Payroll System",
      description: "Implement an automated payroll system tailored to your company’s requirements."
    },
    {
      title: "Process Payroll",
      description: "Run payroll calculations and process payments according to the established schedule."
    },
    {
      title: "Ensure Tax Compliance",
      description: "Verify tax calculations and ensure timely submission of tax-related documents."
    },
    {
      title: "Generate Reports",
      description: "Create detailed payroll reports for financial planning and compliance purposes."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Comprehensive Payroll Services
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Our payroll services ensure that your employees are paid accurately and on time while maintaining compliance with tax regulations. We offer automated processing, tax compliance, and dedicated support to streamline your payroll management.
            </p>

            <p>From setting up your payroll system to generating detailed reports, we provide end-to-end solutions to meet your payroll needs.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Streamline Your Payroll Management</h6>
          <p className='text-center mt-4'>Efficient payroll management is essential for any business. Our comprehensive payroll services provide automated processing, tax compliance, and expert support to ensure accurate and timely payroll management.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What are Payroll Services?</h6>

            <p className='mb-4'>Payroll services involve managing employee compensation, including salary payments, tax calculations, and compliance with legal regulations. Our services ensure accurate and timely processing of payroll, along with expert support and customizable reports.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Payroll Services</h6>
            <p className='mb-4'>To utilize our payroll services, you typically need to provide the following:

              <ul>
                <li><strong>Employee Details -</strong> Accurate personal and bank details of all employees.</li>

                <li><strong>Payroll Policies -</strong> Documentation outlining company payroll policies including pay rates and deductions.</li>

                <li><strong>Tax Registration Documents -</strong> Documents related to tax registration and compliance.</li>

                <li><strong>Bank Account Information -</strong> Details of the company’s bank account for payroll payments.</li>

                <li><strong>Contractual Agreements -</strong> Employee contracts and agreements related to compensation.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Our Payroll Services</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Our Payroll Services</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Our Payroll Services</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Payroll Services</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Set Up Payroll Services</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default PayrollServicesNavbar;