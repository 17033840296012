import React, { useEffect, useRef, useState } from "react";
import {Navbar , Nav , NavDropdown , Button} from "react-bootstrap";
import T2Flogo from './T2F png icons/T2F new logo September.svg'
import dialT2f from './T2F png icons/DialT2F.com logo.svg'
import call from './T2F png icons/call.png'
import FormaBussiness from './iconsweb p/form a business.webp'
import registration from './iconsweb p/registration.webp'
import Intellectual from './iconsweb p/intellectual-property.webp'
import BussinessTools from './iconsweb p/business-tool.webp'
import Compliances from './iconsweb p/compliance.webp'
import Taxation from './iconsweb p/taxation.webp'
import Audits from './iconsweb p/audits.webp'
import Parter from './iconsweb p/partner-with-us.webp'
import Business from './iconsweb p/business-service.webp'
import './Navbar.css'
import { useNavigate } from "react-router-dom";

function NavBar({setNavbarHeight}) {
    const [index,setIndex]=useState(1);
    const [showDropDown,setShowDropdown]=useState(true);
    const navigate=useNavigate();
    const navRef=useRef();
    useEffect(()=>{
        console.log("upper navbarHeight : ",navRef?.current?.offsetHeight);
      setNavbarHeight(navRef?.current?.offsetHeight);
    },[]);
    return(

        <Navbar bg="light" expand="lg" fixed="top" ref={navRef}>
            <div className="brand-custom">
                <Navbar.Brand href="/" className="brand-name d-flex align-items-center">
            <img src ={T2Flogo} alt="Logo" className="logo" 
            style={{width: '30px', height: '30px', marginRight: '10px'}}
            />
            <span style={{color: 'rgba(24, 50, 84, 1)', fontFamily:'Montserrat', fontSize:'21px' , fontWeight:'700' , width:'99px' , height:'26px'}}>
                <img src={dialT2f} alt="dialT2f"/>
            </span>
            </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls = "basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto d-flex justify-content-between align-items-center">




<NavDropdown title="Services" id="Services-dropdown" className="services-container">
    
    
    <div className="dropdown-container w-[64vw] h-[90vh] text-sm">
        <div className="dropdown-item-container">
            <NavDropdown.Item  onMouseOver={()=>{setIndex(1)}}>
                <div className="w-auto flex flex-row">
                <img src={FormaBussiness} alt="Business" className="navbar-icons"/>
                Form A Business
                </div>
            </NavDropdown.Item>

            <NavDropdown.Item onMouseOver={()=>{setIndex(2)}}>
            <div className="w-auto flex flex-row">
                <img src={registration} alt="Registration" className="navbar-icons"/>
                Registration
            </div>
            </NavDropdown.Item>

            <NavDropdown.Item onMouseOver={()=>{setIndex(3)}}>
            <div className="w-auto flex flex-row">
                <img src={Intellectual} alt="Property" className="navbar-icons"/>
                Intellectual Property 
            </div>
            </NavDropdown.Item>

            <NavDropdown.Item onMouseOver={()=>{setIndex(4)}}>
            <div className="w-auto flex flex-row">
                <img src={BussinessTools} alt="Tools" className="navbar-icons"/>
                Business Tools/Software
                </div>
            </NavDropdown.Item>

            <NavDropdown.Item onMouseOver={()=>{setIndex(5)}}>
            <div className="w-auto flex flex-row">
                <img src={Compliances} alt="Compliances" className="navbar-icons"/>
                Compliances
            </div>
            </NavDropdown.Item>

            <NavDropdown.Item onMouseOver={()=>{setIndex(6)}}>
            <div className="w-auto flex flex-row">
                <img src={Taxation} alt="Taxation" className="navbar-icons"/>
                Taxation
                </div>
            </NavDropdown.Item>

            <NavDropdown.Item onMouseOver={()=>{setIndex(7)}}>
            <div className="w-auto flex flex-row">
                <img src={Audits} alt="Audits" className="navbar-icons"/>
                Audits
                </div>
            </NavDropdown.Item>

            <NavDropdown.Item onMouseOver={()=>{setIndex(8)}}>
            <div className="w-auto flex flex-row">
                <img src={Business} alt="" className="navbar-icons"/> 
                Business Services
                </div>
            </NavDropdown.Item>

            <NavDropdown.Item onMouseOver={()=>{setIndex(9)}}>
            <div className="w-auto flex flex-row">
                <img src={Parter} alt="Partner with us" className="navbar-icons"/>
                Partner with us
                </div>
            </NavDropdown.Item>
        </div>
        
        {
        index===1 &&    
            <>
        <div className="sub-dropdown  flex-col flex-wrap">
            <h5>Company Registeration</h5>
            <NavDropdown.Item onClick={()=>navigate("/LLP")}>Limited Liability Partnership(LLP)</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>navigate("/private")}>Private Limited Company</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>navigate("/PartnershipFirmNavbar")} >Partnership</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>navigate("/ProprietorshipNavbar")}>Proprietorship Firm</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>navigate("/public")} >Public Limited Company</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>navigate("/one")}>OPC</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>navigate("/indian")}>Indian Subsidiary(for foreign owners)</NavDropdown.Item>
            <hr/>
            <h5>Company Coversion</h5>
            <NavDropdown.Item onClick={()=>navigate("/partnett")}>Partnership to Limited Liability Partnership(LLP)</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>navigate("/publictoprivate")}>Private Limited Company to Public Limited</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>navigate("/ProprietorshipToPrivateLimitedNavbar")}>Proprietorship Firm to Private Limited Company</NavDropdown.Item>
            <hr/>
            <h5>Special Entities</h5>
            <NavDropdown.Item onClick={()=>{
                navigate("/nidhi")
            }}>Nidhi Company </NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/Section8Navbar")
            }}>Section 8 Company </NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/Trust")
            }}>Trust</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/society")
            }}>Society</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/MicroFinanceCompanyNavbar")
            }}>Micro Finance Company</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/nbfc")
            }}>NBFC</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/prod")
            }}>Producer Company </NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/insurance")
            }}>Insurance Company </NavDropdown.Item>
        </div>
        </>
        }
        {
        index===2 &&    
            <>
        <div className="sub-dropdown">
            <NavDropdown.Item onClick={()=>{
                navigate("/msme")
            }}>MSME</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/ssi")
            }}>SSI</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/fcra")
            }}>FCRA</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/niti")
            }}>Niti Aayog</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/insure")
            }}>Insurance Broker License</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/fssai")
            }}>FSSAI License</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/drug")
            }}>Drug License</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/eat")
            }}>Eating House License</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/iec")
            }}>Import Export Code (IEC)</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/iso")
            }}>ISO</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/trade")
            }}>Trade License</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/dsc")
            }}>Digital Signature (DSC)</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/esi")
            }}>ESI Registration</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/epf")
            }}>Employee Provident Fund (EPF)</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/Tan")
            }}>TAN Registration</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/Ptr")
            }}>Professional Tax Registration</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/udyam")
            }}>Udyam</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/shop")
            }}>Shop & Establishment License</NavDropdown.Item>
        </div>
        </>
        }
        {
        index===3 &&    
            <>
        <div className="sub-dropdown">
            <h5>Trademark</h5>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/TrademarkSearch")
                }
            }>Search</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/trade")
                }
            }>Registration</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/TrademarkAssign")
                }
            }>Assignment</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/TrademarkRen")
                }
            }>Renewal</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/TrademarkObj")
                }
            }>Objection</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/TrademarkOpp")
                }
            }>Opposition</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/TradeReg")
                }
            }>International Trademark Registration</NavDropdown.Item>
            <hr/>
            <h5>Patent</h5>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/patentSearch")
                }
            }>Search</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/patentProv")
                }
            }>Provisional</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/patent")
                }
            }>Registration</NavDropdown.Item>
            <hr/>

            <h5>Copyright</h5>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/copyReg")
                }
            }>Registration</NavDropdown.Item>
            <NavDropdown.Item onClick={
               ()=> navigate("/designReg")
            }>Design Registration</NavDropdown.Item>
            <hr />

        </div>

        </>
        }
        {
        index===4 &&    
            <>
        <div className="sub-dropdown">
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/cloud")
                }
            }>Cloud Accounting</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/eway")
                }
            }>E-way Bills</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/payroll")
                }
            }>Payroll Services</NavDropdown.Item>
            <NavDropdown.Item onClick={
                ()=>{
                    navigate("/invoicing")
                }
            }>Invoicing</NavDropdown.Item>
            </div> 
        </>
        }
        {
        index===5 &&    
            <>
        <div className="sub-dropdown">
            <h5>ROC- Updation of Records</h5>
            <NavDropdown.Item onClick={()=>{
                navigate("/CompShares")
            }}>Allotment of Shares (Share Transfer)</NavDropdown.Item>

            <NavDropdown.Item onClick={()=>{
                navigate("/compAdd")
            }}>Change Registered Office</NavDropdown.Item>


            <NavDropdown.Item onClick={()=>{
                navigate("/changeDir")
            }}>Change in Directors (Addition/ Removal)</NavDropdown.Item>

            <NavDropdown.Item onClick={()=>{
                navigate("/changeAuth")
            }}>Change Authorized Capital of Company</NavDropdown.Item>

            <NavDropdown.Item onClick={()=>{
                navigate("/moa")
            }}>MOA Amendment</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/prepInMins")
            }}>Preparation of Minutes</NavDropdown.Item>
            
            <hr/>
            
            <NavDropdown.Item className="cursor-pointer" style={{"fontWeight": "bold","color": "#125B9A","background":"transparent"}} onClick={()=>navigate("/sebi")}>SEBI</NavDropdown.Item>
            <hr />
            <NavDropdown.Item className="cursor-pointer" style={{"fontWeight": "bold","color": "#125B9A","background":"transparent"}} onClick={()=>navigate("/rbi")}>RBI</NavDropdown.Item>
        </div>
        </>
        }
        {
        index===6 &&    
        <>
        <div className="sub-dropdown">
            <h5>GST</h5>
            <NavDropdown.Item onClick={()=>{
                navigate("/gst")
            }}>Registration</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/gstTemp")
            }}>Temporary Registration</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/invoicing")
            }}>Invoicing</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/einvoicing")
            }}>E-Invoicing</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/AnnualReturns")
            }}>Annual Returns</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/refund")
            }}>Refunds</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/regCancel")
            }}>Registration Cancellation/Revocation</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/ResponsetoNotice")
            }}>Response to Notice/Appeals</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/lut")
            }}>LUT Filing</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/foreign")
            }}> Registration For Foreigners</NavDropdown.Item>
            <hr/>
            <h5>TAX Saving Services</h5>
            <NavDropdown.Item onClick={()=>{
                navigate("/fdbond")
            }}>Fd's/Bond</NavDropdown.Item>
            <hr/>

            <h5>Tax Planning</h5>
            <NavDropdown.Item  onClick={()=>{
                navigate("/taxplanInd")
            }}>Individuals</NavDropdown.Item>
            <NavDropdown.Item  onClick={()=>{
                navigate("/taxplanning")
            }}>Businesses</NavDropdown.Item>
            <NavDropdown.Item  onClick={()=>{
                navigate("/Nri")
            }}>NRI</NavDropdown.Item>
            <NavDropdown.Item>Others</NavDropdown.Item>
        </div>

        <div className="sub-dropdown">
            <h5>ITR</h5>
            <h6>Return Filing</h6>
            <NavDropdown.Item onClick={()=>{
                navigate("/Itr1")
            }}>IT-Return 1 </NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/Itr2")
            }}>IT-Return 2</NavDropdown.Item>
            <h6>Business Income Return</h6>
            <NavDropdown.Item onClick={()=>{
                navigate("/Itr3")
            }}>IT-Return 3 </NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/Itr4")
            }}>IT-Return 4</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/Itr5")
            }}>IT-Return 5</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/Itr6")
            }}>IT-Return 6</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/Itr7")
            }}>IT-Return 7</NavDropdown.Item>

            <NavDropdown.Item onClick={()=>{
                navigate("/refund")
            }}>Refunds</NavDropdown.Item>


            <NavDropdown.Item onClick={()=>{
                navigate("/ResponsetoNotice")
            }}>Respond to Tax Notice/Appleas</NavDropdown.Item>
            <hr/>
            <h5>TDS</h5>
            <NavDropdown.Item onClick={()=>{
                navigate("/returnFile")
            }}>Return Filing </NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/ResponsetoNotice")
            }}>Respond to Tax Notice/Appleas</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/form16")
            }}>Generate Form 16 </NavDropdown.Item>
        </div>
        </>
        }
        {
        index===7 &&    
            <>
        <div className="sub-dropdown">
            <NavDropdown.Item onClick={()=>{
                navigate("/insurance")
            }}>Insurance</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/iso")
            }}>ISO</NavDropdown.Item>
        </div>
        </>
        }
        {
        index===8 &&    
            <>
        <div className="sub-dropdown">
            <h5 className="w-10">Winding Up</h5>
            <NavDropdown.Item onClick={()=>{
                navigate("/WindingLLp")
            }}>LLP</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/winding")
            }}>Company</NavDropdown.Item>

            <hr/>
            <NavDropdown.Item className="cursor-pointer" onClick={()=>navigate("/compSale")} style={{"fontWeight": "bold","color": "#125B9A","background":"transparent"}}>Company Sale/ Purchase</NavDropdown.Item>
            <hr />
            <NavDropdown.Item className="cursor-pointer" onClick={()=>navigate("/sellyr")} style={{"fontWeight": "bold","color": "#125B9A","background":"transparent"}}>Sell Your Company to Us</NavDropdown.Item>
            <hr />
            <NavDropdown.Item className="cursor-pointer" onClick={()=>navigate("/cibil")} style={{"fontWeight": "bold","color": "#125B9A","background":"transparent"}}>Cibil Complaints</NavDropdown.Item>
            <hr />
            
        </div>
        </>
        }
        {
        index===9 &&    
            <>
           <div className="sub-dropdown invisible">
            <h5 className="w-10">Winding Up</h5>
            <NavDropdown.Item onClick={()=>{
                navigate("/WindingLLp")
            }}>LLP</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>{
                navigate("/winding")
            }}>Company</NavDropdown.Item>
           
            <hr/>
            <NavDropdown.Item className="cursor-pointer" onclick={()=>navigate("/compSale")}>Company Sale/ Purchase</NavDropdown.Item>
            <hr />
            <NavDropdown.Item className="cursor-pointer" onclick={()=>navigate("/sellyr")}>Sell Your Company to Us</NavDropdown.Item>
            <hr />
            <NavDropdown.Item className="cursor-pointer" onclick={()=>navigate("/cibil")}>Cibil Complaints</NavDropdown.Item>
            <hr />
            
        </div>
        </>
        }


    </div>
</NavDropdown>


                    {/* <NavDropdown title= "Newsroom" id= "Newsroom-dropdown">
                        <NavDropdown.Item >Newsroom</NavDropdown.Item>
                        {/* <NavDropdown.Item href="newsroom2">Newsroom2</NavDropdown.Item>
                        <NavDropdown.Item href="newsroom3">Newsroom3</NavDropdown.Item>
                        <NavDropdown.Item href="newsroom4">Newsroom4</NavDropdown.Item>
                        <NavDropdown.Item href="newsroom5">Newsroom5</NavDropdown.Item>
                        <NavDropdown.Item href="newsroom6">Newsroom6</NavDropdown.Item> */
                    }
                    <Nav className="cursor-pointer" onClick={()=>navigate("/")}>Newsroom</Nav>
                    <Nav className="cursor-pointer" onClick={()=>navigate("/we-believe")}>We believe</Nav>
                </Nav>
                <Button variant="outline-primary" href="talk to expert" className="btnexpert ">
                <span className="font-semibold"><img className="w-3 h-3 flex flex-row flex-wrap" src={call} alt=""/> 9910960606</span>
                </Button>
            </Navbar.Collapse>
        </Navbar>
    );
};
export default NavBar;