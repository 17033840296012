import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const SocietyRegistration = ({ height }) => {

  // Society Registration grid items
  const items = [
    { title: 'Legal Framework', description: 'Understand the legal framework for establishing a society.' },
    { title: 'Draft Memorandum', description: 'Prepare a memorandum of association outlining the society’s objectives and governance.' },
    { title: 'Member Selection', description: 'Select and recruit members to form the society.' },
    { title: 'Registration Process', description: 'Follow the process for registering the society with relevant authorities.' },
    { title: 'Compliance Requirements', description: 'Ensure compliance with ongoing regulatory and reporting obligations.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Non-Profit Purpose - Ideal for organizations focused on social, educational, or charitable purposes.",
    "Tax Exemptions - Societies often qualify for tax exemptions on donations and income.",
    "Legal Entity - Provides a formal legal structure for managing activities and assets.",
    "Credibility - Enhances the credibility and legitimacy of the organization.",
    "Funding Opportunities - Access to various funding and grant opportunities available for societies.",
  ];

  const disadvantages = [
    "Complexity - Setting up and managing a society can involve complex legal requirements.",
    "Governance - Requires adherence to strict governance and operational rules.",
    "Regulatory Compliance - Must comply with specific regulations and reporting requirements.",
    "Limited Flexibility - Changes in objectives or structure can be difficult to implement.",
  ];

  const documents = [
    {
      title: "Memorandum of Association",
      description: "A document outlining the society's objectives, rules, and governance."
    },
    {
      title: "List of Members",
      description: "Details of the members, including their names and addresses."
    },
    {
      title: "Proof of Address",
      description: "Proof of address for the society's registered office and members."
    },
    {
      title: "Identity Proof",
      description: "Identity proof of the founding members and office bearers."
    },
    {
      title: "KYC Documents",
      description: "Know Your Customer (KYC) documents for verification purposes."
    }
  ];

  const steps = [
    {
      title: "Draft Memorandum",
      description: "Prepare a detailed memorandum outlining the society’s objectives and rules."
    },
    {
      title: "Select Members",
      description: "Recruit and finalize the list of founding members for the society."
    },
    {
      title: "File Registration Documents",
      description: "Submit the memorandum and other required documents with the relevant authorities."
    },
    {
      title: "Obtain Registration Certificate",
      description: "Receive the official certificate of registration from the authorities."
    },
    {
      title: "Compliance and Reporting",
      description: "Ensure compliance with legal requirements and submit periodic reports as required."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Society Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Society Registration involves creating a formal non-profit entity dedicated to social, educational, or charitable purposes. Our service assists in drafting the necessary documents, recruiting members, and ensuring compliance with legal requirements.
            </p>

            <p>Get professional assistance in setting up a society that aligns with your goals.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Society Registration</h6>
          <p className='text-center mt-4'>Society Registration involves the formation of a legal entity for non-profit activities. It is ideal for organizations with a social, educational, or charitable purpose, providing a formal structure for managing and conducting activities.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Society Registration?</h6>

            <p className='mb-4'>Society Registration is the process of establishing a formal non-profit organization that operates for social, educational, or charitable purposes. This involves drafting a memorandum of association, selecting members, and complying with legal requirements.</p>
          </div>

          <div id="application" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Society Registration</h6>
            <p className='mb-4'>To register a society, follow these steps:

              <ul>
                <li><strong>Draft Memorandum -</strong> Prepare a detailed memorandum outlining the society's objectives and governance structure.</li>

                <li><strong>Select Members -</strong> Recruit and finalize the list of founding members for the society.</li>

                <li><strong>Submit Registration Documents -</strong> File the memorandum and other required documents with the relevant authorities.</li>

                <li><strong>Obtain Registration Certificate -</strong> Receive the official certificate of registration from the authorities.</li>

                <li><strong>Compliance and Reporting -</strong> Ensure compliance with legal requirements and submit periodic reports.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our Society Registration Services</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Advantages of Our Society Registration Services</h6>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Disadvantages of Our Society Registration Services</h6>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='why-choose mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Required for Society Registration</h6>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="process" className='how-to-apply mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Steps to Complete Society Registration</h6>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default SocietyRegistration;