import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const InsuranceCompanyRegistration = ({ height }) => {

  // Insurance Company grid items
  const items = [
    { title: 'Comprehensive Coverage', description: 'Insurance companies provide diverse coverage options to protect your assets.' },
    { title: 'Regulatory Compliance', description: 'We ensure that your insurance company adheres to all regulatory norms.' },
    { title: 'Financial Stability', description: 'Gain access to financial security and long-term stability.' },
    { title: 'Risk Management', description: 'Manage and mitigate risk effectively with our expert guidance.' },
    { title: 'Hassle-Free Registration', description: 'Quick and simple registration process for your insurance company.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Financial Security - Protect your clients from unforeseen risks and financial loss.",
    "Government Schemes - Insurance companies can collaborate with government programs and policies.",
    "Wider Market Access - Provide coverage to a diverse range of industries and individuals.",
    "Long-Term Profitability - Establish a steady source of income through policyholder premiums.",
    "Limited Liability - The liability of shareholders is limited to their investment in the company.",
  ];

  const disadvantages = [
    "Complex Regulatory Requirements - Compliance with multiple laws and regulations is necessary.",
    "Risk of Claims - High claims can lead to financial strain on the company.",
    "Capital Requirements - Significant initial investment is required to start an insurance company.",
    "Market Competition - Insurance is a highly competitive industry with many players.",
    "Documentation - Involves extensive documentation for registration and operation.",
  ];

  const documents = [
    {
      title: "Incorporation Certificate",
      description: "Certificate of Incorporation of the company is required for registration."
    },
    {
      title: "Memorandum & Articles of Association",
      description: "MOA and AOA outlining the business objectives of the insurance company."
    },
    {
      title: "Financial Statements",
      description: "Audited financial statements showing the company’s financial position."
    },
    {
      title: "Directors' Information",
      description: "Details and identification proof of all the directors."
    },
    {
      title: "Registered Office Proof",
      description: "Proof of the registered office of the insurance company."
    }
  ];

  const steps = [
    {
      title: "Obtain Digital Signatures",
      description: "Obtain digital signatures for all the directors for online registration."
    },
    {
      title: "Apply for DIN",
      description: "Apply for Director Identification Number (DIN) for all the directors."
    },
    {
      title: "Submit MOA & AOA",
      description: "Submit the company’s Memorandum and Articles of Association."
    },
    {
      title: "File Application",
      description: "File an application with the Insurance Regulatory and Development Authority of India (IRDAI)."
    },
    {
      title: "Receive Registration Certificate",
      description: "Upon approval, receive the Certificate of Registration for your insurance company."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Insurance Company Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Registering an insurance company provides a legal foundation to offer a range of insurance products, ensuring financial stability for clients.
            </p>

            <p>Get your Insurance Company registered to provide protection and security to clients while ensuring regulatory compliance.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Insurance Company</h6>
          <p className='text-center mt-4'>An Insurance Company helps individuals and businesses mitigate financial risk through various insurance products such as life, health, and general insurance.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is an Insurance Company?</h6>

            <p className='mb-4'>An Insurance Company is a business that provides insurance products to clients, helping them manage financial risk by offering coverage for life, health, property, and other areas.</p>
          </div>

          <div id="application" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Insurance Company Registration</h6>
            <p className='mb-4'>To register your Insurance Company, follow these steps:

              <ul>
                <li><strong>Obtain Digital Signatures -</strong> Digital signatures for all directors are mandatory for online registration.</li>

                <li><strong>Apply for DIN -</strong> Director Identification Number (DIN) is needed for all directors.</li>

                <li><strong>Submit MOA & AOA -</strong> Submit the company’s Memorandum and Articles of Association.</li>

                <li><strong>File with IRDAI -</strong> File an application with the Insurance Regulatory and Development Authority of India (IRDAI).</li>

                <li><strong>Receive Registration Certificate -</strong> Upon approval, your Insurance Company will receive the Certificate of Registration.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Insurance Company</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className='ProConLLP'>
            <div className='AdvantagesLLP'>
              <h6 className='text-blue-500 text-lg font-semibold text-center'>Advantages of Insurance Company</h6>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>{advantage}</li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP'>
              <h6 className='text-blue-500 text-lg font-semibold text-center'>Disadvantages of Insurance Company</h6>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>{disadvantage}</li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Documents Required for Insurance Company Registration</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {documents.map((doc, index) => (
                <Card
                  key={index}
                  title={doc.title}
                  description={doc.description}
                />
              ))}
            </div>
          </div>

          <div id="process" className='steps w-full flex flex-wrap' >
            <div className='stepsToRegister'>
              <h6 className='text-blue-500 text-lg font-semibold text-center'>Steps to Complete Insurance Company Registration</h6>
              <ul>
                {steps.map((step, index) => (
                  <li key={index}>
                    <strong>{step.title} -</strong> {step.description}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Insurance Company Registration?</h6>
            <p>We provide comprehensive support for Insurance Company registration, ensuring that your business complies with all regulatory requirements while protecting your clients from financial risks.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default InsuranceCompanyRegistration;