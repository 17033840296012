import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const CompanyShareTransferNavbar = ({ height }) => {

  // Company Share Transfer grid items
  const items = [
    { title: 'Understanding Share Transfer', description: 'Learn about the process and regulations for transferring shares in a company, including types of share transfers and legal requirements.' },
    { title: 'Preparing for Transfer', description: 'Guidance on gathering necessary documentation and preparing for a smooth share transfer process.' },
    { title: 'Executing the Transfer', description: 'Steps involved in executing the share transfer, including legal formalities and documentation.' },
    { title: 'Updating Company Records', description: 'Support in updating company records and ensuring compliance with legal and regulatory requirements post-transfer.' },
    { title: 'Handling Disputes', description: 'Assistance in resolving any disputes or issues that may arise during the share transfer process.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Expert Guidance - Professional assistance to navigate the complexities of share transfers.",
    "Comprehensive Support - Help with documentation, execution, and compliance.",
    "Legal Compliance - Ensuring all legal requirements are met for a smooth transfer.",
    "Efficient Process - Streamlined process to minimize delays and errors.",
    "Peace of Mind - Reducing stress and uncertainty during the share transfer.",
  ];

  const disadvantages = [
    "Time-Consuming - The share transfer process can be lengthy and require detailed documentation.",
    "Potential Costs - Professional fees and other costs associated with the transfer.",
    "Complexity - Handling share transfers can be complex, especially with legal and regulatory requirements.",
  ];

  const documents = [
    {
      title: "Share Transfer Agreement",
      description: "The agreement outlining the terms and conditions of the share transfer."
    },
    {
      title: "Share Certificate",
      description: "The certificate representing the shares being transferred."
    },
    {
      title: "Board Resolution",
      description: "Resolution passed by the board of directors approving the share transfer."
    },
    {
      title: "Proof of Identity",
      description: "Proof of identity for both the transferor and transferee."
    },
    {
      title: "Form of Transfer",
      description: "The prescribed form for transferring shares, typically signed by both parties."
    }
  ];

  const steps = [
    {
      title: "Review Transfer Agreement",
      description: "Examine the share transfer agreement to ensure all terms and conditions are clear and agreed upon."
    },
    {
      title: "Prepare Documentation",
      description: "Gather all necessary documents, including share certificates, board resolutions, and proof of identity."
    },
    {
      title: "Execute Transfer",
      description: "Complete the share transfer process by signing required documents and updating company records."
    },
    {
      title: "Update Records",
      description: "Ensure company records are updated to reflect the new shareholder information."
    },
    {
      title: "Handle Disputes",
      description: "Address any disputes or issues that arise during or after the share transfer process."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Company Share Transfer
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Transferring shares in a company involves a legal process to change the ownership of shares from one person to another. Our services offer expert assistance to ensure the transfer is executed smoothly and in compliance with all legal requirements.
            </p>

            <p>Get expert help with your company share transfer to ensure a hassle-free process and compliance with regulations.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Company Share Transfer</h6>
          <p className='text-center mt-4'>Our services for company share transfer provide comprehensive support to ensure the process is handled efficiently and in compliance with all legal requirements.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Company Share Transfer?</h6>

            <p className='mb-4'>Company share transfer refers to the process of transferring ownership of shares from one person or entity to another within a company. This involves legal formalities and documentation to ensure the transfer is valid and compliant with company laws and regulations.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Share Transfer</h6>
            <p className='mb-4'>To transfer company shares, the following requirements and documents are necessary:

              <ul>
                <li><strong>Share Transfer Agreement -</strong> The agreement outlining the terms and conditions of the share transfer.</li>

                <li><strong>Share Certificate -</strong> The certificate representing the shares being transferred.</li>

                <li><strong>Board Resolution -</strong> Resolution passed by the board of directors approving the share transfer.</li>

                <li><strong>Proof of Identity -</strong> Proof of identity for both the transferor and transferee.</li>

                <li><strong>Form of Transfer -</strong> The prescribed form for transferring shares, typically signed by both parties.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our Share Transfer Services</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  style={{ backgroundColor: '#ffffff', borderRadius: '5px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Our Share Transfer Services</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Our Share Transfer Services</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Share Transfer</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Transfer Company Shares</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default CompanyShareTransferNavbar;