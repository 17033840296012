import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ShopEstablishmentLicense = ({ height }) => {

  // Shop & Establishment License grid items
  const items = [
    { title: 'Legal Compliance', description: 'Ensures that your business complies with local labor and business laws.' },
    { title: 'Operational Legitimacy', description: 'Provides legal legitimacy to operate a shop or establishment in the area.' },
    { title: 'Employee Benefits', description: 'Ensures employees receive statutory benefits and rights under the law.' },
    { title: 'Avoid Legal Issues', description: 'Helps avoid legal complications and penalties associated with operating without a license.' },
    { title: 'Streamlined Operations', description: 'Facilitates smoother business operations by complying with regulatory requirements.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Compliance - Ensures your business adheres to local labor laws and regulations, avoiding legal issues.",
    "Operational Legitimacy - Grants legal permission to operate a shop or establishment, contributing to business credibility.",
    "Employee Benefits - Guarantees that employees receive their entitled benefits and protections under the law.",
    "Avoid Legal Issues - Helps prevent legal complications and fines by maintaining proper licensing.",
    "Streamlined Operations - Promotes smoother operations by meeting regulatory requirements and avoiding disruptions.",
  ];

  const disadvantages = [
    "Regulatory Requirements - Compliance with local regulations can be complex and time-consuming.",
    "Cost of Licensing - Involves costs associated with obtaining and renewing the Shop & Establishment License.",
    "Periodic Inspections - Subject to inspections by local authorities to ensure ongoing compliance with regulations.",
    "Operational Restrictions - May impose certain operational constraints and requirements on the business.",
    "Penalties for Non-Compliance - Non-compliance can result in fines or other legal consequences.",
  ];

  const documents = [
    {
      title: "Identity Proof",
      description: "Valid identity proof of the owner or business entity, such as Aadhar card, PAN card, or passport."
    },
    {
      title: "Premises Proof",
      description: "Proof of ownership or lease agreement for the premises where the shop or establishment operates."
    },
    {
      title: "Business Address",
      description: "Proof of business address, such as utility bills or rent agreements, to verify the location of the establishment."
    },
    {
      title: "Employee Details",
      description: "Details of employees working in the establishment, including their identification and employment contracts."
    },
    {
      title: "Payment Receipts",
      description: "Receipts for any fees paid for the license application and processing."
    }
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents, including identity proof, premises proof, and employee details."
    },
    {
      title: "Complete Application",
      description: "Fill out the application form with accurate details about your shop or establishment and its operations."
    },
    {
      title: "Submit Application",
      description: "Submit the completed application along with required documents to the local authority."
    },
    {
      title: "Inspection",
      description: "Undergo an inspection by local authorities to ensure compliance with regulations."
    },
    {
      title: "Receive License",
      description: "Upon successful inspection and verification, receive the Shop & Establishment License to operate legally."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Shop & Establishment License
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Obtaining a Shop & Establishment License is essential for operating your business legally. Our streamlined process ensures you meet all legal requirements and operate without interruptions.
            </p>

            <p>Ensure legal compliance and smooth operations with our expert assistance in obtaining your Shop & Establishment License.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Shop & Establishment License Overview</h6>
          <p className='text-center mt-4'>A Shop & Establishment License is required to legally operate a retail or commercial establishment. It ensures compliance with local regulations and provides legal validity to your business operations.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Shop & Establishment License?</h6>

            <p className='mb-4'>A Shop & Establishment License is a mandatory registration required for operating a shop or establishment. It ensures adherence to labor laws and regulations, provides legitimacy to business operations, and protects employee rights.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Shop & Establishment License</h6>
            <p className='mb-4'>To be eligible for a Shop & Establishment License, you must:

              <ul>
                <li><strong>Provide Identity Proof -</strong> Submit valid identity proof of the owner or business entity.</li>

                <li><strong>Submit Premises Proof -</strong> Provide proof of ownership or lease agreement for the premises.</li>

                <li><strong>Provide Business Address -</strong> Proof of business address, such as utility bills or rent agreements.</li>

                <li><strong>Details of Employees -</strong> Information about employees, including their identification and employment contracts.</li>

                <li><strong>Payment Receipts -</strong> Receipts for any fees paid for the license application and processing.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Shop & Establishment License</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Shop & Establishment License</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Shop & Establishment License</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Shop & Establishment License</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for Shop & Establishment License</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Shop & Establishment License?</h6>
            <p>We provide expert assistance in obtaining your Shop & Establishment License, ensuring compliance with all local regulations and a smooth application process.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default ShopEstablishmentLicense;