import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const CompanyDirectorChange = ({ height }) => {

  // Company Director Change grid items
  const items = [
    { title: 'Director Resignation', description: 'Formal process for a director to resign from their position.' },
    { title: 'Appointment of New Director', description: 'Procedures to appoint a new director to the board.' },
    { title: 'Board Resolution', description: 'Resolution required for approving the change of director.' },
    { title: 'Update Company Records', description: 'Updating company records and documents to reflect the new director.' },
    { title: 'Notify Regulatory Authorities', description: 'Inform relevant regulatory bodies about the director change.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Compliance - Ensure the company adheres to legal requirements for director changes.",
    "Streamlined Process - Efficient management of the director change process.",
    "Professionalism - Maintain a professional approach in updating company leadership.",
    "Minimize Disruptions - Ensure smooth transition without disrupting company operations.",
    "Centralized Management - Handle all aspects of director change in one process.",
  ];

  const disadvantages = [
    "Administrative Load - Managing director changes can be administratively demanding.",
    "Potential Costs - Possible costs associated with legal documentation and compliance.",
    "Risk of Delays - Risk of delays if documentation or processes are not managed promptly.",
  ];

  const documents = [
    {
      title: "Director Resignation Letter",
      description: "Formal letter of resignation from the outgoing director."
    },
    {
      title: "Director Appointment Form",
      description: "Form for appointing a new director."
    },
    {
      title: "Board Resolution",
      description: "Resolution passed by the board approving the director change."
    },
    {
      title: "Updated Director Register",
      description: "Updated register reflecting the new director."
    },
    {
      title: "Regulatory Notification",
      description: "Notification to regulatory authorities about the director change."
    }
  ];

  const steps = [
    {
      title: "Resignation of Outgoing Director",
      description: "Obtain and submit a formal resignation letter from the outgoing director."
    },
    {
      title: "Appoint New Director",
      description: "Complete and submit the necessary forms to appoint the new director."
    },
    {
      title: "Pass Board Resolution",
      description: "Obtain approval from the board for the director change via a resolution."
    },
    {
      title: "Update Company Records",
      description: "Update the company’s records to reflect the new director."
    },
    {
      title: "Notify Regulatory Authorities",
      description: "Submit the necessary notifications to regulatory bodies regarding the director change."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Company Director Change
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Changing a director in a company involves a series of administrative tasks to ensure legal compliance and smooth transition. Our service facilitates the entire process, ensuring that all regulatory requirements are met and company records are updated accordingly.
            </p>

            <p>Manage your company’s director change efficiently with our expert assistance.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Company Director Change</h6>
          <p className='text-center mt-4'>Changing a company director requires careful attention to legal and administrative procedures. Our service ensures that the change is processed smoothly and in compliance with all relevant regulations.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Company Director Change?</h6>

            <p className='mb-4'>Company Director Change involves updating the company’s leadership by resigning or appointing directors. This process includes formalizing the changes through resolutions, updating records, and notifying regulatory bodies to ensure compliance with legal requirements.</p>
          </div>

          <div id="application" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Director Change</h6>
            <p className='mb-4'>To change a director, follow these steps:

              <ul>
                <li><strong>Resignation of Outgoing Director -</strong> Obtain and submit a formal resignation letter from the outgoing director.</li>

                <li><strong>Appoint New Director -</strong> Complete and submit the necessary forms for appointing the new director.</li>

                <li><strong>Pass Board Resolution -</strong> Obtain approval from the board of directors via a resolution.</li>

                <li><strong>Update Company Records -</strong> Update the company’s records and registers to reflect the new director.</li>

                <li><strong>Notify Regulatory Authorities -</strong> Submit the necessary notifications to relevant regulatory bodies regarding the director change.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our Company Director Change Services</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Advantages of Our Company Director Change Services</h6>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Disadvantages of Our Company Director Change Services</h6>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='why-choose mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Required for Company Director Change</h6>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="process" className='how-to-apply mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Steps to Complete Company Director Change</h6>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default CompanyDirectorChange;