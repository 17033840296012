import React from 'react'
import FAQ from './FAQz'
import Testimonials from './client'
import Sample from './Sample'
import CardConatiner from './Cardcontainer';
import Maincontent from "./Container"
import ScrollToTopButton from './ScrollTop'; // Assuming you've already created ScrollToTopButton
const  Body = () => {
  return (
    <div>
      <Maincontent />
      <Sample />
      {/* <Trustedcontainer /> */}
      <CardConatiner />
      <Testimonials />
      <FAQ />

      {/* Scroll to top button */}
      <ScrollToTopButton />
    </div>
  );
};

export default Body;