import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TrademarkSearch = ({ height }) => {

  // Trademark Search grid items
  const items = [
    { title: 'Search Databases', description: 'Utilize online databases to search for existing trademarks and assess their availability.' },
    { title: 'Conduct a Comprehensive Search', description: 'Perform a detailed search to identify any potential conflicts with existing trademarks.' },
    { title: 'Analyze Search Results', description: 'Evaluate the search results to determine the likelihood of registration and potential conflicts.' },
    { title: 'Consult with an Expert', description: 'Seek advice from a trademark attorney to interpret the search results and guide you through the next steps.' },
    { title: 'File a Trademark Application', description: 'Once the search is clear, proceed to file a trademark application with the relevant authorities.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Avoid Conflicts - Helps identify potential conflicts with existing trademarks before applying for registration.",
    "Save Time and Money - Avoids spending resources on a trademark that may already be in use or too similar to existing marks.",
    "Informed Decisions - Provides valuable information to make informed decisions about the likelihood of successful registration.",
    "Peace of Mind - Ensures that your trademark is unique and reduces the risk of legal disputes or rejections.",
    "Professional Guidance - Offers expert advice and insights to navigate the trademark search process effectively.",
  ];

  const disadvantages = [
    "Cost - Conducting a comprehensive search may involve fees, especially if using professional services.",
    "Complexity - The search process can be complex and may require expertise to interpret results accurately.",
    "No Guarantees - A search does not guarantee that a trademark will be registered or that no conflicts will arise.",
    "Time-Consuming - The search process can take time, which may delay the overall trademark registration process.",
  ];

  const documents = [
    {
      title: "Trademark Search Report",
      description: "A detailed report from a trademark search, outlining the findings and potential conflicts."
    },
    {
      title: "Trademark Application Form",
      description: "The form needed for filing a trademark application, which can be prepared once the search is complete."
    },
    {
      title: "Identification Documents",
      description: "Proof of identity for the applicant or business entity, as required for trademark registration."
    },
    {
      title: "Proof of Use",
      description: "Evidence of the trademark's use in commerce, if applicable."
    },
    {
      title: "Payment Receipts",
      description: "Receipts or proof of payment for search and application fees."
    }
  ];

  const steps = [
    {
      title: "Identify Trademark Scope",
      description: "Define the scope and nature of your trademark, including its design and the goods or services it will cover."
    },
    {
      title: "Perform Preliminary Search",
      description: "Conduct a preliminary search using online databases to identify potential conflicts or similar trademarks."
    },
    {
      title: "Engage Professional Services",
      description: "Consider hiring a trademark attorney or specialist to conduct a comprehensive search and provide expert advice."
    },
    {
      title: "Review Search Results",
      description: "Analyze the search results to determine the potential for conflicts and assess the likelihood of successful registration."
    },
    {
      title: "Prepare and File Application",
      description: "If the search results are favorable, prepare and file your trademark application with the relevant trademark office."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Trademark Search
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Trademark Search is a critical step in the trademark registration process. It involves searching existing trademarks to ensure that your desired trademark is unique and does not conflict with existing marks. A comprehensive search helps in making informed decisions and avoiding potential legal issues.
            </p>

            <p>Get started with our expert services to conduct a thorough trademark search and ensure the uniqueness of your trademark.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Trademark Search Services</h6>
          <p className='text-center mt-4'>Our Trademark Search services are designed to help you identify potential conflicts and assess the availability of your trademark. We provide comprehensive search solutions and expert guidance to ensure that your trademark is unique and protectable.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Trademark Search?</h6>

            <p className='mb-4'>Trademark Search is the process of searching existing trademarks to determine if your desired trademark is already in use or too similar to an existing mark. This step is crucial in the trademark registration process as it helps avoid potential conflicts and ensures the uniqueness of your trademark.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Trademark Search</h6>
            <p className='mb-4'>There are no specific eligibility requirements for conducting a trademark search. However, it is important to conduct a search before filing a trademark application to ensure that your mark is unique and does not conflict with existing trademarks.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of Trademark Search</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className="w-full px-16 mt-10 pt-2">
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Trademark Search</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Trademark Search</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Trademark Search Process</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default TrademarkSearch;