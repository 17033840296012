import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const PartnershipFirmNavbar = ({ height }) => {

  // Partnership Firm service grid items
  const items = [
    { title: 'Shared Responsibility', description: 'Partners share the responsibility and liability of the business operations.' },
    { title: 'Flexibility in Management', description: 'Allows for flexible management and decision-making among partners.' },
    { title: 'Cost-Effective', description: 'Lower registration and compliance costs compared to companies.' },
    { title: 'Easy Formation', description: 'Simpler to set up with fewer legal requirements.' },
    { title: 'Profit Sharing', description: 'Profits are shared among partners as per the agreement.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Shared Responsibility - Partners share the responsibility and liability of the business operations.",
    "Flexibility in Management - Allows for flexible management and decision-making among partners.",
    "Cost-Effective - Lower registration and compliance costs compared to companies.",
    "Easy Formation - Simpler to set up with fewer legal requirements.",
    "Profit Sharing - Profits are shared among partners as per the agreement."
  ];

  const disadvantages = [
    "Unlimited Liability - Partners are personally liable for the debts and obligations of the firm.",
    "Potential for Disputes - Differences in opinions among partners can lead to conflicts.",
    "Limited Lifespan - The partnership may dissolve upon the death or withdrawal of a partner.",
    "Raising Capital - May face difficulties in raising capital compared to companies."
  ];

  const documents = [
    {
      title: "Partnership Deed",
      description: "A detailed document outlining the terms of the partnership, including profit-sharing ratios and responsibilities."
    },
    {
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills."
    },
    {
      title: "Address Proof of Partners",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      title: "Identity Proof of Partners",
      description: "Passport, Aadhar card, or driving license."
    },
    {
      title: "Passport-size Photos",
      description: "With a white background."
    },
    {
      title: "PAN Card",
      description: "For all partners."
    }
  ];

  const steps = [
    {
      title: "Draft a Partnership Deed",
      description: "Create a legal document detailing the terms and conditions of the partnership."
    },
    {
      title: "Choose a Partnership Name",
      description: "Ensure the name complies with regulatory guidelines and is not already taken."
    },
    {
      title: "Register the Partnership Firm",
      description: "Submit the necessary documents and forms to the Registrar of Firms."
    },
    {
      title: "Obtain PAN and TAN",
      description: "Apply for Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN)."
    },
    {
      title: "Open a Bank Account",
      description: "Open a bank account in the name of the partnership firm."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#registration" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#why-choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Partnership Firm Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A Partnership Firm is a business structure where two or more individuals come together to operate a business with shared responsibilities. T2F assists in navigating the registration process, ensuring compliance and a smooth setup.
            </p>

            <p>10K+ Trusted Companies Successfully Registered</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Partnership Firm Registration Made Easy with T2F</h6>
          <p className='text-center mt-4'>Starting a Partnership Firm allows for shared responsibilities and flexibility in business management. T2F provides comprehensive support throughout the registration process, ensuring your partnership firm is set up efficiently and in compliance with legal requirements. Contact us to get started.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Partnership Firm?</h6>

            <p className='mb-4'>A Partnership Firm is a business structure where two or more individuals or entities come together to carry on a business with shared responsibilities, profits, and losses. Key characteristics include:</p>
            <div>
              <ul><strong>Shared Responsibility:</strong> Partners share the responsibility and liability of the business operations.</ul>

              <ul><strong>Flexibility in Management:</strong> Allows for flexible management and decision-making among partners.</ul>

              <ul><strong>Cost-Effective:</strong> Lower registration and compliance costs compared to companies.</ul>

              <ul><strong>Easy Formation:</strong> Simpler to set up with fewer legal requirements.</ul>

              <ul><strong>Profit Sharing:</strong> Profits are shared among partners as per the agreement.</ul>
            </div>

            <p>Partnership firms are governed by the Partnership Act, 1932, and require a partnership deed outlining the terms of the partnership.</p>
          </div>

          <div id='eligibility' className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility Criteria for Partnership Firm Registration</h6>
            <p>To register a Partnership Firm, you need to meet the following criteria:</p>
            <ul>
              <li><strong>Minimum Partners:</strong> At least two partners are required to form a partnership firm.</li>

              <li><strong>Indian Citizens/Residents:</strong> Partners must be Indian citizens or residents.</li>

              <li><strong>Partnership Deed:</strong> A well-drafted partnership deed outlining the terms and conditions of the partnership.</li>

              <li><strong>Registered Office:</strong> Proof of a registered office address is required.</li>

              <li><strong>Compliance with Regulations:</strong> Adherence to regulations set forth by relevant authorities.</li>
            </ul>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of a Partnership Firm</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of a Partnership Firm</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔️</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of a Partnership Firm</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='documents' className='secondLLP pr-96 pl-16 mt-16'>
          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Partnership Firm Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='registration' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth partnership firm registration process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for your Partnership Firm Registration</h6>
            <p>T2F is your trusted partner for Partnership Firm registration:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you set up your partnership firm.</li>

              <li><strong>Documentation Assistance - </strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>DSC and PAN Assistance -</strong> Facilitation of obtaining digital signatures and Permanent Account Number.</li>

              <li><strong>Compliance Support -</strong> Ensure adherence to legal and regulatory requirements.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the registration process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnershipFirmNavbar;
