import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard'; // Assuming Card component is used for feature display
import './LLPcardgrid.css'; // Custom styling for card grid
import './LLP.css'; // Custom styling for the page
import ScrollToTopButton from './ScrollTop'; // Custom component for scroll to top button

const IndianSubsidiaryNavbar = ({ height }) => {

  // Indian Subsidiary service grid items
  const items = [
    { title: 'Foreign Ownership', description: 'Allows foreign companies to establish a presence in India while retaining majority ownership.' },
    { title: 'Limited Liability', description: 'Shareholders’ liability is limited to their shareholding in the subsidiary.' },
    { title: 'Separate Legal Entity', description: 'The subsidiary is a separate legal entity from the parent company.' },
    { title: 'Operational Flexibility', description: 'Ability to operate independently in India while benefiting from the parent company’s resources.' },
    { title: 'Market Access', description: 'Provides access to the Indian market and its economic opportunities.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Foreign Ownership - Allows foreign companies to establish a presence in India while retaining majority ownership.",
    "Limited Liability - Shareholders’ liability is limited to their shareholding in the subsidiary.",
    "Separate Legal Entity - The subsidiary is a separate legal entity from the parent company.",
    "Operational Flexibility - Ability to operate independently in India while benefiting from the parent company’s resources.",
    "Market Access - Provides access to the Indian market and its economic opportunities."
  ];

  const disadvantages = [
    "Regulatory Compliance - Must comply with Indian regulatory requirements, which can be complex.",
    "Higher Costs - Establishing and maintaining a subsidiary may involve higher costs compared to other structures.",
    "Management Complexity - Requires effective management to align subsidiary operations with the parent company’s goals.",
    "Local Market Challenges - Navigating the Indian market may present challenges related to local business practices and regulations."
  ];

  const documents = [
    {
      title: "Parent Company’s Certificate of Incorporation",
      description: "Proof of the parent company's registration in its home country."
    },
    {
      title: "Board Resolution",
      description: "Resolution passed by the parent company’s board of directors authorizing the establishment of the subsidiary."
    },
    {
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills for the subsidiary’s registered office in India."
    },
    {
      title: "Identity Proof of Indian Directors",
      description: "Passport, Aadhar card, or driving license for Indian directors."
    },
    {
      title: "PAN Card",
      description: "Permanent Account Number (PAN) for the subsidiary."
    },
    {
      title: "Address Proof of Indian Directors",
      description: "Recent documents like bank statements or utility bills."
    }
  ];

  const steps = [
    {
      title: "Obtain Digital Signature Certificates (DSC)",
      description: "Acquire DSCs for all the proposed directors of the subsidiary."
    },
    {
      title: "Obtain Director Identification Number (DIN)",
      description: "Apply for DIN for the proposed directors."
    },
    {
      title: "Draft a Memorandum and Articles of Association",
      description: "Prepare the necessary documents outlining the subsidiary’s objectives and rules."
    },
    {
      title: "File for Name Reservation",
      description: "Submit an application to reserve the name of the subsidiary with the Ministry of Corporate Affairs (MCA)."
    },
    {
      title: "Incorporation Application",
      description: "File the incorporation application along with required documents with the MCA."
    },
    {
      title: "Obtain PAN and TAN",
      description: "Apply for Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the subsidiary."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#registration" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#why-choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Indian Subsidiary Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Establishing an Indian Subsidiary allows a foreign company to expand its operations into India while retaining majority ownership. T2F streamlines the registration process, ensuring compliance with Indian regulations and smooth setup.
            </p>

            <p>10K+ Trusted Companies Successfully Registered</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Indian Subsidiary Registration Made Easy with T2F</h6>
          <p className='text-center mt-4'>Registering an Indian Subsidiary allows a foreign company to establish a foothold in the Indian market while retaining control. T2F provides comprehensive support to navigate the registration process efficiently and ensure compliance with all legal requirements. Contact us to get started.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is an Indian Subsidiary?</h6>

            <p className='mb-4'>An Indian Subsidiary is a business entity that is owned or controlled by a foreign company. It operates as a separate legal entity in India, providing the foreign parent company with the benefits of market access, operational flexibility, and limited liability. Key characteristics include:</p>
            <div>
              <ul><strong>Foreign Ownership:</strong> Allows foreign companies to establish a presence in India while retaining majority ownership.</ul>

              <ul><strong>Limited Liability:</strong> Shareholders’ liability is limited to their shareholding in the subsidiary.</ul>

              <ul><strong>Separate Legal Entity:</strong> The subsidiary is a separate legal entity from the parent company.</ul>

              <ul><strong>Operational Flexibility:</strong> Ability to operate independently in India while benefiting from the parent company’s resources.</ul>

              <ul><strong>Market Access:</strong> Provides access to the Indian market and its economic opportunities.</ul>
            </div>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of an Indian Subsidiary</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of an Indian Subsidiary</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔️</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of an Indian Subsidiary</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='documents' className='secondLLP pr-96 pl-16 mt-16'>
          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Indian Subsidiary Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='registration' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth Indian Subsidiary registration process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for your Indian Subsidiary Registration</h6>
            <p>T2F is your trusted partner for Indian Subsidiary registration:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you set up your Indian Subsidiary.</li>

              <li><strong>Documentation Assistance - </strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>DSC and PAN Assistance -</strong> Facilitation of obtaining digital signatures and Permanent Account Number.</li>

              <li><strong>Compliance Support -</strong> Ensure adherence to legal and regulatory requirements.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the registration process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default IndianSubsidiaryNavbar;
