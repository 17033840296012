import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const NitiAayogRegistration = ({ height }) => {

  // Niti Aayog Registration grid items
  const items = [
    { title: 'Unique Identity', description: 'Register with Niti Aayog to get a unique identity for your NGO or trust.' },
    { title: 'Funding Opportunities', description: 'Access government and non-government funding options.' },
    { title: 'Grant Approvals', description: 'Get approvals for various government grants and financial assistance.' },
    { title: 'Tax Exemptions', description: 'Register for tax exemptions under relevant sections.' },
    { title: 'Increased Credibility', description: 'Niti Aayog registration enhances the credibility of your organization.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Government Recognition - Gain official recognition from the Indian government.",
    "Access to Funding - Registered NGOs and trusts can apply for various government and non-government grants.",
    "Increased Trust - Improve your organization's credibility and trust among donors and stakeholders.",
    "Tax Exemptions - Register for tax exemptions under Income Tax Act sections like 80G and 12A.",
    "Transparency - Niti Aayog registration encourages transparency and accountability in operations.",
  ];

  const disadvantages = [
    "Regular Compliance - Requires regular compliance and reporting to maintain registration.",
    "Application Scrutiny - The registration process involves stringent scrutiny of documentation.",
    "Eligibility Criteria - Not all organizations are eligible for Niti Aayog registration.",
    "Time-Consuming Process - The registration process can be lengthy and bureaucratic.",
    "Limited Resources - Some smaller NGOs may face challenges in meeting compliance requirements.",
  ];

  const documents = [
    {
      title: "Trust Deed / Society Certificate",
      description: "Trust Deed or Society Registration Certificate for establishing the organization’s existence."
    },
    {
      title: "PAN Card",
      description: "PAN card of the organization for identification purposes."
    },
    {
      title: "Financial Statements",
      description: "Audited financial statements for the previous years to showcase financial transparency."
    },
    {
      title: "Board Member Details",
      description: "Details of all board members, including their PAN and Aadhaar numbers."
    },
    {
      title: "Registration Certificate",
      description: "Certificate of registration under the Trust Act, Society Registration Act, or other relevant acts."
    }
  ];

  const steps = [
    {
      title: "Prepare Documents",
      description: "Gather all necessary documents, including registration certificates, financial records, and identification details."
    },
    {
      title: "Online Application",
      description: "Submit the Niti Aayog registration application online along with required documents."
    },
    {
      title: "Verification Process",
      description: "Wait for the verification of your application by Niti Aayog authorities."
    },
    {
      title: "Receive Unique ID",
      description: "Once approved, receive your organization's unique Niti Aayog ID."
    },
    {
      title: "Maintain Compliance",
      description: "Ensure timely compliance and reporting to maintain registration status."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Niti Aayog Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Get your NGO or trust recognized by Niti Aayog and unlock various benefits like funding opportunities, tax exemptions, and credibility enhancement.
            </p>

            <p>Registering under Niti Aayog allows you to access government schemes, grants, and donor funding while ensuring transparency and accountability in your operations.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Niti Aayog Registration</h6>
          <p className='text-center mt-4'>Niti Aayog provides a unique platform for NGOs and trusts to access various benefits, including government schemes and funding opportunities.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Niti Aayog Registration?</h6>

            <p className='mb-4'>Niti Aayog registration is a government initiative aimed at providing NGOs and trusts with a platform to register and access various grants, government schemes, and funding opportunities. It enhances transparency and accountability in the functioning of these organizations.</p>
          </div>

          <div id="application" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Niti Aayog Registration</h6>
            <p className='mb-4'>To register your NGO or trust with Niti Aayog, follow these steps:

              <ul>
                <li><strong>Prepare Documents -</strong> Gather all necessary documents, such as the registration certificate, financial records, and member details.</li>

                <li><strong>Submit Online Application -</strong> Submit the registration form online along with the required documents.</li>

                <li><strong>Verification -</strong> Wait for the verification of your application by Niti Aayog authorities.</li>

                <li><strong>Receive Unique ID -</strong> Upon approval, receive a unique Niti Aayog ID for your organization.</li>

                <li><strong>Maintain Compliance -</strong> Ensure regular compliance and reporting to continue receiving benefits.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Niti Aayog Registration</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className='AdvantagesLLP'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Advantages of Niti Aayog Registration</h6>
            <ul>
              {advantages.map((advantage, index) => (
                <li key={index}>{advantage}</li>
              ))}
            </ul>
          </div>

          <div className='DisadvantagesLLP'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Disadvantages of Niti Aayog Registration</h6>
            <ul>
              {disadvantages.map((disadvantage, index) => (
                <li key={index}>{disadvantage}</li>
              ))}
            </ul>
          </div>

          <div id="documents" className='docs-req'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Documents Required for Niti Aayog Registration</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {documents.map((doc, index) => (
                <Card
                  key={index}
                  title={doc.title}
                  description={doc.description}
                />
              ))}
            </div>
          </div>

          <div id="process" className='steps w-full flex flex-wrap' >
            <div className='stepsToRegister'>
              <h6 className='text-blue-500 text-lg font-semibold text-center'>Steps to Complete Niti Aayog Registration</h6>
              <ul>
                {steps.map((step, index) => (
                  <li key={index}>
                    <strong>{step.title} -</strong> {step.description}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Niti Aayog Registration?</h6>
            <p>We provide professional guidance throughout the Niti Aayog registration process, ensuring that your NGO or trust meets all the compliance requirements and gets access to funding opportunities and government schemes.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default NitiAayogRegistration;