import React from 'react'
import { Link } from 'react-router-dom'

const MyCard = ({heading,para , src,alt }) => {
  return (
    <div
        style={{
        display: "flex",
        flexDirection: "row",
        height: "140px",
        width: "344px",
        margin: "4px",
        padding:"10px", 
        }}
        className='border-2 rounded-md shadow-md'
      >


        <div className='pb-2'>
        <h4 style={{fontSize:'20px', lineHeight:'30px', fontWeight:'600'}}>
        {heading}
        </h4>
        <p style={{fontSize:"14px" , lineHeight:'22px', fontWeight:'400'}}>
        {para}
        </p>

      <Link to="/get-started" style={{textDecoration:'none'}}>Get started &gt;</Link>
        </div>

        <div style={{ display: "flex", flexDirection: "column",justifyContent:"center",alignItems:"center" }}>

          <div style={{"height":"130px","width":"112px"}}>
            <img className='h-full w-full' src={src} alt={alt}/>
          </div>
        </div>


      </div>
  )
}

export default MyCard;
