import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TrademarkOpposition = ({ height }) => {

  // Trademark Opposition grid items
  const items = [
    { title: 'Understand Opposition Grounds', description: 'Review the reasons provided for the opposition, including any legal or procedural grounds cited against your trademark application.' },
    { title: 'Prepare Your Defense', description: 'Formulate a robust defense against the opposition by gathering evidence and building arguments to support your trademark application.' },
    { title: 'File Defense Documents', description: 'Submit your defense to the relevant trademark office, ensuring all necessary documents and arguments are included in your response.' },
    { title: 'Attend Hearings', description: 'If required, participate in hearings to present your case in person and address any additional concerns raised by the opposing party.' },
    { title: 'Follow Up and Monitor', description: 'Keep track of the opposition proceedings and any subsequent actions or requirements, ensuring timely responses to any further developments.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Defense Opportunity - Provides a chance to defend your trademark application against opposition, safeguarding your brand rights.",
    "Professional Guidance - Offers access to legal expertise to navigate complex opposition proceedings effectively.",
    "Preserve Application - Helps maintain your trademark application despite challenges, ensuring a clearer path to registration.",
    "Resolve Disputes - Addresses and resolves conflicts that may otherwise hinder the registration process.",
    "Strengthen Application - Allows for refinement of your application in response to valid concerns, potentially enhancing its chances of success.",
  ];

  const disadvantages = [
    "Complex Process - Opposition proceedings can be intricate, requiring detailed legal knowledge and documentation.",
    "Cost - Legal representation and preparation of defense can incur additional expenses.",
    "Time-Consuming - The process can be lengthy and require significant time and effort to address opposition effectively.",
    "Risk of Rejection - There is a possibility that the opposition may lead to rejection of your trademark application.",
    "Additional Requirements - May involve further amendments or additional information, complicating the process.",
  ];

  const documents = [
    {
      title: "Opposition Notice",
      description: "The official notice from the trademark office detailing the grounds for opposition against your trademark application."
    },
    {
      title: "Defense Statement",
      description: "Your formal response to the opposition, including arguments, evidence, and supporting documents to counter the claims."
    },
    {
      title: "Supporting Evidence",
      description: "Documentation and evidence supporting your defense, such as proof of use, distinctiveness, or other relevant materials."
    },
    {
      title: "Legal Correspondence",
      description: "Any communication with legal representatives or the trademark office regarding the opposition and defense."
    },
    {
      title: "Hearing Documents",
      description: "Documents required for hearings if scheduled, including any additional evidence or statements to be presented."
    }
  ];

  const steps = [
    {
      title: "Review Opposition Notice",
      description: "Examine the notice of opposition to understand the specific grounds and reasons for the opposition."
    },
    {
      title: "Prepare Defense Materials",
      description: "Gather evidence and prepare a detailed defense against the opposition, addressing each point raised."
    },
    {
      title: "File Defense Documents",
      description: "Submit your defense to the trademark office, including all necessary documents and arguments."
    },
    {
      title: "Participate in Hearings",
      description: "Attend any scheduled hearings to present your case and address additional concerns from the opposing party."
    },
    {
      title: "Monitor Proceedings",
      description: "Track the status of the opposition proceedings and respond to any further requirements or developments."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Trademark Opposition
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Trademark Opposition is a formal procedure where a third party contests your trademark application. This opposition could be based on various grounds, and handling it effectively is essential to securing your trademark rights and advancing your application.
            </p>

            <p>Address trademark oppositions with professional support to navigate the complexities and protect your brand.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Trademark Opposition Services</h6>
          <p className='text-center mt-4'>Our Trademark Opposition services assist you in addressing challenges raised against your trademark application. We provide expert support to navigate the opposition process and secure your trademark rights.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Trademark Opposition?</h6>

            <p className='mb-4'>Trademark Opposition is a legal challenge filed by a third party against your trademark application. This opposition can be based on various factors such as similarity to existing trademarks or claims of prior rights. Successfully addressing the opposition is crucial to securing your trademark registration.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Trademark Opposition</h6>
            <p className='mb-4'>To oppose a trademark application, you must have a legitimate legal interest, such as owning an existing trademark that could be confused with the new application. Ensure that you file your opposition within the specified period after the publication of the trademark application.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of Trademark Opposition Services</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Handling Trademark Opposition</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Trademark Opposition</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Trademark Opposition</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default TrademarkOpposition;