import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const GSTNavbar = ({ height }) => {

  // GST Registration service grid items
  const items = [
    { title: 'Legal Compliance', description: 'Ensures compliance with the Goods and Services Tax Act and avoids legal penalties.' },
    { title: 'Input Tax Credit', description: 'Allows businesses to claim input tax credit on purchases, reducing overall tax liability.' },
    { title: 'Increased Credibility', description: 'Enhances the credibility of your business by being a registered entity under GST.' },
    { title: 'Ease of Trade', description: 'Facilitates easier inter-state trade with proper documentation and compliance.' },
    { title: 'Simplified Tax System', description: 'Streamlines the tax system with a single tax rate for goods and services.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Compliance - GST registration ensures your business complies with legal requirements and avoids penalties for non-compliance.",
    "Input Tax Credit - Enables businesses to claim input tax credit on purchases, reducing the overall tax burden and improving cash flow.",
    "Increased Credibility - A GST registration adds credibility to your business, making it more trustworthy in the eyes of clients and partners.",
    "Ease of Trade - Simplifies inter-state trade with a unified tax system and proper documentation, enhancing business operations.",
    "Simplified Tax System - Provides a single tax rate for goods and services, reducing complexity and administrative burden.",
  ];

  const disadvantages = [
    "Compliance Costs - May incur costs related to compliance, including accounting software, professional fees, and regular tax filings.",
    "Administrative Burden - Requires regular maintenance of records, preparation of tax returns, and adherence to GST regulations.",
    "Complexity for Small Businesses - The process and requirements of GST registration may be complex for small businesses or startups.",
    "Potential Penalties - Non-compliance with GST rules can result in penalties and legal consequences, requiring diligent adherence.",
  ];

  const documents = [
    {
      title: "PAN Card",
      description: "A valid Permanent Account Number (PAN) card of the business or the proprietor."
    },
    {
      title: "Business Proof",
      description: "Proof of business address, such as utility bills or lease agreements."
    },
    {
      title: "Identity Proof",
      description: "Identity proof of the business owner or authorized signatory, such as Aadhar card or passport."
    },
    {
      title: "Bank Statement",
      description: "A recent bank statement of the business entity."
    },
    {
      title: "Business Registration Certificate",
      description: "Certificate of business registration or incorporation, if applicable."
    }
  ];

  const steps = [
    {
      title: "Gather Required Documents",
      description: "Collect all necessary documents for GST registration, including identity proof, business proof, and PAN card."
    },
    {
      title: "Complete GST Application",
      description: "Fill out the GST registration application form available on the GST portal or with the help of a professional."
    },
    {
      title: "Submit Application",
      description: "Submit the completed application along with the required documents to the GST authorities."
    },
    {
      title: "Verification Process",
      description: "The GST authorities will verify the submitted documents and application details."
    },
    {
      title: "Receive GSTIN",
      description: "Upon successful verification, receive your Goods and Services Tax Identification Number (GSTIN)."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              GST Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              GST Registration is crucial for businesses to comply with the Goods and Services Tax laws. It allows you to claim input tax credits, enhances your business’s credibility, and simplifies the tax system for smoother operations.
            </p>

            <p>Thousands of businesses have streamlined their tax processes with our assistance.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>GST Registration Simplified</h6>
          <p className='text-center mt-4'>GST Registration is essential for businesses to meet regulatory requirements and enjoy benefits like input tax credits. Our streamlined process makes it easy for you to get registered and stay compliant.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is GST Registration?</h6>

            <p className='mb-4'>GST Registration is the process of registering your business under the Goods and Services Tax (GST) regime. It allows businesses to collect GST on sales, claim input tax credits, and comply with the tax regulations. It is a legal requirement for businesses with a turnover above the prescribed threshold.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for GST Registration</h6>
            <p className='mb-4'>To apply for GST registration, you must meet the following requirements:

              <ul>
                <li><strong>PAN Card -</strong> A valid Permanent Account Number (PAN) card of the business or the proprietor.</li>

                <li><strong>Business Proof -</strong> Proof of business address, such as utility bills or lease agreements.</li>

                <li><strong>Identity Proof -</strong> Identity proof of the business owner or authorized signatory, such as Aadhar card or passport.</li>

                <li><strong>Bank Statement -</strong> A recent bank statement of the business entity.</li>

                <li><strong>Business Registration Certificate -</strong> Certificate of business registration or incorporation, if applicable.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of GST Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of GST Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of GST Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for GST Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for GST Registration</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default GSTNavbar;