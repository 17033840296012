import React from "react";
import { Card, Container, Col, Row, Button } from "react-bootstrap";
import FormaBussiness from './iconsweb p/form a business.webp'
import registration from './iconsweb p/registration.webp'
import Intellectual from './iconsweb p/intellectual-property.webp'
import BussinessTools from './iconsweb p/business-tool.webp'
import Compliances from './iconsweb p/compliance.webp'
import Taxation from './iconsweb p/taxation.webp'
import Audits from './iconsweb p/audits.webp'
import Parter from './iconsweb p/partner-with-us.webp'
import './Cardcontainer.css'

function CardContainer() {
    return (
        <Container className="custom-container">
            <h3 className="container-heading"> One stop destination for all you
                <span className="highlight"> Business!</span></h3>

            <Row className="card-row">
                <Col sm={3}>
                    <Card className="custom-cards">
                        <div className="image-box">
                            <img src ={FormaBussiness} alt="Bussiness"/>
                        </div>
                        <Card.Body className="card-body">
                            <Card.Title className="card-title">Form a Business</Card.Title>
                            <Card.Text className="card-text">All-in-one Tech Solutions for startup Compliance</Card.Text>
                            <Button href="#" className="link-button"> &gt;</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="custom-cards">
                        <div className="image-box">
                            <img src={registration} alt="registration"/>
                        </div>
                        <Card.Body className="card-body">
                            <Card.Title className="card-title">Registration</Card.Title>
                            <Card.Text className="card-text">All-in-one Tech Solutions for startup Compliance</Card.Text>
                            <Button href="#" className="link-button"> &gt;</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="custom-cards">
                        <div className="image-box">
                            <img src = {Intellectual} alt="property"/>
                        </div>
                        <Card.Body className="card-body">
                            <Card.Title className="card-title">Intellectual Property</Card.Title>
                            <Card.Text className="card-text">All-in-one Tech Solutions for startup Compliance</Card.Text>
                            <Button href="#" className="link-button"> &gt;</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="custom-cards">
                        <div className="image-box">
                            <img src={BussinessTools} alt="Tools"/>
                        </div>
                        <Card.Body className="card-bodys">
                            <Card.Title className="card-title">Business Tools</Card.Title>
                            <Card.Text className="card-text">All-in-one Tech Solutions for startup Compliance</Card.Text>
                            <Button href="#" className="link-button"> &gt;</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="card-row">
                <Col sm={3}>
                    <Card className="custom-cards">
                        <div className="image-box">
                            <img src={Compliances} alt="Compliances"/>
                        </div>
                        <Card.Body className="card-bodys">
                            <Card.Title className="card-title">Compliances</Card.Title>
                            <Card.Text className="card-text">All-in-one Tech Solutions for startup Compliance</Card.Text>
                            <Button href="#" className="link-button"> &gt;</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="custom-cards">
                        <div className="image-box">
                            <img src={Taxation} alt="Taxation"/>
                        </div>
                        <Card.Body className="card-bodys">
                            <Card.Title className="card-title">Taxation</Card.Title>
                            <Card.Text className="card-text">All-in-one Tech Solutions for startup Compliance</Card.Text>
                            <Button href="#" className="link-button"> &gt;</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="custom-cards">
                        <div className="image-box">
                            <img src = {Audits} alt = "Audits"/>
                        </div>
                        <Card.Body className="card-bodys">
                            <Card.Title className="card-title">Audits</Card.Title>
                            <Card.Text className="card-text">All-in-one Tech Solutions for startup Compliance</Card.Text>
                            <Button href="#" className="link-button"> &gt;</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="custom-cards">
                        <div className="image-box">
                            <img src ={Parter} alt=" Partner with us"/>
                        </div>
                        <Card.Body className="card-bodys">
                            <Card.Title className="card-title">Partner with Us</Card.Title>
                            <Card.Text className="card-text">All-in-one Tech Solutions for startup Compliance</Card.Text>
                            <Button href="#" className="link-button"> &gt;</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CardContainer;
