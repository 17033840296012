import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const GstTemporaryRegistration = ({ height }) => {

  // GST Temporary Registration grid items
  const items = [
    { title: 'What is GST Temporary Registration?', description: 'GST Temporary Registration allows individuals or businesses to register for GST for a limited period, generally for a specific event or transaction.' },
    { title: 'Who Needs GST Temporary Registration?', description: 'Entities engaging in occasional business activities, like exhibitions or short-term projects, may require GST temporary registration.' },
    { title: 'Validity of Temporary Registration', description: 'Temporary registration is generally valid for a period as specified by the GST authorities, usually tied to the duration of the specific event.' },
    { title: 'Procedure for Temporary Registration', description: 'The process involves submitting relevant details and documentation online via the GST portal, followed by verification by the GST authorities.' },
    { title: 'Compliance for Temporary Registration', description: 'Entities with temporary registration must comply with GST regulations, including filing returns for the specified period.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Short-Term Compliance - Temporary GST registration helps individuals or businesses comply with GST regulations for short-term transactions.",
    "Flexibility - Offers flexibility for entities that do not engage in continuous business but need GST for specific purposes.",
    "No Long-Term Commitment - Since it is temporary, businesses are not bound by long-term compliance obligations.",
    "Efficient Taxation - Ensures that businesses engaging in short-term activities also contribute to the GST system."
  ];

  const disadvantages = [
    "Limited Validity - Temporary registration is only valid for a specific period, requiring renewal or regular registration if the business activity continues.",
    "Compliance Requirements - Temporary registration still requires compliance with GST rules, including filing returns and keeping records.",
    "No Input Tax Credit - Entities with temporary registration may not be eligible to claim input tax credits, affecting profitability."
  ];

  const documents = [
    {
      title: "PAN Card",
      description: "Permanent Account Number (PAN) of the individual or business entity is required for temporary GST registration."
    },
    {
      title: "Proof of Business Activity",
      description: "Documents proving the nature of the business activity, such as contracts, invitations, or event details, must be provided."
    },
    {
      title: "Identity Proof",
      description: "Aadhaar card, passport, or voter ID of the individual or authorized person handling the temporary business activities."
    },
    {
      title: "Address Proof",
      description: "Proof of address for the place of business or activity, such as an electricity bill, rent agreement, or sale deed."
    },
    {
      title: "Digital Signature",
      description: "A digital signature is required for submitting the GST registration application online."
    }
  ];

  const steps = [
    {
      title: "Collect Documents",
      description: "Prepare and collect all necessary documents like PAN, proof of business activity, and address proof."
    },
    {
      title: "Fill Application",
      description: "Complete the online GST temporary registration application on the GST portal with accurate details."
    },
    {
      title: "Submit Application",
      description: "Submit the completed application along with the supporting documents for verification."
    },
    {
      title: "Verification",
      description: "The GST authorities will verify the application and documents. Upon successful verification, temporary registration will be granted."
    },
    {
      title: "Receive GSTIN",
      description: "Once approved, the GSTIN (Goods and Services Tax Identification Number) will be issued for temporary use."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              GST Temporary Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Temporary GST registration is a short-term registration option for entities engaging in occasional business activities or events.
            </p>

            <p>Ensure compliance with GST laws even for temporary business activities with GST Temporary Registration.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>GST Temporary Registration</h6>
          <p className='text-center mt-4'>Temporary GST registration helps entities comply with the law for short-term activities or occasional events.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Temporary Registration?</h6>

            <p className='mb-4'>Temporary registration allows businesses to comply with GST laws for short-term or occasional business activities.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Temporary Registration</h6>
            <p className='mb-4'>Entities engaged in occasional business activities or short-term projects may apply for temporary GST registration.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of GST Temporary Registration</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of GST Temporary Registration</h6>
            <div className="flex flex-wrap justify-between mt-10">
              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for GST Temporary Registration</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for GST Temporary Registration</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default GstTemporaryRegistration;