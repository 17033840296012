import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const DrugLicense = ({ height }) => {

  // Drug License grid items
  const items = [
    { title: 'Legal Compliance', description: 'Ensure compliance with legal requirements for the sale and distribution of drugs.' },
    { title: 'Secure Distribution', description: 'Allows authorized distribution of drugs under a valid license.' },
    { title: 'Public Trust', description: 'Gain public trust by operating a legally compliant pharmacy or drug distribution business.' },
    { title: 'Regulatory Benefits', description: 'Access to benefits and subsidies for drug distribution businesses under the law.' },
    { title: 'Market Expansion', description: 'Expand your market by obtaining approvals for various types of drugs.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Compliance - A drug license ensures that your business is operating within legal regulations.",
    "Public Trust - A valid drug license enhances trust with consumers and health professionals.",
    "Regulatory Support - License holders may receive benefits and support from regulatory authorities.",
    "Market Opportunities - With a drug license, you can expand your business into various segments of the pharmaceutical industry.",
    "Competitive Edge - Holding a drug license sets your business apart from unlicensed competitors."
  ];

  const disadvantages = [
    "Strict Regulations - Businesses must adhere to strict regulations and compliance protocols.",
    "High Initial Investment - The cost of obtaining and maintaining a drug license can be significant.",
    "Renewal and Reporting - Regular renewals and mandatory reporting are required to keep the license valid.",
    "Operational Limitations - License holders may be limited to specific categories of drugs and activities.",
    "Risk of Penalties - Non-compliance can result in hefty fines, penalties, or even license revocation."
  ];

  const documents = [
    {
      title: "Business Registration",
      description: "Proof of business registration and legal status of the applicant entity."
    },
    {
      title: "Pharmacist Certificate",
      description: "Pharmacist certificate or proof of qualification for handling and selling drugs."
    },
    {
      title: "Premises Proof",
      description: "Proof of premises where drugs will be stored or sold, as per regulatory guidelines."
    },
    {
      title: "Affidavit",
      description: "An affidavit stating compliance with the necessary provisions of the drug laws."
    },
    {
      title: "Director's Details",
      description: "Details of the business directors, including identity proof and financial background."
    }
  ];

  const steps = [
    {
      title: "Document Preparation",
      description: "Prepare all the required documents such as business registration, pharmacist certificates, and premises proof."
    },
    {
      title: "Application Submission",
      description: "Submit the drug license application online or in person to the regulatory authority."
    },
    {
      title: "Inspection",
      description: "Authorities may conduct a physical inspection of your premises to ensure compliance with storage and safety regulations."
    },
    {
      title: "Approval Process",
      description: "Once the inspection is complete and the documents are verified, the authorities will process your application."
    },
    {
      title: "Receive License",
      description: "Upon approval, you will receive your Drug License and can begin legally operating your drug business."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Drug License
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Obtain your Drug License to legally sell and distribute pharmaceutical products.
            </p>

            <p>A Drug License is essential for businesses involved in the manufacturing, distribution, or sale of drugs, ensuring legal compliance and safety standards.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Drug License</h6>
          <p className='text-center mt-4'>A Drug License is mandatory for businesses that sell, distribute, or manufacture pharmaceutical drugs in compliance with national and state laws.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Drug License?</h6>

            <p className='mb-4'>A Drug License is issued by the Drug Control Department to regulate the sale, distribution, and manufacturing of drugs. It ensures that the drug business operates in accordance with the legal framework to maintain safety and public health.</p>
          </div>

          <div id="application" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Drug License</h6>
            <p className='mb-4'>To apply for a Drug License, follow these steps:

              <ul>
                <li><strong>Document Preparation -</strong> Gather all required documents such as business registration and pharmacist certificates.</li>

                <li><strong>Application Submission -</strong> Submit the application online or to the Drug Control Department.</li>

                <li><strong>Premises Inspection -</strong> Authorities will inspect the premises to ensure safety and storage compliance.</li>

                <li><strong>License Approval -</strong> Upon successful verification and inspection, you will receive the Drug License.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of a Drug License</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className='AdvantagesLLP'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Advantages of Drug License</h6>
            <ul>
              {advantages.map((advantage, index) => (
                <li key={index}>{advantage}</li>
              ))}
            </ul>
          </div>

          <div className='DisadvantagesLLP'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Disadvantages of Drug License</h6>
            <ul>
              {disadvantages.map((disadvantage, index) => (
                <li key={index}>{disadvantage}</li>
              ))}
            </ul>
          </div>

          <div id="documents" className='docs-req'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Documents Required for Drug License</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {documents.map((doc, index) => (
                <Card
                  key={index}
                  title={doc.title}
                  description={doc.description}
                />
              ))}
            </div>
          </div>

          <div id="process" className='steps w-full flex flex-wrap' >
            <div className='stepsToRegister'>
              <h6 className='text-blue-500 text-lg font-semibold text-center'>Steps to Complete Drug License</h6>
              <ul>
                {steps.map((step, index) => (
                  <li key={index}>
                    <strong>{step.title} -</strong> {step.description}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Drug License?</h6>
            <p>We offer expert guidance throughout the Drug License application process, ensuring compliance with regulatory standards and helping you navigate the complexities of the pharmaceutical industry.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default DrugLicense;