import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TrustRegistration = ({ height }) => {

  // Trust Registration grid items
  const items = [
    { title: 'Legal Framework', description: 'Understand the legal requirements and framework for setting up a trust.' },
    { title: 'Draft Trust Deed', description: 'Prepare a detailed trust deed outlining the trust’s objectives and governance.' },
    { title: 'Trustee Selection', description: 'Select appropriate trustees to manage the trust assets and fulfill its objectives.' },
    { title: 'Registration Process', description: 'Follow the legal process for registering the trust with relevant authorities.' },
    { title: 'Compliance Requirements', description: 'Ensure compliance with ongoing regulatory and reporting obligations.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Tax Benefits - Trusts often offer tax benefits for both the trust and its beneficiaries.",
    "Asset Protection - Provides protection of assets from legal claims and creditors.",
    "Estate Planning - Useful for estate planning and distribution of assets according to wishes.",
    "Charitable Goals - Ideal for setting up charitable trusts to support philanthropic causes.",
    "Privacy - Trusts can offer privacy in terms of asset management and distribution.",
  ];

  const disadvantages = [
    "Complexity - Setting up and managing a trust can be complex and require legal expertise.",
    "Cost - There may be significant costs associated with setting up and maintaining the trust.",
    "Regulatory Compliance - Trusts must comply with various regulations and reporting requirements.",
    "Limited Flexibility - Once established, making changes to the trust can be challenging.",
  ];

  const documents = [
    {
      title: "Trust Deed",
      description: "The primary document outlining the trust’s objectives, rules, and governance."
    },
    {
      title: "Trustee Details",
      description: "Information about the trustees, including their identification and consent."
    },
    {
      title: "Beneficiary Information",
      description: "Details of the beneficiaries who will benefit from the trust."
    },
    {
      title: "Proof of Address",
      description: "Proof of address for the trust, trustees, and beneficiaries."
    },
    {
      title: "KYC Documents",
      description: "Know Your Customer (KYC) documents for verification purposes."
    }
  ];

  const steps = [
    {
      title: "Draft Trust Deed",
      description: "Prepare a detailed deed outlining the trust's purpose, rules, and governance structure."
    },
    {
      title: "Select Trustees",
      description: "Choose reliable individuals or entities to act as trustees for the trust."
    },
    {
      title: "Submit Registration Documents",
      description: "File the trust deed and other required documents with the relevant authorities."
    },
    {
      title: "Obtain Registration Certificate",
      description: "Receive the official certificate of registration from the authorities."
    },
    {
      title: "Compliance and Reporting",
      description: "Ensure compliance with legal requirements and submit regular reports as required."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Trust 
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Trust Registration is a process of establishing a legal entity that manages assets for the benefit of designated beneficiaries. Our service assists in setting up a trust, drafting the trust deed, selecting trustees, and ensuring compliance with legal requirements.
            </p>

            <p>Get professional assistance in setting up a trust that aligns with your objectives.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Trust </h6>
          <p className='text-center mt-4'>Trust Registration involves establishing a trust entity to manage and distribute assets according to specific rules set out in a trust deed. It is essential for asset protection, estate planning, and charitable purposes.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Trust ?</h6>

            <p className='mb-4'>Trust Registration is the process of legally establishing a trust to manage assets for beneficiaries. It involves drafting a trust deed, selecting trustees, and registering the trust with relevant authorities to ensure it meets legal and regulatory requirements.</p>
          </div>

          <div id="application" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Trust Registration</h6>
            <p className='mb-4'>To register a trust, follow these steps:

              <ul>
                <li><strong>Draft Trust Deed -</strong> Prepare a comprehensive deed outlining the trust's objectives and rules.</li>

                <li><strong>Select Trustees -</strong> Choose suitable individuals or entities to act as trustees.</li>

                <li><strong>Submit Registration Documents -</strong> File the deed and other required documents with the relevant authorities.</li>

                <li><strong>Obtain Registration Certificate -</strong> Receive the certificate of registration for the trust.</li>

                <li><strong>Compliance and Reporting -</strong> Ensure ongoing compliance with legal requirements and submit periodic reports.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our Trust Registration Services</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Advantages of Our Trust Registration Services</h6>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Disadvantages of Our Trust Registration Services</h6>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='why-choose mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Required for Trust Registration</h6>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="process" className='how-to-apply mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Steps to Complete Trust Registration</h6>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default TrustRegistration;