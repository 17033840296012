import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ForeignRegistration = ({ height }) => {

  // Foreign Registration grid items
  const items = [
    { title: 'Easy Process', description: 'Streamlined process for foreign nationals to register businesses in the country.' },
    { title: 'Compliance Support', description: 'Assistance with legal and regulatory compliance throughout the registration process.' },
    { title: 'Document Assistance', description: 'Help with gathering and preparing the required documentation for registration.' },
    { title: 'Expert Guidance', description: 'Professional advice on meeting all requirements and ensuring a smooth registration.' },
    { title: 'Timely Service', description: 'Efficient handling of registration to minimize delays and expedite the process.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Streamlined Process - Simplified procedures for foreigners to register their businesses effectively.",
    "Compliance Support - Comprehensive support to ensure adherence to local regulations and legal requirements.",
    "Document Assistance - Help with collecting and preparing all necessary documents for smooth registration.",
    "Expert Guidance - Professional advice and assistance from experienced consultants throughout the registration process.",
    "Timely Service - Efficient handling of the registration process to avoid unnecessary delays."
  ];

  const disadvantages = [
    "Complex Regulations - Navigating local regulations and requirements can be challenging for foreigners.",
    "Documentation - Gathering and preparing the required documents may be time-consuming.",
    "Cultural Differences - Understanding local business practices and cultural norms can be difficult.",
    "Cost - Professional fees and associated costs may be higher for foreigners compared to local businesses.",
    "Legal Challenges - Potential legal hurdles or complications specific to foreign registrations."
  ];

  const documents = [
    {
      title: "Passport Copy",
      description: "A valid copy of the passport for identification and verification purposes."
    },
    {
      title: "Visa Status",
      description: "Proof of visa status or residence permit in the country."
    },
    {
      title: "Business Plan",
      description: "A detailed plan outlining the business objectives and operations."
    },
    {
      title: "Proof of Address",
      description: "Documentation verifying the address of the business and the foreign national."
    },
    {
      title: "Bank Statement",
      description: "Recent bank statements showing financial stability and capacity for investment."
    }
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents required for registration."
    },
    {
      title: "Submit Application",
      description: "Complete and submit the registration application along with the required documents."
    },
    {
      title: "Verification Process",
      description: "Undergo the verification process by the relevant authorities."
    },
    {
      title: "Receive Confirmation",
      description: "Obtain confirmation of the registration and official registration certificate."
    },
    {
      title: "Start Operations",
      description: "Begin business operations once the registration is complete."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Registration for Foreigners
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Simplify the process of business registration in the country with our dedicated services for foreign nationals. We assist with every step to ensure a smooth and efficient registration.
            </p>

            <p>Our expert team provides comprehensive support for foreigners looking to establish their businesses locally, offering guidance and handling all necessary procedures.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Foreign Business Registration Made Easy</h6>
          <p className='text-center mt-4'>Our expert team offers comprehensive services for foreign nationals seeking to register their businesses in the country. From document assistance to compliance support, we ensure a smooth registration process.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Foreign Business Registration?</h6>

            <p className='mb-4'>Foreign Business Registration allows non-residents to establish and operate a business within the country. Our services are designed to streamline the registration process, ensuring compliance with local laws and regulations while providing expert guidance throughout.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To register a business as a foreign national, you need to:

              <ul>
                <li><strong>Valid Passport -</strong> Provide a copy of your valid passport for identification.</li>

                <li><strong>Visa Status -</strong> Proof of your visa status or residence permit in the country.</li>

                <li><strong>Business Plan -</strong> A detailed business plan outlining your objectives and operations.</li>

                <li><strong>Proof of Address -</strong> Documentation verifying your address and that of the business.</li>

                <li><strong>Bank Statement -</strong> Recent bank statements to demonstrate financial stability.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Foreign Business Registration</h6>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="pros-cons" className='pl-16 mt-16'>
            <div className='AdvantagesLLP'>
              <h2>Advantages of Foreign Business Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Foreign Business Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Register Your Business</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Your Foreign Business Registration?</h6>
            <p>Our dedicated team provides expert assistance with the registration process for foreign nationals. We offer personalized support to help you navigate the complexities of local regulations and ensure a successful registration.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default ForeignRegistration;