import React, { useState } from "react";
import "./ContactUs.css";
import SendEmail from "../Api/SendEmail";

const BlogsNArticles = () => {
  const [emailInput, setEmailInput] = useState(null);
  const [stage,setStage]=useState("");

  const checkValidations = (emailAddress) => {
    if (emailAddress === "") return false;
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (regex.test(emailAddress)) return true;
    return false;
  };

  const HandleFormSubmit = async(event) => {
    event.preventDefault();
    if (checkValidations(emailInput))
        { 
            setStage("Subscribing Please Wait....")
           SendEmail(emailInput).then(response=>{
            setStage("Subscribed Successfully")
           }).catch(error=>{
            setStage("Failed to Subscribe , Try Again!!")
           }
           )
        }
        else
        setStage("Please Enter valid Email Address")
  };

  return (
    <div className="ContactUs-container" id="contact-us">
      <div className="Firsthead">
        <h3>Blogs</h3>
        <p>Subscribe to get latest Finance related blogs instantly!</p>
      <form className="flex flex-row w-[40%] h-[10vh] justify-center">
        <div className="flex flex-row flex-wrap justify-center w-3/4">
          <input
            className="outline-none rounded-md px-2 py-4 border-1 w-[65%] h-[10vh]"
            placeholder="Enter your Email"
            type="email"
            name="email"
            id="email"
            value={emailInput}
            onChange={(event) => {
              setEmailInput(event.target.value);
            }}
          />
        </div>

        <div className="w-[35%] h-full">
          <button
            className="text-white h-full rounded-md bg-blue-900 px-4"
            onClick={HandleFormSubmit}
            type="submit"
          >
            Subscribe
          </button>
        </div>

        {
    (stage.includes("valid") || stage.includes("Failed"))?
    <span className="text-red-500">{stage}</span>
    :
    stage.includes("Subscribing")?
    <span className="text-yellow-400">{stage}</span>  
    :
    <span className="text-green-500">{stage}</span>  
        }

      


      </form>
      </div>
    </div>
  );
};

export default BlogsNArticles;
