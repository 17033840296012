import React from 'react';  
import { Container, Row, Col , Button } from 'react-bootstrap'; 
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';
import LLPIcon from "./iconsweb p/LLP_Icon.webp"
import { useNavigate } from 'react-router-dom';

const SecondaryNavbar = ({height}) => {  

  const navigate=useNavigate();
  
  // card grid item
  const items = [  
    { title: 'Distinct Legal Identity', description: 'Operates as a separate entity from its owners.' },  
    { title: 'Cost Efficient', description: 'Lower setup and maintenance costs compared to companies.' },  
    { title: 'Flexible Partner Structure', description: 'Requires at least one designated partner with no cap on the total number of partners.' },  
    { title: 'Limited Liability', description: 'Partners are only liable for their contributions.' },  
    { title: 'Less Regulation', description: 'Fewer compliance requirements than larger entities.' },  
  ];  
      // Advantages and Disadvantages
  const advantages = [  
    "Independent Legal Status - Can operate independently and undertake legal actions.",  
    "Protected Liability - Partner's personal assets are safeguarded against business liabilities.",  
    "Affordable Setup - Lower initial and ongoing costs with reduced regulatory burdens.",  
    "Flexible Capital Contribution - No fixed minimum capital requirement."  
  ];  

  const disadvantages = [  
    "Regulatory Compliance - Penalties for failing to meet regulatory requirements.",  
    "Operational Risks - Must cease operations if fewer than two partners remain or if it cannot settle its debts.",  
    "Investment Limitations - Challenging to attract large-scale investments compared to public companies."  
  ];  

  const documents = [  
    {  
        title: "Proof of Registered Office Address",  
        description: "Includes rent agreement and utility bills."  
    },  
    {  
        title: "Address Proof",  
        description: "Recent documents like bank statements or utility bills."  
    },  
    {  
        title: "Residential Proof",  
        description: "Recent documents like bank statements or utility bills."  
    },  
    {  
        title: "Passport-size Photos",  
        description: "With a white background."  
    },  
    {  
        title: "PAN Card/ID Proof",  
        description: "For all partners."  
    },  
    {  
        title: "Digital Signature Certificate (DSC)",  
        description: "Required for at least one designated partner."  
    }  
];  


const steps = [  
  {  
      title: "Get a Digital Signature Certificate (DSC)",  
      description: "Required for all partners."  
  },  
  {  
      title: "Apply for Director Identification Number (DIN)",  
      description: "For all designated partners."  
  },  
  {  
      title: "Select and Reserve Your LLP Name",  
      description: "Ensure it meets MCA guidelines."  
  },  
  {  
      title: "Complete Form FiLLiP",  
      description: "Submit information about the LLP, partners, and registered office."  
  },  
  {  
      title: "Draft and File LLP Agreement",  
      description: "Must be notarized and filed within 30 days of incorporation."  
  },  
  {  
      title: "Receive Certificate of Incorporation",  
      description: "Issued by the Registrar of Companies (RoC)."  
  },  
  {  
      title: "Apply for PAN and TAN",  
      description: "Essential for tax purposes."  
  }  
];  


  return (  
    <>
    <div className='sticky z-20' style={{ top: `${height}px` }}>
      <nav className="bg-blue-900 h-[15%] w-screen">  
          <div className="flex flex-row flex-wrap justify-around items-center">  
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>  
            <a href='#el'  className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>  
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>  
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>  
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation </a>  
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>  
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>   
          </div>  
      </nav> 
      </div>
 

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',  
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',  
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent" 
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
            Limited Liability Partnership(LLP)
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
            A Limited Liability Partnership (LLP) is a hybrid business structure that combines elements of both partnerships and corporations.
            </p>

            <p>10K+ Trusted Companies Successfully Registered</p>

            <Button className='btncon'
                        style={{
                            width: 'fit-content',
                            backgroundColor: '#125B9A',
                            marginTop: '10px'
                        }}
                        onClick={()=>navigate("/contact-us")}
                        >
                        Get in touch&gt;
                    </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
                    style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}>
                    <div style={{
                        width: '632px',
                        height: '420px',
                        marginBottom:'35px',  
                        borderRadius: '8px',
                        
                    }}
                    className='object-contain'
                    >
                      <img src={LLPIcon} alt={"LLP icon"} style={{borderRadius: '10px'}} />
                    </div>
                    </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
      <div  id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
        <h6 className='text-2xl text-center font-semibold'>LLP Registration Made Simple with dialT2F</h6>
        <p className='text-center mt-4'>Are you ready to set up a business that offers both flexibility and legal protection? Look no further than a Limited Liability Partnership (LLP).
        With LLPs, you get the best of both worlds— 
        the collaborative spirit of partnerships combined with the security of limited liability.
        At dialT2F, we make LLP registration in India straightforward and efficient. 
        Our experts will guide you through every step, ensuring a smooth process from start to finish. 
        Contact us today to begin your journey toward a successful LLP.
        </p>
    </div>

    <div  className='LLP-container w-auto mt-4 flex flex-wrap' >

    <div className='pl-16 pr-96 pt-5 ' >
        <h6 className='text-blue-500 text-lg font-semibold'>What is LLP?</h6>

        <p className='mb-4'>A Limited Liability Partnership (LLP) is a modern business structure that merges the benefits of a partnership with the security of a company. 
            Here’s what sets LLPs apart:</p>
            <div>
            <ul><strong>Legal Entity:</strong> Like a company, an LLP is a separate legal entity.
            This means it can enter into contracts, own property, and take legal actions independently.</ul>

            <ul><strong>Limited Liability:</strong> Partners are only liable for their own actions and contributions, 
            keeping their personal assets safe.
            </ul>

            <ul><strong>Operational Flexibility:</strong> LLPs offer the ease of partnership operations with the added advantages of a corporate structure.</ul> 
            </div>
            
        <p>Introduced in India in 2008 under the Limited Liability Partnership Act, LLPs are a versatile option for businesses of all sizes.</p>
    </div>

    <div id="el" className='pl-16 pr-96 mt-16 ' >
        <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
        <p className='mb-4'>To register an LLP in India, you need to meet the following criteria:

            <ul>
            <li><strong>Minimum 2 Partners -</strong> An LLP must have at least two partners, with no maximum limit.</li>  

            <li><strong>Designated Partners -</strong> At least two designated partners must be individuals, and one must be an Indian resident.
            </li>  

            <li ><strong>Authorized Capital -</strong>An LLP should have a minimum authorized capital of Rs. 1 lakh</li>
            <li ><strong>Corporate Partners -</strong> If a corporate body is involved, a natural person must represent it.</li>
            </ul>
            </p>
    </div>

    <div className='pl-16 pr-96 mt-16 ' >
        <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Feature of LLP</h6>
        <div className="card-grid">  
      {items.map((item, index) => (  
        <Card style={{backgroundColor:'#C0DEF7'}} key={index} title={item.title} description={item.description} />  
      ))}  
    </div> 
    </div>

    <div id='pro/con'className='pros'>
    <div className='AdvantagesLLP pl-16 mt-16'>
    <h2>Advantages of an LLP</h2>  
      <ul>  
        {advantages.map((advantage, index) => (  
          <li key={index} className="advantage-item">  
            <span className="checkmark">✔️</span> {advantage}  
          </li>  
        ))}  
      </ul>  
    </div>

    <div className='Disadvantages mt-4'>
    <h2>Disadvantages of an LLP</h2>  
      <ul>  
        {disadvantages.map((disadvantage, index) => (  
          <li key={index} className="disadvantage-item">  
            <span className="crossmark">❌</span> {disadvantage}  
          </li>  
        ))}  
      </ul>  
    </div>
    </div>
    </div>

    <div id='why'className='secondLLP pr-96 pl-16 mt-16'>
      <div className='Choosing'>
      <h6 className='text-blue-500 text-lg font-semibold'>Choosing your LLP Name</h6>
      <ul className='space-x-4'>
            <li><strong>Unique and Available - </strong>  Ensure your LLP name is unique and not already in use.</li>  

            <li><strong>Descriptive - </strong> Reflects your business activities clearly.</li>  

            <li ><strong>Suffix Requirement -</strong>Must end with “LLP” or “Limited Liability Partnership</li>
          </ul>
      </div>

      <div className='Documents'>
      <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for LLP Registration</h6>
      <p>Here’s what you’ll need:</p>   
            <div className="card-grid">  
                {documents.map((doc, index) => (  
                    <Card key={index} title={doc.title} description={doc.description} />  
                ))}  
            </div>  
        </div>

      <div id='how' className='Howto mt-16'>
      <div className="registration-steps">  
            <h2>How to Register</h2>  
            <p>Follow these steps for a smooth registration process:</p>  
            <div className="card-grid">  
                {steps.map((step, index) => (  
                    <Card   
                        key={index}   
                        title={`${index + 1}. ${step.title}`}   
                        description={step.description}   
                    />  
                ))}  
            </div>  
        </div>  
      </div>

      <div id='choose' className='Why mt-12'>
      <h6 className='text-blue-500 text-lg font-semibold'>Why Choose dialT2F for your LLP Registration</h6>
      <p>dialT2F is your trusted partner for LLP registration:</p>
      <ul className='space-x-4'>
            <li><strong>LLP Agreement Drafting - </strong>  Expert help with drafting and filing your LLP agreement.</li>  

            <li><strong>Name Reservation - </strong>  We’ll help you secure your desired LLP name.</li>  

            <li ><strong>Expert Support -</strong>Our experienced team ensures a smooth registration process.</li>

            <li ><strong>Ongoing Assistance -</strong>We provide updates and support throughout your registration journey.</li>

            <li ><strong>DSC and DIN Assistance -</strong>We facilitate obtaining necessary digital signatures and identification numbers.</li>
            
            <li ><strong>PAN and TAN Application -</strong>Simplified application process for tax IDs.</li>
          </ul>
      </div>
    </div>
    </div>
    <ScrollToTopButton/>
    </>
  );  
};  

export default SecondaryNavbar;
