import React from 'react';
import T2Flogo from './T2F png icons/Group.png';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube,  } from 'react-icons/fa';
import { AiOutlineGlobal } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import LegalPages from './LegalP';


const LastFooter = () => {

    const handleClick=(event)=>{
        
        navigate(`/privacy-policy/${event.target.id}`)
    }

    const navigate=useNavigate();

    return (
        <div className='divBoss flex flex-col flex-wrap w-full text-white' style={{"background":"#1A3254"}}>
            <div className="flex flex-row flex-wrap icon ml-[2%] mt-2 w-[12%] mb-10 items-center justify-evenly">
                <img className='mb-2' src={T2Flogo} alt="" />
                <span className='text-2xl font-bold'>T2F</span>
            </div>
            <div className='div1 flex flex-col w-full justify-stretch flex-wrap'>
                <div className='div2 flex flex-row w-full justify-around flex-wrap'>
                    <div className='div3 flex flex-row flex-wrap'>
                        <div className='div8 flex flex-wrap flex-col mr-3'>
                            <ul className='font-bold mb-3'>Company Service</ul>
                            <ul className='mb-2'>Form a Business</ul>
                            <ul className='mb-2'>Registrations</ul>
                            <ul className='mb-2'>Intellectual Property</ul>
                            <ul className='mb-2'>Business Tools/Software</ul>
                            <ul className='mb-2'>Audits</ul>
                        </div>
                        <div className='div9 flex flex-wrap flex-col'>
                            <ul className='font-bold mb-3 invisible'>Company Service</ul>
                            <ul className='mb-2 cursor-pointer'>Compliance</ul>
                            <ul className='mb-2 cursor-pointer'>Taxation</ul>
                            <ul className='mb-2 cursor-pointer'>Services</ul>
                            <ul className='mb-2 cursor-pointer'>Partner with Us</ul>
                        </div>
                    </div>

                    <div className='div4 flex flex-wrap flex-col'>
                        <ul className='font-bold mb-3'>Newsroom</ul>
                        <ul className='mb-2 cursor-pointer'>
                            Blogs & Articles</ul>
                        <ul className='mb-2 cursor-pointer'>Casestudies</ul>
                        <ul className='mb-2 cursor-pointer'>Tax Tools</ul>
                        <ul className='mb-2 cursor-pointer'>
                            <a href="#faqq" className='no-underline text-white'>FAQs</a>
                        </ul>
                    </div>

                    <div className='div5 flex flex-wrap flex-col'>
                        <ul className='font-bold mb-3'>We Believe</ul>
                        <ul className='mb-2 cursor-pointer'>Overview</ul>
                        <ul className='mb-2 cursor-pointer'>Careers</ul>
                        <ul className='mb-2 cursor-pointer' onClick={
                            ()=>{
                                navigate("/contact-us")
                            }
                        }>Contact Us</ul>
                    </div>
                    <div className='div6 flex flex-wrap flex-col'>
                        <ul className='font-bold mb-3'>Legal & Admin</ul>
                         <ul className='mb-2 cursor-pointer' id="0" onClick={handleClick}>Privacy Policy</ul>
                        <ul className='mb-2 cursor-pointer' id="2" onClick={handleClick}>Terms of service</ul>
                        <ul className='mb-2 cursor-pointer' id="1" onClick={handleClick}>Refund Policy</ul>
                        <ul className='mb-2 cursor-pointer' id="3" onClick={handleClick}>Disclaimer</ul>
                    </div>
                </div>

                <hr />

        <div className='div7 flex flex-wrap flex-row mt-3 justify-between'>
            <div className='div8 flex flex-row flex-wrap w-1/2 justify-evenly'>
            <Link to="https://www.linkedin.com/" className='text-white' target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn className='text-2xl'/>
            </Link>
            <Link to="https://twitter.com/"  className='text-white' target="_blank" rel="noopener noreferrer">
                <FaTwitter className='text-2xl'/>
            </Link>
            <Link to="https://www.facebook.com/" className='text-white' target="_blank" rel="noopener noreferrer">
                <FaFacebook className='text-2xl'/>
            </Link>
            <Link to="https://www.instagram.com/" className='text-white' target="_blank" rel="noopener noreferrer">
                <FaInstagram className='text-2xl'/>
            </Link>
            <Link to="https://www.youtube.com/"  className='text-white' target="_blank" rel="noopener noreferrer">
                <FaYoutube className='text-2xl'/>
            </Link>
            </div>

            <div className='div9 w-1/5 flex flex-row flex-wrap'>
            <AiOutlineGlobal className='text-2xl'/>
            &nbsp;
            &nbsp;
            <span>English</span>
            </div>
            
        </div>
        </div>
        </div>
    );
};

export default LastFooter;