import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const MOAAmendment = ({ height }) => {

  // MOA Amendment grid items
  const items = [
    { title: 'Name Change', description: 'Update your company’s name in the MOA to reflect new branding or legal requirements.' },
    { title: 'Address Update', description: 'Amend your registered office address in the MOA for accurate correspondence.' },
    { title: 'Change in Objectives', description: 'Modify the objectives of your company as per new business activities or strategic goals.' },
    { title: 'Share Capital Adjustment', description: 'Revise the share capital details in the MOA to align with current financial structure.' },
    { title: 'Directors Update', description: 'Update the details of directors in the MOA as per the latest appointments or resignations.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Compliance - Ensures that your company's MOA is in line with legal requirements and current business operations.",
    "Accurate Records - Maintains accurate and updated records for official and regulatory purposes.",
    "Improved Branding - Allows for changes in company name or objectives to align with new branding strategies.",
    "Financial Alignment - Updates share capital details to reflect the current financial status of the company.",
    "Enhanced Transparency - Keeps stakeholders informed with accurate information about the company’s directors and business activities."
  ];

  const disadvantages = [
    "Cost Implications - Amendments may incur costs related to legal and administrative fees.",
    "Time-Consuming - The process of amending the MOA can be time-consuming and may affect business operations temporarily.",
    "Regulatory Procedures - Requires adherence to regulatory procedures which can be complex and require professional assistance.",
    "Possible Delays - Processing time for MOA amendments can vary, leading to potential delays in reflecting changes."
  ];

  const documents = [
    {
      title: "Resolution Copy",
      description: "A copy of the board resolution or shareholder resolution approving the MOA amendment."
    },
    {
      title: "Amended MOA",
      description: "The draft of the amended Memorandum of Association reflecting the proposed changes."
    },
    {
      title: "Company Registration Certificate",
      description: "The existing certificate of incorporation or registration of the company."
    },
    {
      title: "Identity Proof",
      description: "Identity proof of the company’s directors or authorized signatories for verification purposes."
    },
    {
      title: "Proof of Address",
      description: "Proof of the new address if it involves a change in the registered office."
    }
  ];

  const steps = [
    {
      title: "Draft Amendments",
      description: "Prepare the draft of the amended MOA including all proposed changes."
    },
    {
      title: "Board Resolution",
      description: "Obtain approval for the amendments through a board or shareholders’ resolution."
    },
    {
      title: "File with Authorities",
      description: "Submit the amended MOA and required documents to the relevant regulatory authorities."
    },
    {
      title: "Update Records",
      description: "Update all company records and registers to reflect the changes made in the MOA."
    },
    {
      title: "Communicate Changes",
      description: "Inform stakeholders and relevant parties about the amendments to ensure transparency."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Streamline Your MOA Amendments with Us
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Need to amend your Memorandum of Association (MOA)? We offer comprehensive services to help you update your company's MOA efficiently and in compliance with legal requirements.
            </p>

            <p>Our expert team ensures that your MOA amendments are processed smoothly, minimizing disruptions to your business.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Efficient MOA Amendments for Your Company</h6>
          <p className='text-center mt-4'>Our MOA amendment services ensure that your company’s Memorandum of Association is updated accurately to reflect necessary changes. From name changes to address updates, we handle it all.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is MOA Amendment?</h6>

            <p className='mb-4'>MOA Amendment involves making changes to the Memorandum of Association (MOA) of a company. This could include updates to the company’s name, address, objectives, share capital, or director details, in compliance with legal and business requirements.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for MOA Amendment</h6>
            <p className='mb-4'>To be eligible for MOA amendment, your company must meet the following criteria:

              <ul>
                <li><strong>Board Approval -</strong> The amendments must be approved by the company’s board of directors or shareholders.</li>

                <li><strong>Valid Reasons -</strong> There must be valid reasons for the amendments, such as changes in business operations or legal requirements.</li>

                <li><strong>Documentary Evidence -</strong> Relevant documents supporting the need for amendments must be provided.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of MOA Amendment Services</h6>

            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of MOA Amendment</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of MOA Amendment</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for MOA Amendment</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps for MOA Amendment</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for MOA Amendments?</h6>
            <p>Our team of experts ensures that your MOA amendments are handled with precision and efficiency. We assist you through every step of the process, making sure your company’s records are up-to-date and compliant with regulations.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default MOAAmendment;