import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import LastFooter from "./Lastfooter";
import T2Flogo from './T2F png icons/Group.png';
import './Footer.css';
import OtpModal from "./OtpPopup";
import fImage from './iconsweb p/T2F Homepage footer.png'

function Footer() {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <div className="Footer-container">
            {/* <div className="footer-main-section">
                <div className="image-footer">
            <img className="h-[100%]" src={fImage} alt="footerImage"/>

                </div>
                <div className="text-main">
                    <h4> Elevate Your Business with T2F</h4>
                    <p style={{ fontWeight: '400', fontSize: '18px', lineHeight: '30px' }}>
                        Seamlessly register, manage taxes, and ensure compliance with our expert, for transparent services.
                    </p>
                    <Button
                        className='btncon'
                        style={{
                            width: '165px',
                            height: '48px',
                            borderRadius: '8px',
                            backgroundColor: '#125B9A',
                            padding: '12px',
                            gap: '15px'
                        }}
                        onClick={handleShow}
                    >
                        Get Started &gt;
                    </Button>
                </div>
            </div> */}

<div className="flex flex-col md:flex-row items-center justify-between p-6 bg-blue-100">  
      <div className="flex-1 mb-4 md:mb-0">  
        <div className="w-full h-64 box-content rounded-lg">
            <img className="h-full" src={fImage} alt="name"/>
            </div>  
      </div>  
      <div className="flex-1 md:ml-6">  
        <h1 className="text-2xl font-bold text-gray-800">  
          Elevate Your Business with Tax2free  
        </h1>  
        <p className="mt-2 text-gray-600">  
          Seamlessly register, manage taxes, and ensure compliance with our expert, transparent services.  
        </p>  
        <button className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200">  
          Get Started &gt; 
        </button>  
      </div>  
    </div>

            <div className="Footer-Content">
            <LastFooter/>
</div>
            {/* OTP Modal */}
            {showModal && (
                <OtpModal
                    showModal={showModal}
                    handleClose={handleClose}
                />
            )}
        </div>
    );
};

export default Footer;