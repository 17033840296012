import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import right from './T2F png icons/right.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import TickText from "./TickText";
import OtpModal from "./OtpPopup";
import HeroImage from "./iconsweb p/T2f Homepage Hero Image.webp"
function Maincontent() {

    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    return (
        <Container fluid className="main-content-container"
            style={{
                background: ' #E3EEFB',
                minHeight: '470px',  
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '90px',  // Push the content below the navbar
            }}>
            <Row className="w-100 h-100">
                <Col md={6} xs={12} className="textcontent" 
                    style={{
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'left'
                    }}>
                    <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
                        Your trusted partner for business success, at no cost
                    </h1>
                    <p style={{ marginTop: '10px', fontWeight:'500' ,fontSize: '18px' , lineHeight:'30px' }}>
                        <Link to="dialT2F" style={{textDecoration:'none', color:'#125B9A'}}>dialT2F </Link> offers a streamlined platform where users can complete every step to build their business,
                        <Link to="pricing" style={{textDecoration:'none', color:'#125B9A'}}>simple and affordably</Link> 
                    </p>

                    <div className="smallhead flex flex-row flex-wrap " >
                    <TickText image={right} text={"Timely reporting"}/>
                    <TickText image={right} text={"Advanced Tech & Experts"}/>
                    <TickText image={right} text={"Innovative Solutionns"}/>
                    </div>

                    <Button className='btncon'
                        style={{
                            width: 'fit-content',
                            backgroundColor: '#125B9A',
                            marginTop: '10px'
                        }}
                        onClick={handleShow}
                        >
                        Get free Assistance &gt;
                    </Button>
                </Col>
                <Col md={6} xs={12} className="image-space"
                    style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}>
                    <div style={{
                        width: '632px',
                        height: '420px',
                        marginBottom:'35px',
                        backgroundColor: '#C6D9F1',  
                        borderRadius: '8px',

        
                    }}>
                        <img src={HeroImage} alt="homepage"/>
                    </div>
                </Col>
            </Row>
            {/* Render the OTP Modal */}
            <OtpModal showModal={showModal} handleClose={handleClose} />
        </Container>
    );
};

export default Maincontent;
