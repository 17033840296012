import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const EWayBills = ({ height }) => {

  // E-way Bills grid items
  const items = [
    { title: 'Simplified Transport', description: 'E-way bills streamline the transport process by providing a digital waybill for goods movement.' },
    { title: 'Reduced Paperwork', description: 'Minimizes the need for physical documentation, reducing administrative burden.' },
    { title: 'Compliance Tracking', description: 'Facilitates compliance with GST regulations by ensuring proper documentation of goods movement.' },
    { title: 'Enhanced Security', description: 'Provides a secure and tamper-proof method of tracking goods in transit.' },
    { title: 'Real-Time Updates', description: 'Offers real-time tracking and updates of goods during transportation for better management.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Simplified Transport - E-way bills provide a digital solution for transporting goods, streamlining the entire process.",
    "Reduced Paperwork - Reduces the amount of physical paperwork required, leading to less administrative work and fewer errors.",
    "Compliance Tracking - Ensures compliance with GST regulations by maintaining accurate records of goods movement.",
    "Enhanced Security - Offers secure and tamper-proof tracking of goods during transportation.",
    "Real-Time Updates - Provides real-time tracking and updates, improving logistics and management.",
  ];

  const disadvantages = [
    "System Dependency - Requires a stable internet connection and system access for generating and managing e-way bills.",
    "Technical Issues - Potential technical issues with the e-way bill system can disrupt the process of generating or verifying bills.",
    "Compliance Challenges - Ensuring compliance with evolving regulations and maintaining accurate data can be challenging.",
    "User Training - May require training for staff to effectively use the e-way bill system and understand its features.",
  ];

  const documents = [
    {
      title: "Invoice Details",
      description: "Detailed invoice information for the goods being transported, including quantities and values."
    },
    {
      title: "GST Details",
      description: "GST details related to the goods and services to ensure accurate e-way bill generation."
    },
    {
      title: "Transporter Information",
      description: "Information about the transporter handling the goods, including their registration details."
    },
    {
      title: "Goods Details",
      description: "Details of the goods being transported, such as description, quantity, and value."
    },
    {
      title: "Vehicle Details",
      description: "Details of the vehicle used for transportation, including vehicle number and driver information."
    }
  ];

  const steps = [
    {
      title: "Gather Required Information",
      description: "Collect all necessary details including invoice, GST, and transporter information."
    },
    {
      title: "Generate E-Way Bill",
      description: "Use the e-way bill system to create a digital waybill by entering the required details."
    },
    {
      title: "Verify Details",
      description: "Ensure that all information entered is accurate and complies with regulations before finalizing the e-way bill."
    },
    {
      title: "Print E-Way Bill",
      description: "Print the generated e-way bill and attach it to the goods being transported."
    },
    {
      title: "Maintain Records",
      description: "Keep records of generated e-way bills and related documentation for compliance and future reference."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              E-Way Bill Solutions
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              E-way bills are crucial for the seamless movement of goods across state borders. Our solutions simplify the e-way bill process, ensuring compliance and efficiency in your logistics operations.
            </p>

            <p>Transform your logistics with our streamlined e-way bill solutions designed to enhance your supply chain management.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>E-Way Bills Overview</h6>
          <p className='text-center mt-4'>E-way bills offer an efficient way to manage the transportation of goods across state borders. Our service ensures you can handle e-way bills seamlessly, complying with regulations and enhancing your logistics efficiency.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is an E-Way Bill?</h6>

            <p className='mb-4'>An e-way bill is a digital document required for the movement of goods exceeding a certain value. It serves as proof of compliance with GST regulations and facilitates the tracking and verification of goods in transit.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for E-Way Bills</h6>
            <p className='mb-4'>To generate an e-way bill, you need to:

              <ul>
                <li><strong>Invoice Details -</strong> Information about the goods being transported, including values and quantities.</li>

                <li><strong>GST Details -</strong> GST details related to the goods to ensure accurate e-way bill generation.</li>

                <li><strong>Transporter Information -</strong> Details of the transporter handling the goods.</li>

                <li><strong>Goods Details -</strong> Detailed description of the goods being transported.</li>

                <li><strong>Vehicle Details -</strong> Information about the vehicle used for transportation.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of E-Way Bills</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of E-Way Bills</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of E-Way Bills</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for E-Way Bills</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Generate E-Way Bills</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for E-Way Bills?</h6>
            <p>Our e-way bill services offer ease of use, comprehensive compliance, and enhanced management for your logistics operations. Partner with us to streamline your e-way bill processes and ensure smooth transport operations.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default EWayBills;