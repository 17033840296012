import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ITReturn3 = ({ height }) => {

  // IT-Return 3 grid items
  const items = [
    { title: 'Business Income Reporting', description: 'Declare your income from any business or profession you engage in.' },
    { title: 'Income from Partnerships', description: 'Report your income from partnerships, LLPs, or other business entities.' },
    { title: 'Presumptive Taxation', description: 'Opt for simplified tax computations under presumptive taxation schemes.' },
    { title: 'House Property and Capital Gains', description: 'Declare income from house property and capital gains.' },
    { title: 'Complete E-Filing Support', description: 'Seamless e-filing of IT-Return 3 with accurate calculations.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Business Income - IT-Return 3 allows for comprehensive reporting of business or professional income.",
    "Presumptive Taxation - Benefit from simplified taxation schemes if you qualify for presumptive taxation.",
    "Tax Deductions - Claim all applicable deductions to minimize tax liabilities.",
    "Multiple Income Sources - Declare income from business, partnerships, and other sources.",
    "Online Filing - File your IT-Return 3 online, accurately and on time."
  ];

  const disadvantages = [
    "More Detailed - IT-Return 3 requires comprehensive documentation of business income and deductions.",
    "Time-Consuming - Filing may take time due to the complexity of reporting business income.",
    "Late Fees - Delayed filing of IT-Return 3 can attract penalties and interest.",
    "Documentation - Extensive documentation for business income, partnerships, and assets is needed.",
    "Complex Rules - Staying up-to-date with business tax regulations and changes can be challenging."
  ];

  const documents = [
    {
      title: "Business Income Documents",
      description: "Proof of income from business or profession, such as profit and loss statements."
    },
    {
      title: "Partnership Documents",
      description: "Documents related to income from partnerships or LLPs."
    },
    {
      title: "Form 16A",
      description: "Certificate for tax deducted at source on business income."
    },
    {
      title: "Capital Gains Documents",
      description: "Documents related to the sale of assets or properties."
    },
    {
      title: "Property Documents",
      description: "Documents for income from house property, if applicable."
    }
  ];

  const steps = [
    {
      title: "Gather Business and Other Income Documents",
      description: "Collect all necessary documents related to your business, partnerships, and house property."
    },
    {
      title: "Calculate Total Income",
      description: "Include income from all sources like business, partnerships, capital gains, and property."
    },
    {
      title: "Apply Eligible Deductions",
      description: "Maximize your tax savings by applying all eligible deductions for business expenses."
    },
    {
      title: "Pay Self-Assessment Tax",
      description: "Ensure all due taxes are paid on time to avoid penalties."
    },
    {
      title: "File IT-Return 3 Online",
      description: "File your return online accurately, ensuring that all business income is reported correctly."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              IT-Return 3
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              File your IT-Return 3 accurately by declaring income from business, partnerships, house property, and capital gains.
            </p>

            <p>IT-Return 3 is suited for individuals with income from business or profession, as well as partnerships and property. Our service ensures compliance and tax optimization.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainITReturn w-full flex flex-wrap'>
        <div id="overview" className="ITReturnRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>IT-Return 3 Filing Made Simple</h6>
          <p className='text-center mt-4'>Get expert guidance to accurately file IT-Return 3, declaring income from business, partnerships, and property, and optimizing deductions for tax savings.</p>
        </div>

        <div className='ITReturn-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-96 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is IT-Return 3?</h6>

            <p className='mb-4'>IT-Return 3 is intended for individuals earning income from business or profession, partnerships, capital gains, and house property. It allows for complete reporting and tax deductions.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-96 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To file IT-Return 3, the following documents and eligibility criteria are required:

              <ul>
                <li><strong>Business Income Documents -</strong> Profit and loss statements, balance sheets, and other related documents.</li>

                <li><strong>Partnership Income Proof -</strong> Income from partnerships, LLPs, or other business entities.</li>

                <li><strong>Capital Gains Documents -</strong> Proof of sale of assets like property or shares.</li>

                <li><strong>Property Documents -</strong> Documents related to house property income, if applicable.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of IT-Return 3</h6>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="pros-cons" className='flex flex-wrap pl-16 pr-96 mt-16'>
            <div className='AdvantagesLLP'>
              <h2>Advantages of Filing IT-Return 3</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Filing IT-Return 3</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for IT-Return 3</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to File IT-Return 3</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Filing IT-Return 3?</h6>
            <p>Our team of professionals will guide you through every step of filing your IT-Return 3, ensuring accuracy, tax savings, and complete compliance.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default ITReturn3;