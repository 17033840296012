import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TrademarkObjection = ({ height }) => {

  // Trademark Objection grid items
  const items = [
    { title: 'Understand the Objection', description: 'Comprehend the nature of the objection raised against your trademark application, including the grounds and reasons cited.' },
    { title: 'Prepare a Response', description: 'Draft a detailed response to address the objections, providing evidence and arguments to support the registration of your trademark.' },
    { title: 'Submit Response', description: 'File your response with the trademark office within the stipulated time frame to contest the objection and proceed with the application.' },
    { title: 'Monitor Progress', description: 'Track the status of your objection response and any further actions required, such as attending hearings or providing additional information.' },
    { title: 'Seek Legal Advice', description: 'Consider consulting with a trademark attorney to navigate complex objections and improve your chances of a successful outcome.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Chance to Address Issues - Provides an opportunity to rectify any issues or objections against your trademark application.",
    "Legal Representation - Allows for professional representation and advice to handle objections effectively.",
    "Protect Your Brand - Ensures that your trademark application is thoroughly reviewed and defended to protect your brand rights.",
    "Preserves Application - Helps in continuing the trademark registration process despite initial objections.",
    "Resolution of Disputes - Resolves conflicts and objections, allowing for a clearer path to trademark registration.",
  ];

  const disadvantages = [
    "Complex Process - Handling objections can be complex and may require extensive legal knowledge and documentation.",
    "Cost - Engaging legal counsel and preparing responses can incur additional costs.",
    "Time-Consuming - The process of responding to objections and monitoring progress can be time-consuming.",
    "Risk of Rejection - There is a risk that the objection may not be resolved in your favor, potentially leading to rejection.",
    "Additional Requirements - May require additional information or amendments to your application, complicating the process.",
  ];

  const documents = [
    {
      title: "Objection Notice",
      description: "The official notice from the trademark office outlining the grounds for the objection against your trademark application."
    },
    {
      title: "Response Document",
      description: "Your detailed response addressing the objections, including arguments, evidence, and any supporting documents."
    },
    {
      title: "Supporting Evidence",
      description: "Evidence and documentation supporting your case, such as proof of use, distinctiveness, or other relevant materials."
    },
    {
      title: "Legal Correspondence",
      description: "Any correspondence with legal counsel or the trademark office related to the objection and response process."
    },
    {
      title: "Hearing Documents",
      description: "Documents required if a hearing is scheduled to discuss the objection and your response in person."
    }
  ];

  const steps = [
    {
      title: "Review Objection Notice",
      description: "Examine the notice of objection to understand the specific grounds and reasons for the objection."
    },
    {
      title: "Prepare Detailed Response",
      description: "Draft a comprehensive response addressing each point of the objection with supporting evidence and arguments."
    },
    {
      title: "File Your Response",
      description: "Submit your response to the trademark office within the prescribed timeframe to contest the objection."
    },
    {
      title: "Follow Up",
      description: "Monitor the status of your response and any further actions required, including possible hearings or additional submissions."
    },
    {
      title: "Seek Expert Advice",
      description: "Consult with a trademark attorney if needed to enhance your response and navigate the objection process effectively."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Trademark Objection
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Trademark Objection occurs when a third party raises concerns against your trademark application, challenging its registration. Addressing these objections promptly and effectively is crucial to securing your trademark rights and protecting your brand.
            </p>

            <p>Resolve trademark objections with our expert guidance to ensure a smooth and successful registration process.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Trademark Objection Services</h6>
          <p className='text-center mt-4'>Our Trademark Objection services help you navigate the challenges posed by objections to your trademark application. We provide expert support to address objections and ensure a smooth path to registration.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-96 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Trademark Objection?</h6>

            <p className='mb-4'>Trademark Objection is a formal challenge to the registration of your trademark, typically raised by third parties or the trademark office itself. This objection can be based on various grounds, such as similarity to existing trademarks or lack of distinctiveness.</p>
          </div>

          <div id="el" className='pl-16 pr-96 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Addressing Objections</h6>
            <p className='mb-4'>To address a trademark objection, you must have a pending trademark application that has been challenged. Ensure that you respond within the stipulated time and provide adequate evidence and arguments to counter the objection.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of Trademark Objection Services</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Handling Trademark Objection</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Addressing Trademark Objection</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Addressing Trademark Objection</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default TrademarkObjection;